import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CustomerAddress } from 'src/app/services/customer/customer.model';
import {
  OrwiCity,
  OrwiCountry,
  OrwiTown,
} from 'src/app/services/dto/orwi-address';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';

@Component({
  selector: 'customer-address-item',
  templateUrl: './customer-address-item.component.html',
  styleUrls: ['./customer-address-item.component.scss'],
})
export class CustomerAddressItemComponent implements OnInit, OnDestroy {
  @Input()
  addressFormGroup: any;

  @Input()
  isEditing: boolean = false;

  @Output()
  isEditOpened = new EventEmitter();

  @Output()
  onDeleteAddress = new EventEmitter();

  @Output()
  onSaveAddress = new EventEmitter();

  @Output()
  onCancelAddress = new EventEmitter();

  addressData: CustomerAddress;

  editable = false;
  private destroy$ = new Subject<void>();

  constructor(private countryCodesService: CountryCodesService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    const savedCity = localStorage.getItem('selectedCity');
    if (savedCity) {
      this.City.setValue(savedCity);
    }

    console.log(this.addressFormGroup);
    this.addressData = this.addressFormGroup.value;
    this.getCity('TR');

    this.City.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe((city) => {
      this.Town.setValue(null);
      if (city !== null && city !== undefined && city.length > 0) {
        localStorage.setItem('selectedCity', city);
        this.getTown('TR', city);
      } else {
        this.getTown(null, null);
      }
    });  
    this.addressFormGroup.get('city').setValue(this.addressData.city);
    this.addressFormGroup.get('town').setValue(this.addressData.town);
  }

  openEdit() {
    this.editable = true;
    this.isEditOpened.emit(true);
  }

  async getCountries() {
    this.CountryInfo.countries = (await this.countryCodesService.getCountries()) as OrwiCountry[];
  }
  async getCity(country: string) {
    this.City.setValue(null);
    if (country !== null && country !== undefined && country.length > 0) {
      this.CountryInfo.cities = (await this.countryCodesService.getCities(
        country
      )) as OrwiCity[];
      this.CountryInfo.cities.sort((a, b) => +a.code - +b.code);
    }
  }

  async getTown(country: string, city: string) {
    this.Town.setValue(null);
    if (
      (city !== null && city !== undefined && city.length > 0) ||
      (country !== null && country !== undefined && country.length > 0)
    ) {
      this.CountryInfo.towns = (await this.countryCodesService.getTowns(
        country,
        city
      )) as OrwiTown[];
      this.CountryInfo.towns.sort((a, b) => +a.code - +b.code);
    }
  }

  get City(): UntypedFormControl {
    return this.addressFormGroup.get('city') as UntypedFormControl;
  }
  get Town(): UntypedFormControl {
    return this.addressFormGroup.get('town') as UntypedFormControl;
  }

  get AddressName(): UntypedFormControl {
    return this.addressFormGroup.get('adressName') as UntypedFormControl;
  }

  CountryInfo: {
    countries: OrwiCountry[];
    cities: OrwiCity[];
    towns: OrwiTown[];
  } = {
    countries: [],
    cities: [],
    towns: [],
  };

  cityName(id: string) {
    return this.CountryInfo.cities.find((el) => el.code == id)?.name;
  }

  townName(id: string) {
    return this.CountryInfo.towns.find((el) => el.code == id)?.name;
  }
}
