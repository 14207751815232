import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sync-account-menu-error',
  templateUrl: './sync-account-menu-error.component.html',
  styleUrls: ['./sync-account-menu-error.component.scss'],
})
export class SyncAccountMenuErrorComponent  implements OnInit {
@Input() erroredAccount: any;
  constructor(public modalController : ModalController) { }

  ngOnInit() {}

}
