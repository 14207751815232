<!-- <ion-list-header>
  <div
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    "
  >
    <div>
      <h3>{{ title | transloco }}</h3>
    </div>

    <div (click)="close()">
      <div class="close">
        <ion-icon name="close" class="bottom"></ion-icon>
        <ion-icon class="top" name="close"></ion-icon>
      </div>
    </div>
  </div>
</ion-list-header> -->

<ion-header class="header ion-no-border">
  <ion-toolbar>
    <ion-title>
      {{ title | transloco | uppercase}}
    </ion-title>
    <ion-buttons slot="end" *ngIf="closeButton">
      <ion-button (click)="close()">
        <span [ngStyle]="{'display': closeButton ? 'flex' : 'none'}" class="material-icons"> close </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
