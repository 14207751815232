import { Component, OnDestroy } from '@angular/core';
import { arrayUpsert } from '@datorama/akita';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppModifier, RestoranModifier } from '../../../state/dto';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { FoodAppsIntegrationStore } from '../../../state/food-apps-integration.store';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'app-modifer-select',
  templateUrl: './modifer-select.component.html',
  styleUrls: ['./modifer-select.component.scss'],
})
export class ModiferSelectComponent implements OnDestroy {
  foodAppProducts: FoodAppModifier[] = [];
  activeGroup = '';
  query = '';
  modifiers: RestoranModifier[] = [];
  page = 0;
  pageLimit = 20;
  pagedData = [];
  isLoaded = false;

  constructor(
    public integrationQuery: FoodAppsIntegrationQuery,
    private glb: GlobalService,
    private integrationStore: FoodAppsIntegrationStore
  ) {
    this.integrationQuery.restaurantMenu$.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      if (o) {
        this.modifiers = o.modifiers;
        console.log(this.modifiers);
        this.isLoaded = true;
        this.pagedData = this.modifiers.slice(0, this.pageLimit);
      }
    });
  }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  select(item: RestoranModifier) {
    this.integrationQuery.activeFoodAppModifier.internalId = item.id;
    this.integrationQuery.activeFoodAppModifier.changed = true;

    this.integrationStore.update(({ foodAppModifiers }) => ({
      foodAppModifiers: arrayUpsert(
        foodAppModifiers,
        this.integrationQuery.activeFoodAppModifier.id,
        this.integrationQuery.activeFoodAppModifier,
        'id'
      ),
    }));
    this.glb.closeModal();
  }

  handleChange(event) {
    this.query = event.target.value.toLowerCase();
    if (this.query.length > 0) {
      this.pagedData = [];
      setTimeout(() => {
        this.pagedData = this.integrationQuery.restaurantModifiers.filter(
          (o) => o.name.toLowerCase().indexOf(this.query) > -1
        ).slice(0, this.pageLimit);
        this.page = 0;
      }, 500);
    } else {
      this.pagedData = this.modifiers.slice(0, this.pageLimit);
      this.page = 0;
    }
  }

  doInfinit(e: any) {
    this.page++;
    if (this.query.length > 0) {
      this.pagedData.push(
        ...this.integrationQuery.restaurantModifiers.filter(
          (o) => o.name.toLowerCase().indexOf(this.query) > -1
        ).slice(this.page * this.pageLimit, (this.page * this.pageLimit) + this.pageLimit)
      );
    } else {
      this.pagedData.push(
        ...this.modifiers.slice(this.page * this.pageLimit, (this.page * this.pageLimit) + this.pageLimit)
      );
    }
    console.log('page', this.page, this.pageLimit, this.page * this.pageLimit, 'data', this.pagedData);
    e.target.complete();
  }
}
