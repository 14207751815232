import { PluginListenerHandle } from '@capacitor/core';

export interface PavoPaymentPluginPlugin {
  sendBroadcast(data: Partial<IPavoIntent>): Promise<any>;
  launchChooser(data: Partial<IPavoIntent>): Promise<any>;
  launch(data: Partial<IPavoIntent>): Promise<any>;
  canResolveActivity(data: Partial<IPavoIntent>): Promise<any>;
  addListener(
    eventName: 'OnLaunchResult',
    listenerFunc: OnLaunchResult,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
}

type OnLaunchResult = (data: any) => void;

export enum PavoActionType {
  completeSale = 'pavopay.intent.action.complete.sale',
  cancelSale = 'pavopay.intent.action.cancel.sale',
  paymentMediators = 'pavopay.intent.action.get.mediators',
  printOut = 'pavopay.intent.action.print.out',
  createReport = 'pavopay.intent.action.create.report',
  getDeviceInfo = 'pavopay.intent.action.get.device.info',
  performEOD = 'pavopay.intent.action.performEOD',
  sendOfflineSales = 'pavopay.intent.action.send.offline.sales',
  pendingSale = 'pavopay.intent.action.check.pending.sale',
  suspendedSale = 'pavopay.intent.action.abandon.suspended.sale',
  lastProcessedSale = "pavopay.intent.action.last.processed.sale"
}

export enum PavoPackageType {
  dev = 'tr.com.overtech.overpay_pos_dev',
  test = 'tr.com.overtech.overpay_pos_test',
  demo = 'tr.com.overtech.overpay_pos_demo',
  prod = 'tr.com.overtech.overpay_pos',
}

 /**  Builds an Android intent with the following parameters
  * @param [action] refers to the action parameter of the intent.
  * @param [flags] is the list of int that will be converted to native flags.
  * @param [category] refers to the category of the intent, can be null.
  * @param [data] refers to the string format of the URI that will be passed to
  * intent.
  * @param [arguments] is the map that will be converted into an extras bundle and
  * passed to the intent.
  * @param [arrayArguments] is a map that will be converted into an extra bundle
  * as in an array and passed to the intent.
  * @param [package] refers to the package parameter of the intent, can be null.
  * @param [componentName] refers to the component name of the intent, can be null.
  * If not null, then [package] but also be provided.
  * @param [type] refers to the type of the intent, can be null.
  */
export interface IPavoIntent {
    action: PavoActionType;
    flags: number[];
    category: any;
    data: any;
    arguments: PavoArguments;
    arrayArguments: PavoArrayArguments;
    package: PavoPackageType;
    componentName: string;
    type: 'application/json';
}

export interface PavoArguments {
    [key:string]: any;
}
export interface PavoArrayArguments {
    [key:string]: any[];
}