import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { ModifierProduct, Modifier } from 'src/app/services/dto/orwi-product';
import { GlobalService } from 'src/app/services/global.service';
import { ModifiersService } from '../../state/modifiers.service';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { MenuQuery } from '../../../state/menu.query';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'orwi-modifiers-form',
  templateUrl: './modifiers-form.component.html',
  styleUrls: ['./modifiers-form.component.scss'],
})
export class ModifiersFormComponent implements OnInit, OnDestroy {
  mustModifiers: Modifier[] = [];
  constructor(
    private glb: GlobalService,
    public folioQuery: FolioQuery,
    private folioStore: FolioStore,
    private folioService: FolioService,
    private modifierService: ModifiersService,
    private ms: ModalController,
    private numpadService: NumpadService,
    private menuQuery: MenuQuery
  ) {}

  async ngOnInit() {
    this.folioQuery.activeFolioRow$.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      if (o) {
        this.mustModifiers = o.mustModifiers;
      }
    });

    const modalRef = await this.ms.getTop();
    modalRef.onDidDismiss().then(() => {
      this.numpadService.reset('qty');
    });
  }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  done() {
    let ps = this.modifierService.checkModifiers();
    let elModifierArraow = document.getElementById('modifier-arrow');
    if (ps.length == 0) {
      elModifierArraow.style.opacity = '0';
      elModifierArraow.style.display = 'none';

      setTimeout(async () => {
        elModifierArraow.style.opacity = '0';
        elModifierArraow.style.display = 'none';
        await this.folioService._posSaveFolio(null, 'low');
        this.folioStore.update({ showModifiers: false });
        await this.ms.dismiss(undefined, 'close');
      }, 200);
    } else {
      let p = ps[0];
      let el = `header-${p.id}-1`;

      let el1 = document.getElementById(el);

      el1.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout(() => {
        // let left = el1.getBoundingClientRect().left;
        let top = el1.getBoundingClientRect().top;
        elModifierArraow.style.top = top - 50 + 'px';
        // elModifierArraow.style.left = left + 'px';
        elModifierArraow.style.opacity = '1';
        elModifierArraow.style.display = 'block';
      }, 300);
    }
  }

  // async cancel() {
  //   this.ms.dismiss();
  //   this.folioStore.update({ showModifiers: false });
  // }

  change(
    _e,
    modifierProduct: ModifierProduct,
    modifier: Modifier,
    folioRowParentID,
    qtyID
  ) {
    setTimeout(() => {
      this.modifierService
        .addRemoveModifier(
          modifierProduct,
          modifier,
          folioRowParentID,
          false,
          qtyID
        )
        .catch((_l) => {
          // console.log(l)
          this.glb
            .toast(
              'You made too many choices',
              `En fazla ${modifier.maxSelect} seçim yapabilirsiniz.`,
              'bottom',
              'danger',
              3000
            )
            .then((_o) => {
              // console.log("ok..")
              setTimeout(() => {
                modifierProduct.selected = false;
              }, 2000);
            });
        });
    }, 10);
  }

  deattach(
    _e,
    modifierProduct: ModifierProduct,
    modifier: Modifier,
    folioRowParentID,
    qtyID
  ) {
    modifierProduct.selected = modifierProduct.selected ? false : true;
    this.modifierService.addRemoveModifier(
      modifierProduct,
      modifier,
      folioRowParentID,
      true,
      qtyID
    );
  }

  async cancel() {
    if (
      this.folioService.popProduct(
        this.menuQuery.getValue().selectedProduct.product.id
      )
    ) {
      this.folioStore.update({ showModifiers: false });
      await this.ms.dismiss(undefined, 'close');
    }else{
      this.glb.toast('Opss!', 'Something went wrong', 'bottom', 'danger');

    }
  }
}
