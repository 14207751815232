import { Injectable } from '@angular/core';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { Folio } from '../dto/orwi-folio';
import { Coupon } from '../dto/orwi-store';
import { OrwiService } from '../orwi/orwi.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(
    private orwi: OrwiService,
    private us: SSOSessionService,
  ) {}

  loadCoupon(id) {
    return new Promise((resolve, reject) => {
      this.orwi
        .serviceRequestPromise(
          '/api/coupon/loadCoupon',
          { id: id },
          this.us.acessToken
        )
        .then(
          (o: any) => {
            resolve(o);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  getUserCoupons() {
    return new Promise((resolve, reject) => {
      this.orwi
        .serviceRequestPromise('/api/coupon/getUserCoupons', {}, this.us.acessToken)
        .then(
          (o: any) => {
            if (o.response) {
              resolve(o.response);
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  useCoupon(coupon: Coupon, folio: Folio): Promise<Folio> {
    let request = { id: coupon.id, folioId: folio.id, userId: this.us.userInfo.id };
    return new Promise((resolve, reject) => {
      this.orwi
        .serviceRequestPromise(
          '/api/coupon/useCouponForPOS',
          request,
          this.us.acessToken
        )
        .then(
          (o: any) => {
            if (o.response) {
              resolve(o.response);
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  getBenefitsForPOS(params: {
    folioNo: string;
    userQrCode: string;
    kerzzId: string;
    orwiFolioId: string;
  }): Promise<Folio> {
    return new Promise((resolve, reject) => {
      this.orwi
        .serviceRequestPromise(
          '/api/coupon/getBenefitsForPOS',
          params,
          this.us.acessToken
        )
        .then(
          (o: any) => {
            if (o) {
              resolve(o);
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }
}
