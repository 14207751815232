<!-- <div *ngIf="menuType == 'pos' && (ssoService.checkPermission('pos-main') || ssoService.checkPermission('folio-actions'))" class="cloud-menu-cover"> 
  pos-main ve folio-actions icin bakilacak -->

<div *ngIf="menuType == 'pos' && (ssoService.checkPermission('modules-pos'))" class="cloud-menu-cover">
  <div
    class="cloud-menu-item"
    [ngStyle]="{ display: item?.hide ? 'none' : '' }"
    [ngClass]="{
      selected: (selectedPosMenu$ | async)?.id == item.id,
      disabled: item.disabled
    }"
    *ngFor="let item of posMenu$ | async as list"
    (click)="selectMenuItem(item)"
  >
    <div>
      {{ item.title | transloco }}
      <ion-badge *ngIf="item.badge">{{ item.badge }}</ion-badge>
    </div>
    <ion-ripple-effect></ion-ripple-effect>
  </div>
</div>

<div *ngIf="menuType == 'folio'" class="cloud-menu-cover-absolute">
  <div
    class="cloud-menu-item-absolute"
    [ngStyle]="{ filter: item.disabled ? 'opacity(0.8)' : '' }"
    [ngClass]="{
      selected: (selectedFolioMenu$ | async)?.id == item.id && item.selectable
    }"
    *ngFor="let item of folioMenu$ | async as list"
    (click)="selectMenuItem(item)"
  >
    <div>
      <span [ngClass]="{ 'have-note': isHaveNote(item) }">
        {{ item.title | transloco }}
      </span>
      <ion-badge *ngIf="item.badge">{{ item.badge }}</ion-badge>
    </div>
    <ion-ripple-effect></ion-ripple-effect>
  </div>
</div>
