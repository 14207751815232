import { inject, Injectable } from '@angular/core';
import { EcrSettingService } from './ecr-setting.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EcrContract } from './dto/ecr-contract.model';

@Injectable({
  providedIn: 'root',
})
export class EcrContractService {
  ecrSettingService = inject(EcrSettingService);
  http = inject(HttpClient);
  apiUrl = 'https://contract-service.kerzz.com:4300';
  contract = new EcrContract();

  constructor() {}

  async getContracts(ecrSerialNo: string): Promise<EcrContract> {
    return new Promise((resolve, reject) => {
      return lastValueFrom(
        this.http.post(
          this.apiUrl + '/api/ecr/getContractStatus',
          {
            legalId: ecrSerialNo,
            ecrType: 'gmp',
          },
          {
            headers: {
              'x-api-key':
                'WkhkeFpIcWRxd2Rxd2Rxd2RLTTJGVE1YZGlNMDEwWVRKV05VTm5kMlZ4Y1E',
            },
          }
        )
      ).then((response: EcrContract) => {
        if (response) {
          this.contract = response;
          return resolve(response);
        }

        return reject('Hata!');
      });
    });
  }

  async getHeartBeatLicense() {
    // /api/license/heartbeatLicense
    return new Promise((resolve, reject) => {
      return lastValueFrom(
        this.http.post(
          this.apiUrl + '/api/license/heartbeatLicense',
          {
            // licenseId: localStorage.getItem('activeStore'),
            // verison: 'string',
            databaseName: localStorage.getItem('activeStore'),
            isServer: true,
          },
          {
            headers: {
              'x-api-key':
                'WkhkeFpIcWRxd2Rxd2Rxd2RLTTJGVE1YZGlNMDEwWVRKV05VTm5kMlZ4Y1E',
            },
          }
        )
      ).then((response: EcrContract) => {
        if (response) {
          this.contract = response;
          return resolve(response);
        }

        return reject('Hata!');
      });
    });
  }
}
