import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Device } from '@capacitor/device';
import { ModalController, NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { EcrSetting } from 'src/app/services/ecr/dto/ecr-setting.model';
import { EcrSettingService } from 'src/app/services/ecr/ecr-setting.service';
import { EcrService } from 'src/app/services/ecr/ecr.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'ecr-settings',
  templateUrl: './ecr-settings.component.html',
  styleUrls: ['./ecr-settings.component.scss'],
})
export class EcrSettingsComponent {
  printerToggle;
  segments: SegmentCard['segment'] = 'ecr-settings';
  customECRoptions = {
    header: this.transloco.translate('ECR Service Type'),
  };
  segmentCards: SegmentCard[] = [
    // {
    //   title: 'Ecr Settings',
    //   icon: 'wallet-outline',
    //   type: 'Settings',
    //   segment: 'ecrSettings',
    // },
    {
      title: 'Department Definition',
      icon: 'wallet-outline',
      type: 'Definition',
      segment: 'departmentDefinitions',
    },
    {
      title: 'Tax Definition',
      icon: 'wallet-outline',
      type: 'Definition',
      segment: 'taxDefinitions',
    },
  ];

  printerIpList = [];
  ecrServices: any[] = [
    {
      label: this.transloco.translate('None'),
      value: 'none',
    },
    {
      label: 'Ingenico',
      value: 'ingenico',
    },
    {
      label: 'Profilo',
      value: 'profilo',
    },
    {
      label: 'Hugin',
      value: 'hugin',
    },
    {
      label: 'Pavo',
      value: 'pavo',
    },
    {
      label: 'KSA',
      value: 'KSA'
    }
  ];
  constructor(
    private ecrService: EcrService,
    private glb: GlobalService,
    private transloco: TranslocoService,
    public modalController: ModalController,
    public nav: NavController,
    private ecrSettingService: EcrSettingService,
    private ssoService: SSOSessionService,
    private sessionQuery : SessionQuery

  ) {
    // this.ipAddress = localStorage.getItem('ecrIPAddress');
    // this.terminalIpAddress = localStorage.getItem('terminalIp');
    // this.selectedService.setValue(
    //   localStorage.getItem('ecrServiceType') ?? 'none'
    // );
    // this.checkingPrinterIpList();
  }

  ecrForm = new UntypedFormGroup({
    ecrIp: new UntypedFormControl(null),
    terminalIp: new UntypedFormControl(null),
    port: new UntypedFormControl(null, Validators.required),
    ecrSerialNo: new UntypedFormControl(null),
    ecrModel: new UntypedFormControl(null, Validators.required),
  });

  ionViewDidEnter() {
  this.getEcrSettings();
  }

  currentEcrSettings: Partial<EcrSetting>;

  segmentHandler(segment: SegmentCard['segment']) {
    console.log(segment)
    if(!this.ssoService.checkPermission('cash-register-edit') && segment === 'ecrSettings'){
      this.glb.permissionToast();
      return
    }
    this.segments == segment ? this.nav.navigateRoot('pos-home') : null;
    this.segments = segment;
    this.printerToggle = false;
    if (segment == 'ecr-settings') {
      // this.getEcrSettings();
    }
  }

  findPrinter() {
    console.log('Getting Printer IP List');
    this.printerToggle = true;

    this.glb.showLoading();
    setTimeout(() => {
      this.printerIpList = [
        { ip: '123.123.123.123', name: 'Printer1' },
        { ip: '321.321.321.321', name: 'Printer2' },
        { ip: '143.412.412.142', name: 'Printer3' },
      ];
      this.glb.closeLoading();
    }, 3000);
    console.log('Printer List', this.printerIpList);
  }

  selectPrinter(item) {
    this.printerToggle = false;
    this.ecrForm.patchValue({
      ecrIp: item.ip,
    });
    this.ecrForm.value.ecrIp = item.ip;
    console.log(this.ecrForm.value.ecrIp);
  }


  checkCashResigisterIsActive()
  {

  }

  async createZReport() {
    this.glb.showLoading();
    setTimeout(async () => {
      await this.ecrService.createZreport();
      this.glb.closeLoading();
      this.glb.toast(
        '',
        'Printed Z Report successfully',
        'bottom',
        'success'
      );
    }, 3000);
  }

  async createXReport() {
    this.glb.showLoading();

    setTimeout(async () => {
      await this.ecrService.createXreport();
    }, 3000);
  }

  close() {
    this.modalController.dismiss();
  }

  save() {
    if (this.ecrForm.invalid) return;
    const {
      ecrIp,
      terminalIp,
      ecrModel,
      port,
      ecrSerialNo,
    } = this.ecrForm.value;
    console.log('ecrService', ecrModel);
    localStorage.setItem('ecrIPAddress', ecrIp);
    localStorage.setItem('terminalIp', terminalIp);
    localStorage.setItem('ecrServiceType', ecrModel);
    localStorage.setItem('ecrPort', port);
    localStorage.setItem('ecrSerialNo', ecrSerialNo);
    this.glb.ecrIPAddress = this.ecrForm.value.ecrIp;
    this.glb.terminalIdAddress = terminalIp;
    this.glb.ecrServiceType = ecrModel;

    console.log('ECR FORM DATA: ', this.ecrForm.value);

    this.saveEcrSettings();
    // this.ecrService.connectEcr()
    this.segmentHandler('ecr-settings');
  }

  async setDepartments() {
    await this.ecrService.syncDepartment();
  }
  async saveEcrSettings() {
    let data = {
      ...this.currentEcrSettings,
      ...this.ecrForm.value,
    };
    if (!data.uuid) {
      data = {
        ...data,
        uuid: (await Device.getId()).identifier,
        storeId : this.sessionQuery.activeLicense.orwiStore.id
      };
    }
    await this.ecrSettingService.saveEcrSetting(data).then((res) => {
      console.log('', res);
      this.glb.toast(
        'Ayar Aktifliği',
        'Your settings will be active when the application restarts',
        'middle',
        'success'
      );
    });
  }

  async getEcrSettings() {
    this.glb.showLoading();
    console.log('ecrSayfası',this.ecrSettingService.ecrSetting)
    // this.ecrSettingService.getEcrSetting().then((res) => {
      if (this.ecrSettingService.ecrSetting) {
        this.currentEcrSettings = this.ecrSettingService.ecrSetting;
        this.ecrForm.patchValue(this.currentEcrSettings);
      } else {
        this.ecrForm.patchValue({
          ecrIp: localStorage.getItem('ecrIPAddress'),
          terminalIp: '127.0.0.1',
          port: localStorage.getItem('ecrPort'),
          ecrModel: localStorage.getItem('ecrServiceType'),
          ecrSerialNo: localStorage.getItem('ecrSerialNo'),
        });
      }

      this.glb.closeLoading();
    // });
  }
}

interface SegmentCard {
  title: string;
  icon: string;
  type: string;
  segment:
    | 'ecrSettings'
    | 'departmentDefinitions'
    | 'taxDefinitions'
    | 'ecr-settings';
}
