<div id="folio-coverc-folio" class="folio-row-cover">
  <div style="margin-bottom: 10px" *ngIf="component === 'folio'">
    <ion-segment
      mode="ios"
      [value]="folioQuery.activeSegment$ | async"
      (ionChange)="segmentChangedFolio($event)"
    >
      <ion-segment-button value="all">
        <ion-label>{{ "All" | transloco }}</ion-label>
        <ion-badge>{{ folioQuery.mainRows("all").length }}</ion-badge>
      </ion-segment-button>
      <ion-segment-button value="new">
        <ion-label>{{ "New" | transloco }}</ion-label>
        <ion-badge>{{ folioQuery.mainRows("new").length }}</ion-badge>
      </ion-segment-button>
      <ion-segment-button value="old">
        <ion-label>{{ "Old" | transloco }}</ion-label>
        <ion-badge>{{ folioQuery.mainRows("old").length }}</ion-badge>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div id="folio-content-{{ id }}" class="scroll-cover">
    <ion-list
      class="folio-list"
      lines="none"
      *ngIf="folioQuery.mainRows().length > 0"
    >
      <div
        (click)="select(item, i)"
        *ngFor="
          let item of folioQuery.mainRows$() | async;
          let i = index;
          let first = first;
          trackBy: trackBy
        "
        style="padding: 10px; border: #cbcdde 1px solid; border-radius: 8px"
        [ngClass]="{
          selected:
            (folioQuery.selectedRow$ | async)?.id === item.id &&
            i === selectedRowIndex,
          paid: item.isPaidFull
        }"
        [ngStyle]="{ background: first ? 'rgb(175 255 162 / 55%)' : '' }"
        class="folio-row"
        id="folio-row-{{ item.id }}"
      >
        <ion-icon
          size="small"
          slot="end"
          *ngIf="component === 'payment' && !item.isPaidFull"
          name="ellipse"
        ></ion-icon>

        <ion-icon
          size="small"
          slot="end"
          color="primary"
          *ngIf="component === 'payment' && item.isPaidFull"
          name="checkmark-done-circle"
        ></ion-icon>

        <div
          id="row-{{ item.id }}"
          style="width: 100%; display: flex; justify-content: space-between"
        >
        <ion-button
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          qtyDelete($event, item)
        "
        fill="clear"
        [disabled]="item.qty == 1 || item.isGift || item.price === 0"
        size="small"
      >
        <div>
          <ion-icon
            style="margin-bottom: 3px; color: #184eae"
            name="trash-outline"
          ></ion-icon>
          <div style="text-transform: none; color: #171e4e">
            {{ "Delete" | transloco }}
          </div>
        </div>
      </ion-button>
          <div class="qty qty-price">
            {{ item.qty }}
          </div>
          <div style="width: 100%">
            <div class="item-row-cover">
              <div class="product-name">
                <ion-icon
                  *ngIf="item.isGift"
                  name="gift-outline"
                  style="margin-right: 5px; color: lime; font-size: 18px"
                >
                </ion-icon>
                <ion-icon
                  style="
                    margin-right: 5px;
                    color: rgb(255, 128, 0);
                    font-size: 18px;
                  "
                  *ngIf="item.rowType === 'discount'"
                  name="pricetags-outline"
                ></ion-icon>
                <ion-icon
                  style="
                    margin-right: 5px;
                    color: rgb(255, 0, 0);
                    font-size: 18px;
                  "
                  *ngIf="item.rowType === 'payment'"
                  name="cash-outline"
                ></ion-icon>
                <label
                  *ngIf="
                    item.rowType === 'payment' || item.rowType === 'discount'
                  "
                  style="font-weight: bold"
                >
                  {{ item.name }}</label
                >
                <label
                  *ngIf="
                    item.rowType !== 'payment' && item.rowType !== 'discount'
                  "
                >
                  {{ item.name }}</label
                >
              </div>
              <div class="product-price">
                {{ item | folioRowTotal | orwiCurrency }}
              </div>
            </div>
            <div
              *ngTemplateOutlet="
                tplModifier;
                context: {
                  parentID: item.id,
                  isSelected:
                    (folioQuery.selectedRow$ | async)?.id === item.id &&
                    i === selectedRowIndex
                }
              "
            ></div>
            <div style="font-size: 10px; text-align: right; color: rgb(26, 25, 25)">
              {{ item.creation | date: "HH:mm:ss" }} /
              {{ item.updaters[0]?.userName }}
            </div>
          </div>
        </div>
      </div>
    </ion-list>

    <ion-list lines="none" *ngIf="showPaymentRows" style="margin-top: 20px">
      <ion-item *ngFor="let item of this.getPaymentRows()">
        <div class="payment-row-cover">
          <div class="product-name">
            <ion-icon
              *ngIf="item.isGift"
              name="gift-outline"
              style="margin-right: 5px; color: lime; font-size: 18px"
            >
            </ion-icon>
            {{ item.name }}
          </div>
          <div class="product-price">
            {{ item | folioRowTotal | orwiCurrency }}
          </div>
        </div>
        <div
          *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"
        ></div>
      </ion-item>
    </ion-list>
  </div>
  <!--    TOTAL ROW -->

  <div class="total-row-cover">
    <div class="folio-total-label">
      {{ "Total" | transloco }}
    </div>
    <div class="folio-total-price">
      {{
        folioQuery.activeFolio$ | async | orwiFoliosTotalPrice | orwiCurrency
      }}
    </div>
  </div>
</div>

<!-- MODIFIER TEMPLATE -->
<ng-template #tplModifier let-parentId="parentID" let-isSelected="isSelected">
  <!-- <ng-container *ngIf="isSelected"> -->
  <div *ngFor="let item of folioQuery.mustModifiers(parentId)">
    <!-- <div *ngIf="item.qtyID !== oldID;" class="modifier-qtID">
      {{showID(item.qtyID)}}
    </div> -->

    <!-- <div style="margin-left: 10px; font-size: 12px">
      <div style=" display: flex; justify-content: space-between;  align-items: center;">
        <div style="width: 100%; padding-left: 5px"
          [ngStyle]="{'text-decoration': item.isDetachableModifier ? 'line-through' : 'unset'}">
          <ion-icon [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }" name="return-down-forward-outline">
          </ion-icon>&nbsp;{{ item.name }}
        </div>

      </div>
      <div style="margin-left: 10px" *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div>
    </div> -->
  </div>

  <div
    style="height: 5px"
    *ngIf="
      folioQuery.modifiers(parentId).length > 0 &&
      folioQuery.mustModifiers(parentId).length > 0
    "
  ></div>

  <div *ngFor="let item of folioQuery.modifiers(parentId)">
    <!-- <div *ngIf="item.qtyID !== oldID;" class="modifier-qtID">
      {{showID(item.qtyID)}}
    </div> -->

    <div style="margin-left: 10px; font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          style="width: 100%; padding-left: 5px"
          [ngStyle]="{
            'text-decoration': item.isDetachableModifier
              ? 'line-through'
              : 'unset'
          }"
        >
          <ion-icon
            [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }"
            name="return-down-forward-outline"
          >
          </ion-icon
          >&nbsp;{{ item.name }}
        </div>
        <div *ngIf="item.price > 0 && !item.isGift">
          {{ item.price | orwiCurrency }}
        </div>
        <div *ngIf="item.isGift">
          {{ 0 | orwiCurrency }}
        </div>
      </div>
      <div
        style="margin-left: 10px"
        *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"
      ></div>
    </div>
  </div>

  <!-- flio note start -->
  <div *ngFor="let item of folioQuery.notes(parentId)" style="margin-top: 5px">
    <div style="margin-left: 10px; font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="width: 100%; padding-left: 5px; font-weight: lighter">
          <ion-icon
            style="color: rgb(28, 167, 1); font-weight: 600"
            name="document-text-outline"
          ></ion-icon>
          &nbsp;
          <span style="color: rgb(28, 167, 1); font-weight: 600">{{
            item.name
          }}</span>
        </div>
        <div
          (click)="deletNote($event, item)"
        >
          <ion-icon
            name="trash-outline"
            style="
              color: rgb(223, 50, 116);
              margin-right: 15px;
              transform: scale(1.5);
              cursor: pointer;
            "
          ></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </div>

        <!-- <div *ngIf="fs.getRowTotal(item) > 0"> -->
        <!-- {{fs.getRowTotal(item) | orwiCurrency}} -->
        <!-- </div> -->
      </div>
      <!-- <div style="margin-left: 10px;" *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div> -->
    </div>
  </div>
  <!-- Folio note end-->
  <!-- </ng-container> -->
</ng-template>
