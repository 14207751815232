import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'tableName',
  standalone:true,
})
export class TableNamePipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(name: string): string {
    if (!name) {
      return this.transloco.translate('Noname');
    }
    if (name === 'take-away' || name === 'take-away-service') {
      return this.transloco.translate('takeAway');
    }
    return name;
  }
}
