import { Injectable } from '@angular/core';
import { IonRouterOutlet, ModalController, ModalOptions } from '@ionic/angular';
import { FolioInfoComponent } from 'src/app/modules/folio/components/folio-info/folio-info.component';
import { OpenFolioComponent } from 'src/app/modules/folio/components/open-folio/open-folio.component';
import { ProductTransferComponent } from 'src/app/modules/folio/components/product-transfer/product-transfer.component';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { ModifiersFormComponent } from 'src/app/modules/menu/modifiers/components/modifiers-form/modifiers-form.component';
import { SelectUserComponent } from 'src/app/modules/pos-users/components/select-user/select-user.component';
import { DiscountComponent } from 'src/app/modules/pos/pos-payment/components/discount/discount.component';
import { PaymentBanknoteComponent } from 'src/app/modules/pos/pos-payment/components/payment-banknote/payment-banknote.component';
import { FolioListDetailComponent } from 'src/app/modules/reports/components/folio-list-detail/folio-list-detail.component';
import { SelectTableFolioComponent } from 'src/app/modules/tables/componets/select-table-folio/select-table-folio.component';
import { StoreListModalComponent } from 'src/app/shared/components/store-list-modal/store-list-modal.component';
import { Folio } from '../dto/orwi-folio';
import { ITable } from '../dto/tables';
import { LoyaltyComponent } from 'src/app/modules/folio/components/loyalty/loyalty.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // *ngIf="folioQuery.showModifiers$ | async"

  infoModal;
  routerOutlet: HTMLIonRouterOutletElement;
  constructor(
    _routerOutlet: IonRouterOutlet,
    private folioStore: FolioStore,
    public modalController: ModalController
  ) {
    this.routerOutlet = _routerOutlet.nativeEl;
  }

  async showTableInfoModal(e: ITable, tableName, data?) {
    e; //reserved
    const infoModal = await this.modalController.create({
      component: FolioInfoComponent,
      showBackdrop: true,
      backdropDismiss: true,
      canDismiss: true,
      presentingElement: this.routerOutlet,
      componentProps: {
        data: data,
        tableName: tableName,
      },
    });
    return await infoModal.present();
  }

  async showSelectFolioModal(table: ITable, excludedFolioIds: string[] = []) {
    const folioModal = await this.modalController.create({
      component: SelectTableFolioComponent,
      componentProps: {
        table: table,
        excludedFolioIds: excludedFolioIds,
      },
      initialBreakpoint: 0.4,
      breakpoints: [0, 0.4, 1],
    });
    folioModal.present();
    const { data } = await folioModal.onDidDismiss();
    return data as Folio;
  }

  async showLoyaltyModal() {
    const infoModal = await this.modalController.create({
      component: LoyaltyComponent,
      showBackdrop: true,
      backdropDismiss: false,
      canDismiss: true,
      cssClass: 'loyalty-modal',
      presentingElement: this.routerOutlet,
      componentProps: {},
    });
    await infoModal.present();
  }

  async showTransferProductModal() {
    const infoModal = await this.modalController.create({
      component: ProductTransferComponent,
      showBackdrop: true,
      backdropDismiss: false,
      canDismiss: true,
      cssClass: 'product-transfer',
      presentingElement: this.routerOutlet,
      componentProps: {},
    });
    await infoModal.present();
  }

  async showNewTableModal(
    data?: any,
    type: 'folio' | 'add-table' | 'edit-table' = 'folio'
  ) {
    const infoModal = await this.modalController.create({
      component: OpenFolioComponent,
      cssClass: 'edit-tg',
      showBackdrop: true,
      backdropDismiss: true,
      canDismiss: true,
      presentingElement: this.routerOutlet,
      componentProps: {
        data,
        type,
      },
    });
    await infoModal.present();

    return infoModal;
  }

  async openModal(modalOptions: ModalOptions) {
    const infoModal = await this.modalController.create(modalOptions);
    await infoModal.present();

    return infoModal;
  }

  async showDiscountModal() {
    const infoModal = await this.modalController.create({
      component: DiscountComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    await infoModal.present();
  }

  async showModifier() {
    const infoModal = await this.modalController.create({
      component: ModifiersFormComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 0.5, 1],
      backdropDismiss: false,
      animated: false,
      canDismiss: (_data?: any, role?: string) => {
        return new Promise<boolean>((resolve) => resolve(role === 'close'));
      },
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      o; //reserved
      this.folioStore.update({ showModifiers: false });
    });
  }

  async showBanknoteModal() {
    const infoModal = await this.modalController.create({
      component: PaymentBanknoteComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      o; //reserved
      //this.folioStore.update({ show: false })
    });
  }

  async dismiss(data?: any) {
    this.modalController.dismiss(data);
  }

  async folioListDetail() {
    const infoModal = await this.modalController.create({
      component: FolioListDetailComponent,
      initialBreakpoint: 0.8,
      breakpoints: [0, 0.8, 1],
    });
    await infoModal.present();
  }

  async selectUser() {
    const infoModal = await this.modalController.create({
      component: SelectUserComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    await infoModal.present();
    return infoModal;
  }

  async selectLicense() {
    const infoModal = await this.modalController.create({
      component: StoreListModalComponent,
      initialBreakpoint: 0.8,
      breakpoints: [0, 0.8, 1],
    });
    await infoModal.present();
    return infoModal;
  }
}
