import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { OrwiListModalComponent } from 'src/app/shared/components/orwi-list-modal/orwi-list-modal.component';
import { Printer } from '../dto/orwi-definitions';
import { Folio, FolioRow, folioStatus } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { PrinterPrepareService } from './printer.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  FoodAppStore,
  Notification,
} from 'src/app/modules/food-apps-integration/state/dto';
// import { PublicApiService } from '../public-api/public-api.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { TOrwiPdf } from 'src/app/modules/orwi-pdf/dto/orwi-pdf.model';
import { FoodAppsIntegrationStore } from 'src/app/modules/food-apps-integration/state/food-apps-integration.store';
import { catchError, of, timeout } from 'rxjs';
import { ParametersStore } from 'src/app/modules/parameters/state/parameters.store';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { MenuQuery } from 'src/app/modules/menu/state/menu.query';
import { LogLevel } from 'kerzz-base';
import { PaymentQuery } from 'src/app/modules/pos/pos-payment/state/payment.query';
import { PaymentStore } from 'src/app/modules/pos/pos-payment/state/payment.store';
// import { BrandsStore } from 'src/app/modules/brands/state/brands.store';

@Injectable({ providedIn: 'root' })
export class PrintCoverService {
  folio: Folio = new Folio();
  constructor(
    private folioStore: FolioStore,
    private transloco: TranslocoService,
    private platform: Platform,
    private printerPrepareService: PrinterPrepareService,
    private orwiStoreQuery: OrwiStoreQuery,
    private globalService: GlobalService,
    private http: HttpClient,
    private sessionQuery: SessionQuery,
    private integrationStore: FoodAppsIntegrationStore,
    private parameterStore: ParametersStore,
    private folioService: FolioService,
    private menuQuery: MenuQuery,
    private idGen: IdGeneratorService,
    private paymentQuery: PaymentQuery,
    private paymentStore: PaymentStore
  ) {}

  async printUnit(folio?: Folio) {
    console.log('eft');
    if (!folio) folio = this.folioStore.getValue().activeFolio;
    this.folio = folio;
    folio.rows.map((o, _: any, folioRows: FolioRow[]) => {
      if (
        o.recordStatus !== 'deleted' &&
        (o.printed == false || o.printed == undefined)
      ) {
        if (o.printer === '') {
          if (o.parentID != '') {
            let parentRow = folioRows.find((rw) => rw.id == o.parentID);
            if (parentRow) {
              o.printer = parentRow.printer;
            }
          } else {
            // o.printer = this.orwiStoreQuery
            //   .getValue()
            //   .printers.filter(
            //     (p) => p.printerType == 'unit' || o.printer == p.name
            //   )[0]?.name;
          }
        }
      }
    });

    if (
      folio.rows.filter(
        (x) =>
          (x.recordStatus !== 'old' || x.printed === false) &&
          x.rowType == 'product'
      ).length > 0
    ) {
      let printers = this.orwiStoreQuery
        .getValue()
        .printers.filter(
          (p) => p.printerType == 'unit' && p.serviceTypes.includes(folio.type)
        );
      console.log(printers);

      if (folio.rows.filter((r) => r.printer).length > 0) {
        for await (const printer of printers) {
          try {
            let printData = await this.printerPrepareService.convertUnitV2(
              folio,
              printer
            );
            if (printData) {
              await this.handlePrintV2(printer, printData, undefined);
            }
          } catch (error) {
            await this.addFolioPrintLog(error, 'error');
            console.log(error);
          }
        }

        folio.rows.forEach((row) => {
          row.printed = true;
          row.new = false;
        });
        this.folioService._posSaveFolio(folio)
      }
    }
  }

  async printLabel(folio: Folio) {
    if (!folio) folio = this.folioStore.getValue().activeFolio;
    this.folio = folio;
    let printers = this.orwiStoreQuery
      .getValue()
      .printers.filter((p) => p.printerType == 'label' && p.serviceTypes.includes(folio.type));
    let orderLine: number = 1;
    for await (const printer of printers) {
      for await (const row of folio.rows.filter(
        (elm) =>
          elm.recordStatus !== 'deleted' &&
          elm.recordStatus === 'new' &&
          elm.rowType === 'product'
      )) {
        if (
          this.menuQuery.getValue().products.find((x) => x.id === row.itemID)
            ?.printLabel
        ) {
          try {
            let printData = await this.printerPrepareService.ConvertPrintLabel(
              folio,
              row,
              orderLine,
              printer
            );
            if (printData) {
              await this.handlePrintV2(printer, printData, undefined);
            }
            orderLine++;
          } catch (error) {
            await this.addFolioPrintLog(error, 'error');
            console.log(error);
          }
        }
      }
    }
  }

  async addFolioPrintLog(body, logLevel: LogLevel) {
    this.idGen;
    let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.folio.id,
      createDate: new Date(),
      logLevel: logLevel,
      actionType: 'folio-print',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: JSON.stringify(body),
      infoType: 'information',
      description: this.transloco.translate('Folio Print'),
    };
    await this.folioService.saveFolioLog(logObject);
    // await this.publicApi.upsert({
    //   _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
    //   col: 'print-folio-log',
    //   data: {
    //     createDate: new Date(),
    //     action: action,
    //     storeId: this.storeQuery._orwiStore.id,
    //     data: JSON.stringify(body),
    //     folioId: this.folio.id,
    //     uuid: this.globalService.DeviceUUID,
    //     requestIp: repIp,
    //     methot: method,
    //   },
    // });
  }

  async printSocketFolio(folio: Folio) {
    return new Promise(async (resolve) => {
      if (!folio) return resolve(null);



      this.orwiStoreQuery.folioPrinters
        .filter(
          (p) =>
            p.serviceTypes.includes('delivery') ||
            p.serviceTypes.includes('take-away')
        )
        .forEach(async (element) => {
          let printStr = await this.printerPrepareService.convertFolioV2(folio,element);
          this.handlePrintV2(element, printStr, folio.status).then((result) => {
            console.log('result', result);
            resolve(result);
          });
        });
    });
  }

  async printFolio(folio?: Folio) {

    return new Promise(async (resolve) => {
      this.globalService.overRideClosing = true;
      this.globalService.closeLoading();
      if (!folio) folio = this.folioStore.getValue().activeFolio;
      this.folio = folio;

      const discountRows = this.paymentQuery
        .getValue()
        .history.filter((o) => o.folioId == folio.id)
        .filter((o) => o.rowType == 'discount');
      let tempRows = [];
      for (const iterator of discountRows) {
        let paymentrow = this.folioService.createPaymentrow(
          iterator.paymentId,
          iterator.desc,
          iterator.value,
          iterator.rowType,
          iterator.payType
        );
        tempRows.push(paymentrow);
      }


      // if (folio.type === 'delivery' || folio.type === 'take-away') {
      //   const savedFolio = await this.folioService._posSaveFolio(folio, 'high');
      //   folio = savedFolio;
      //   printStr = await this.printerPrepareService.convertFolioV2(savedFolio);
      //   this.folioStore.update({ activeFolio: savedFolio });
      //   this.paymentStore.update(({ history }) => ({
      //     history: history.filter((hs) =>
      //       discountRows.some((dsRow) => dsRow.id != hs.id)
      //     ),
      //   }));

      //   this.orwiStoreQuery.folioPrinters
      //     .filter(
      //       (p) =>
      //         p.serviceTypes.includes('delivery') ||
      //         p.serviceTypes.includes('take-away')
      //     )
      //     .forEach(async (element) => {
      //       this.handlePrintV2(element, printStr, folio.status).then(
      //         (result) => {
      //           console.log('result', result);
      //           resolve(result);
      //         }
      //       );
      //     });
      // } else {
        this.openPrinters(folio).then(async (o: Printer) => {
          if (o)
          {
            folio.rows.push(...tempRows);
            folio.printCount= folio.printCount +1
            console.log('PrintFolio',folio)
            const savedFolio = await this.folioService._posSaveFolio(
              folio,
              'high'
            );
            folio = savedFolio;
            let printStr = await this.printerPrepareService.convertFolioV2(savedFolio,o);
            // printStr = await this.printerPrepareService.convertFolioV2(
            //   savedFolio,o
            // );
            this.folioStore.update({ activeFolio: savedFolio });
            this.paymentStore.update(({ history }) => ({
              history: history.filter((hs) =>
                discountRows.some((dsRow) => dsRow.id != hs.id)
              ),
            }));

            await this.handlePrintV2(o, printStr, folio.status).then((result) => {
              console.log('result2', result, folio);
              resolve(result);
            });
          }
          else
          {
            folio.rows.push(...tempRows);
            const savedFolio = await this.folioService._posSaveFolio(
              folio,
              'high'
            );
            folio = savedFolio;
            this.folioStore.update({ activeFolio: savedFolio });
            this.paymentStore.update(({ history }) => ({
              history: history.filter((hs) =>
                discountRows.some((dsRow) => dsRow.id != hs.id)
              ),
            }));
            resolve(true)
          }

        }
        );
      // }

      // let logObject = {
      //   id: this.idGen.generate(),
      //   storeId: this.sessionQuery.activeLicense.orwiStore.id,
      //   folioId: this.folioStore
      //   .getValue()
      //   .activeFolio.id,
      //   createDate: new Date(),
      //   logLevel: 'debug',
      //   actionType: 'folio-print',
      //   userId: this.sessionQuery.user.id,
      //   userName: this.sessionQuery.user.name,
      //   data: '',
      //   infoType: 'information',
      //   description: this.transloco.translate('Folio Print'),
      // };
      // await this.folioService.saveFolioLog(logObject)
    }).finally(() => {
      this.globalService.overRideClosing = true;
      this.globalService.closeLoading();
    });
  }

  async printTemplate(content: TOrwiPdf) {
    let printStr = await this.printerPrepareService.createTemplate({ content });

    this.openPrinters().then(async (o: Printer) => {
      await this.printToServerV2(printStr, o.ip, 1);
    });
  }

  async printNotifications(notification: Notification) {
    let printers = this.orwiStoreQuery
      .getValue()
      .printers.filter((p) => p.printerType == 'folio');

    const promises = [];
    for await (const printer of printers.filter((x) =>
      x.serviceTypes.includes('delivery')
    )) {
      try {
        let printData = await this.printerPrepareService.printNotifications(
          notification
        );
        if (printData) {
          promises.push({
            func: this.handlePrintV2,
            param1: printer,
            param2: printData,
            param3: 'closed',
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    for await (let { func, param1, param2, param3 } of promises) {
      try {
        const message = await func.call(this, param1, param2, param3);
        console.log(':::::::::::::::::::::::::');
        console.log('::::Printer Response:::::', message);
        console.log(':::::::::::::::::::::::::');
        console.log();
      } catch (error) {
        console.log('printNotify Error', error.message);
        return error;
      }
      if (promises.length > 1) {
        await setTimeout(async () => {}, 3000);
      }
    }
  }

  async printFoodAppStatus(foodAppStore: FoodAppStore, status) {
    if (this.integrationStore.getValue().foodAppAccounts.length > 0) {
      if (
        this.integrationStore
          .getValue()
          .foodAppAccounts.filter((x) => x.id === foodAppStore.accountId)
          .length === 0
      ) {
        return;
      }
    }
    let printStr = this.printerPrepareService.storeStatusChanged(
      foodAppStore,
      status
    );
    console.log('printFoodAppStatus', printStr);
    this.openPrinters().then(async (o: Printer) => {
      await this.handlePrintV2(o, printStr, 'closed');
    });
  }

  async handlePrintV2(
    printer: Printer,
    printData: Uint8Array,
    foliostatus?: folioStatus
  ) {
    const copies = [];
    let copyCount = printer.copyCount;
    if (foliostatus === 'closed') {
      copyCount = 1;
    }

    if (this.platform.is('capacitor')) {
      for (let i = 1; i <= copyCount; i++) {
        let tryCount = 0;
        while (tryCount != 6) {
          try {
            return await this.printToNativeAppV2(printer, printData);
            break;
          } catch (err) {
            tryCount += 1;
            await this.addFolioPrintLog(JSON.stringify(err), 'error');
            console.error(
              'birim yazdirma hatasi - tekrar sayisi:' +
                tryCount +
                ':' +
                JSON.stringify(err),
              new Date()
            );
            await setTimeout(async () => {}, 500);
          }
        }
        if (copies.length > 1) {
          //await setTimeout(async () => { }, 1500);
        }
      }
      return undefined;
    } else {
      return await this.printToServerV2(printData, printer.ip, copyCount);
    }
  }

  printToNativeAppV2(printer: Printer, printStr: Uint8Array) {
    let socketId = 0;
    console.log('socket started - ip:' + printer.ip);
    return new Promise((resolve, reject) => {
      try {
        (<any>window).chrome.sockets.tcp.create({}, (socketInfo) => {
          socketId = socketInfo.socketId;

          (<any>window).chrome.sockets.tcp.onReceiveError.addListener(
            (info) => {
              if (socketId == info.socketId) {
                console.error(
                  socketId,
                  'addListener_error' + JSON.stringify(info)
                );
                reject('addListener_error_' + JSON.stringify(info));
              }
            }
          );
          (<any>window).chrome.sockets.tcp.onReceive.addListener((info) => {
            console.warn(
              socketId,
              'addListener_onReceive' + JSON.stringify(info)
            );
          });
          console.log('socket created:', JSON.stringify(socketInfo));
          (<any>window).chrome.sockets.tcp.connect(
            socketId,
            printer.ip,
            9100,
            (result) => {
              if (result < 0) {
                reject(
                  socketId +
                    '- printer network connect error:' +
                    JSON.stringify(result)
                );
              } else {
                console.log(socketId, 'socket connected:', result);
              }
              (<any>window).chrome.sockets.tcp.send(
                socketId,
                printStr.buffer,
                (result) => {
                  console.log(socketId, 'socket sended', result);
                  //error control
                  if (result.bytesSent == 0 || result.resultCode < 0) {
                    reject(
                      socketId +
                        '- printer network send error:' +
                        JSON.stringify(result)
                    );
                  }
                  (<any>window).chrome.sockets.tcp.disconnect(socketId, () => {
                    console.log(socketId, 'socket disconnected');
                    (<any>window).chrome.sockets.tcp.close(socketId, () => {
                      console.log(socketId, 'socket closed');
                      resolve(socketId);
                    });
                  });
                }
              );
            }
          );
        });
        //on timeout - 10 sec.
        setTimeout(() => {
          (<any>window).chrome.sockets.tcp.disconnect(socketId, () => {
            console.log(socketId, 'socket timeout error disconnected');
            (<any>window).chrome.sockets.tcp.close(socketId, () => {
              console.log(socketId, 'socket timeout error closed');
            });
          });
          reject(socketId + '- printer network connect timeout error 10 sec.');
        }, 10 * 1000);
      } catch (err: any) {
        (<any>window).chrome.sockets.tcp.disconnect(socketId, () => {
          console.log(socketId, 'socket error disconnected');
          (<any>window).chrome.sockets.tcp.close(socketId, () => {
            console.log(socketId, 'socket error closed');
          });
        });
        console.error(socketId, err);
        reject(err);
      }
    });
  }

  async printToServerV2(
    printData: Uint8Array,
    printerIp: string,
    copyCount: number
  ) {
    return new Promise(async (resolve, ) => {
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };

        let body = JSON.stringify({
          printStr: printData.toString(),
          ipAddress: printerIp,
          port: 9100,
          copyCount: copyCount,
        });
        let windowsApiServiceAddress = this.parameterStore.getValue()
          .posParameters.winServiceIpAddress;
        await this.http
          .post(
            'http://' + windowsApiServiceAddress + ':1400/api/printer/printV2',
            body,
            httpOptions
          )
          .pipe(timeout(15000))
          .pipe(
            catchError(() => {
              return of({
                statusCode: 0
              })
            })
          )
          .subscribe(
            (data: any) => {
              console.log('print result:', data);
              if (data.statusCode == '200') {
                this.addFolioPrintLog(data, 'debug').then();
                this.globalService.toast(
                  '',
                  'Receipt Printed.',
                  'bottom',
                  'success'
                );
                resolve(data);
              } else {
                this.globalService.toast(
                  'Failed to Print Receipt',
                  data.message,
                  'bottom',
                  'primary'
                );
                resolve(data);
              }
            },
            (error) => {
              this.addFolioPrintLog(error, 'error').then();
              this.globalService.consolelog('print error:', error);
              console.error('print error:', error);
              resolve(error);
            }
          );
      } catch (err) {
        this.addFolioPrintLog(err, 'error').then();
        this.globalService.consolelog('print-error:', err);
        this.globalService.toast(
          'Print Error',
          'Print Service Unreachable',
          'bottom',
          'warning',
          1000
        );
        resolve(err);
      }
    });
  }

  getNetworkPrinters(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let windowsApiServiceAddress = this.parameterStore.getValue()
        .posParameters.winServiceIpAddress;
      if (!windowsApiServiceAddress)
        return reject('getPrinterResult Error Ip Not Initialized');
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        let body = JSON.stringify({});
        await this.http
          .post(
            'http://' +
              windowsApiServiceAddress +
              ':1400/api/printer/getPrinters',
            body,
            httpOptions
          )
          .subscribe(
            (data) => {
              console.log('getPrinterResult  Result:', data);

              resolve(data);
            },
            (error) => {
              console.log('getPrinterResult Error:', error);
              reject([]);
            }
          );
      } catch (error) {
        console.log('getPrinterResult Catch Error:', error);
        reject([]);
      }
    });
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  openPrinters(folio?: Folio): Promise<Printer> {
    return new Promise(async (resolve, reject) => {
      if (folio) {
        try {
          if (
            this.orwiStoreQuery.folioPrinters.filter((x) =>
              x.serviceTypes.includes(folio.type)
            ).length == 1
          ) {
            resolve(
              this.orwiStoreQuery.folioPrinters.filter((x) =>
                x.serviceTypes.includes(folio.type)
              )[0]
            );
          } else if (this.orwiStoreQuery.folioPrinters.length > 1) {
            const printerModal = await this.globalService.openModal({
              component: OrwiListModalComponent,
              cssClass: 'list-modal',
              componentProps: {
                list: this.orwiStoreQuery.folioPrinters.filter((x) =>
                  x.serviceTypes.includes(folio.type)
                ),
                value: 'ip',
                title: this.transloco.translate('Select Printer'),
                requiredText: 'Yazıcı zorunludur.',
                selectedItemType: 'list',
              },
            });

            printerModal.onDidDismiss().then(async (res) => {
              console.log(res)
              if (!res.data) {
             resolve(res.data)
                // if (
                //   this.orwiStoreQuery.folioPrinters.filter((x) =>
                //     x.serviceTypes.includes(folio.type)
                //   ).length == 1
                // ) {
                //   resolve(
                //     this.orwiStoreQuery.folioPrinters.filter((x) =>
                //       x.serviceTypes.includes(folio.type)
                //     )[0]
                //   );
                // }
              } else {
                console.log('modalDismiss', res);
                let printer = this.orwiStoreQuery.folioPrinters.find(
                  (o) => o.ip == res.data
                );
                resolve(printer);
              }
            });
          } else {
            this.globalService.toast(
              'Warning',
              'Folio printer has been disabled for this service type',
              'middle',
              'warning'
            );
          }
        } catch (err: any) {
          reject(err);
        }
      } else {
        try {
          if (this.orwiStoreQuery.folioPrinters.length == 1) {
            resolve(this.orwiStoreQuery.folioPrinters[0]);
          } else if (this.orwiStoreQuery.folioPrinters.length > 1) {
            const printerModal = await this.globalService.openModal({
              component: OrwiListModalComponent,
              cssClass: 'list-modal',
              componentProps: {
                list: this.orwiStoreQuery.folioPrinters,
                value: 'ip',
                title: this.transloco.translate('Select Printer'),
                requiredText: 'Yazıcı zorunludur.',
                selectedItemType: 'list',
              },
            });

            printerModal.onDidDismiss().then(async (res) => {
              if (!res.data) {
              } else {
                console.log('modalDismiss', res);
                let printer = this.orwiStoreQuery.folioPrinters.find(
                  (o) => o.ip == res.data
                );
                resolve(printer);
              }
            });
          } else {
            this.globalService.toast(
              'Warning',
              'No Printers Found',
              'middle',
              'warning'
            );
          }
        } catch (err: any) {
          reject(err);
        }
      }
    });

    // let activeFolio : Folio =  this.folioStore.getValue().activeFolio
    // if (activeFolio.rows.filter(x=> x.rowType == 'product').length == 0) {
    //   this.globalService.shakeElement('row-cover');
    //   return;
    // }
  }
}
