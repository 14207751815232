import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BrandsQuery } from 'src/app/modules/brands/state/brands.query';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
// import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { FoodAppApplication, FoodAppStore } from '../../../state/dto';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { FoodAppsIntegrationService } from '../../../state/food-apps-integration.service';
import { TranslocoService } from '@ngneat/transloco';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'app-ys-form',
  templateUrl: './ys-form.component.html',
  styleUrls: ['./ys-form.component.scss'],
})
export class YsFormComponent implements OnInit {
  activeApp: FoodAppApplication;
  customApplicationOptions = {
    header: this.transloco.translate('Applications'),
  };
  customBrandOptions = {
    header: this.transloco.translate('Brands'),
  };

  constructor(
    private ig: IdGeneratorService,
    private integrationService: FoodAppsIntegrationService,
    public integrationQuery: FoodAppsIntegrationQuery,
    // private publicApiService: PublicApiService,
    private glb: GlobalService,
    public brandsQuery: BrandsQuery,
    public sessionQuery: SessionQuery,
    private nav: NavController,
    private transloco: TranslocoService,
    private ssoService: SSOSessionService
  ) {}

  ngOnInit() {
    if (!this.sessionQuery.authStatus) {
      this.nav.navigateRoot('welcome');
    }


    this.activeApp = this.integrationQuery.foodAppAplications.find(
      (o) => o.id == this.integrationQuery.activeAccount.foodApp
    );
    console.log(this.activeApp);
    console.log(this.integrationQuery.activeAccount);
  }

  changeActiveStatus(e) {
    console.log(e);
    this.integrationQuery.activeAccount.isActive = e.detail.checked;
  }

  changeLogStatus(e) {
    this.integrationQuery.activeAccount.isDetailLogActive = e.detail.checked;
  }


  changeServerType(e) {
    this.integrationQuery.activeAccount.isTest = e.detail.checked;
  }

  changeAcceptOrderStatus(e) {
    this.integrationQuery.activeAccount.autoOrderAccept = e.detail.checked;
  }

  async save() {
    this.glb.showLoading();
    if (this.integrationQuery.activeAccount.id == 'new') {
      if (
        this.integrationQuery.activeAccount.foodApp == 'yemek-sepeti' ||
        this.integrationQuery.activeAccount.foodApp == 'custom'
      ) {
        this.integrationQuery.activeAccount.id = this.integrationQuery.activeAccount.userName;
        this.integrationQuery.activeAccount.restaurantId = this.integrationQuery.activeAccount.userName;
      } else {
        this.integrationQuery.activeAccount.id = this.ig.generate();
      }
    }

    if (
      this.integrationQuery.foodAppAplications.find(
        (x) => x.id === this.integrationQuery.activeAccount.foodApp
      ).type === 'deliver-app'
    ) {
      this.integrationQuery.activeAccount.isDeliveryApp = true;
    } else {
      this.integrationQuery.activeAccount.isDeliveryApp = false;
    }

    await this.integrationService.saveAccount(this.integrationQuery.activeAccount)
      .then((res) => {
        console.log(res);

        // this.integrationService.getFoodAppIntegrations();
        // if (this.integrationQuery.activeAccount.foodApp == 'yemek-sepeti') {
        //   this.saveStore();
        // }
        // this.integrationService.getFoodAppIntegrations()
        this.glb.toast('Save successful', '', 'middle', 'success');
        this.glb.closeLoading();
      })
      .catch((err) => {
        if (err.error.message?.includes('E11000')) {
          this.glb.toast(
            '',
            'Bu hesap daha önce farklı bir store için tanımlandı.',
            'middle',
            'warning'
          );
        } else {
          this.glb.toast('', 'An error occured', 'middle', 'warning');
        }

        this.integrationQuery.activeAccount.id = 'new';
        this.glb.closeLoading();
      });
  }

  async saveStore() {
    let foodAppStore: FoodAppStore = new FoodAppStore();
    foodAppStore.accountId = this.integrationQuery.activeAccount.id;
    foodAppStore.id = this.integrationQuery.activeAccount.id;
    foodAppStore.source = this.integrationQuery.activeAccount.foodApp;
    foodAppStore.averagePreparationTime = 20;
    foodAppStore.internalId = this.sessionQuery.activeLicense.orwiStore.id;
    foodAppStore.lastEditDate = new Date();
    foodAppStore.name = this.integrationQuery.activeAccount.description;
    foodAppStore.status = 'closed';
    foodAppStore.usefoodAppCourier = false;
    await this.integrationService.saveFoodAppStore(foodAppStore).then((x) => {
      x;
      this.integrationService.getFoodAppIntegrations();
    });
  }

  appChanged(e) {
    this.activeApp = this.integrationQuery.foodAppAplications.find(
      (o) => o.id == e.detail.value
    );
    console.log(this.activeApp);
  }

  delete() {
    if (!this.ssoService.checkPermission('integration-list-delete')) {
      this.glb.permissionToast();
      return;
    }
    this.integrationService.deleteFoodAppAccount().then((x) => {
      x;
      this.integrationService.getFoodAppIntegrations();
    });
    this.nav.pop();
  }
}
