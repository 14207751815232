<div style="display: flex; flex-direction: column; height: 100vh;">
  <orwi-modal-header [title]="'Loyalty' | transloco" (onClose)="handleClose()"></orwi-modal-header>

  <div style="flex: 1; padding: 1rem;">
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex; align-items: center;">
        <ion-input class="selector" [label]="'QR Code' | transloco" #qrCodeInput></ion-input>
        <ion-button class="action-btn" (click)="getUserCoupons(qrCodeInput.value)" [disabled]="qrCodeInput.value?.toLocaleString().length < 6">
          <ion-icon slot="start" src="assets/icons/binoculars.svg"></ion-icon> {{ 'Retrieve' | transloco }}
        </ion-button>
        <ion-button class="action-btn" (click)="qrCodeInput.value = ''">
          <ion-icon slot="start" name="close"></ion-icon> {{ 'Clear' | transloco }}
        </ion-button>
      </div>
      <ion-input class="selector" [label]="'User' | transloco"></ion-input>
    </div>
    <div class="stamp-coupon">
      <ion-button class="action-btn">
        <ion-icon slot="start" src="assets/icons/tag-starred.svg"></ion-icon>
        {{ 'Stamps' | transloco }}
      </ion-button>
      <ion-button class="action-btn">
        <ion-icon slot="start" src="assets/icons/ticket.svg"></ion-icon>
        {{ 'Coupons' | transloco }}
      </ion-button>
    </div>
    <div style="width: 100%; display: flex; justify-content: end;">
      <ion-input class="selector amount" [label]="'Total' | transloco" disabled="true" [value]="totalPoints"></ion-input>
    </div>

    <dx-data-grid [dataSource]="coupons" *transloco="let t">
      <dxi-column dataField="name" [caption]="t('Name')"></dxi-column>
      <dxi-column dataField="amount" [caption]="t('Amount')"></dxi-column>
      <dxi-column dataField="points" [caption]="t('Points')"></dxi-column>
      <dxi-column dataField="used" [caption]="t('Use')"></dxi-column>
    </dx-data-grid>
    <div class="end-info">
      <ion-input class="selector amount" [label]="'Used' | transloco" [value]="usedPoints"></ion-input>
      <ion-input class="selector amount" [label]="'Remaining' | transloco" [value]="remainingPoints"></ion-input>
    </div>
  </div>

  <ion-buttons class="footer-btns" style="position: sticky; bottom: 0; display: flex; justify-content: space-between; padding: 1rem;">
    <ion-button style="--background: #ae1818" (click)="modalService.dismiss()">{{ 'Close' | transloco }}</ion-button>
    <ion-button style="--background: #4cd9ed">{{ 'Apply' | transloco }}</ion-button>
  </ion-buttons>
</div>
