import { Component, OnInit, Renderer2 } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { PaxComponent } from 'src/app/modules/folio/components/pax/pax.component';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { MenuQuery } from 'src/app/modules/menu/state/menu.query';
import { MenuService } from 'src/app/modules/menu/state/menu.service';
import { MenuStore } from 'src/app/modules/menu/state/menu.store';
import { Printer } from 'src/app/services/dto/orwi-definitions';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { Product } from 'src/app/services/dto/orwi-product';
import { GlobalService } from 'src/app/services/global.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { ParametersQuery } from 'src/app/modules/parameters/state/parameters.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { TableStore } from 'src/app/modules/tables/state/table.store';
import { EcrService } from 'src/app/services/ecr/ecr.service';
import { ParametersStore } from 'src/app/modules/parameters/state/parameters.store';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'folio-page',
  templateUrl: './phone-folio-page.component.html',
  styleUrls: ['./phone-folio-page.component.scss'],
})
export class PhoneFolioPageComponent implements OnInit {
  onSave = false;

  printers: Printer[] = [];
  folioPrinters: Printer[] = [];
  unitPrinters: Printer[] = [];

  constructor(
    private menuStore: MenuStore,
    private menuService: MenuService,
    private renderer: Renderer2,
    private plt: Platform,
    private sessionQuery: SessionQuery,
    private nav: NavController,
    private fStore: FolioStore,
    private fs: FolioService,
    public fQ: FolioQuery,
    private glb: GlobalService,
    public mQ: MenuQuery,
    private modidierService: ModifiersService,
    private printerService: PrintCoverService,
    private paramQuery: ParametersQuery,
    public ssoService: SSOSessionService,
    private folioStore: FolioStore,
    private tableStore: TableStore,
    private ecrService: EcrService,
    private parameterStore: ParametersStore,
    private transloco: TranslocoService
  ) {
    //this.fStore.update({activeFolio: new Folio()})
  }

  async ngOnInit() {
    if (!this.sessionQuery.user) {
      this.nav.navigateRoot('welcome');
      return;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calcSize(this.plt.height() / 2);
    }, 200);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.calcSize(this.plt.height() / 2);
    }, 10);
  }

  select(item) {
    this.menuService.selectGroup(item);
  }

  onResizeEnd(e) {
    this.calcSize(e.rectangle.height);
  }

  productClick(p: Product) {
    this.menuStore.update({ selectedProduct: { product: p, time: moment() } });
  }

  async ionViewDidEnter() {
    if (this.fStore.getValue().activeFolio.type === 'table') {
      if (
        this.paramQuery.getValue().posParameters.globalParameters
          .paxSelectAutoShow &&
        this.fStore.getValue().activeFolio.pax === 0
      ) {
        await this.glb.openModal({
          component: PaxComponent,
          cssClass: 'reason-modal',
        });
      }
    }
    if (
      this.fQ.getValue().activeFolio.customerName.length == 0 &&
      (this.paramQuery.getValue().posParameters.globalParameters as any)
        .askCustomerName
    ) {
      try {
        const customerName = await this.glb.showCustomerName();
        this.fQ.getValue().activeFolio.customerName = customerName;
      } catch (error) {
        const activeFolio: Folio = this.folioStore.getValue().activeFolio;
        if (!activeFolio) {
          return;
        }
        // if (activeFolio.rows.every((el) => el.recordStatus == 'deleted')) {
        //   activeFolio.status = 'cancelled';
        //   this.folioService._posSaveFolio(activeFolio);
        // }

        await this.fs.loadOpenFolios();
        this.nav.pop();
      }
    }
  }

  checkPermission() {
    if (!this.ssoService.checkPermission('folio-find-product')) {
      this.glb.permissionToast();
      return;
    }
  }

  calcSize(height: number) {
    let fl = document.getElementById('folio-resize');
    let pr = document.getElementById('product');
    let gr = document.getElementById('group-cover');
    let mn = document.getElementById('menu-cover');

    var style = getComputedStyle(document.body);

    // console.log(
    //   height,
    //   style.getPropertyValue('--offset-bottom'),
    //   style.getPropertyValue('--offset-top'),
    //   style.getPropertyValue('--ion-safe-area-bottom'),
    //   style.getPropertyValue('--ion-safe-area-top')
    // );

    let header = document.getElementById('phone-folio-header').offsetHeight;
    let footer = document.getElementById('phone-folio-footer').offsetHeight;

    let top = style.getPropertyValue('--ion-safe-area-top');
    let bottom = style.getPropertyValue('--ion-safe-area-bottom');

    top = top.replace('px', '');
    let safeTop = parseInt(top);

    bottom = bottom.replace('px', '');
    let safeBottom = parseInt(bottom);

    console.log('---->', safeTop, header, safeBottom, footer);

    this.renderer.setStyle(fl, 'height', height + 'px');
    let s = this.plt.height() - (height + (header + footer));

    this.renderer.setStyle(mn, 'height', s + 'px');
    this.renderer.setStyle(pr, 'height', s + 'px');
    this.renderer.setStyle(gr, 'height', s + 'px');
  }

  async done() {
    if (
      !this.ssoService.checkPermission('folio-use-send-on-selfservice') &&
      this.fStore.getValue().activeFolio.type === 'self'
    ) {
      this.glb.permissionToast();
      return;
    }

    if (this.fQ.mainRows().length == 0) {
      this.glb.shakeElement('done-button');
    } else {
      this.glb.showLoading();
      console.log('doneFolio', this.fStore.getValue().activeFolio);

      // if (this.folioStore.getValue().activeFolio.lock.status == 'locked')
      //   {
      //     let diff = moment(this.folioStore.getValue().activeFolio.lock.time);
      //     let msg = `Masa  ${this.folioStore.getValue().activeFolio.lock.userName} tarafından  ${moment().diff(
      //       diff,
      //       'minute'
      //     )} dakika önce kilitlenmiştir.İşlem Yapamazsınız`;
      //     this.glb.toast('Masa Kilidi',msg,'bottom','warning')
      //     this.glb.closeAllLoading();
      //     // this.nav.back();
      //     return
      //   }

      if (
        this.fStore
          .getValue()
          .activeFolio.rows.filter(
            (x) => x.folioId == '' && x.recordStatus == 'deleted'
          ).length > 0
      ) {
        this.fStore
          .getValue()
          .activeFolio.rows.filter(
            (x) => x.folioId == '' && x.recordStatus == 'deleted'
          )
          .map((x) => {
            let index = this.fStore.getValue().activeFolio.rows.indexOf(x);
            this.fStore.getValue().activeFolio.rows.splice(index, 1);
          });
      }

      // let newFolio = await this.fs._posSaveFolio(this.fStore.getValue().activeFolio)
      this.fs._posSaveFolio().then((folioResult) => {
        setTimeout(async () => {
          await this.printerService.printLabel(folioResult);
          await this.printerService.printUnit(folioResult);
          await this.fs._posDoneFolio(folioResult);
          // this.printerService.printFolio(folioResult)
        }, 500);
      });
      this.glb.closeAllLoading();
      this.nav.back();
    }
  }

  async cancel() {
    const activeFolio: Folio = this.fStore.getValue().activeFolio;
    if (!activeFolio) {
      return;
    }
    if (activeFolio.type === 'table') {
      if (this.tableStore.getValue().selectedTable) {
        this.tableStore.getValue().selectedTable.state = 'close';
      }
    }

    await this.fs.loadOpenFolios();
    this.nav.pop();
  }

  payment() {
    if (!this.ssoService.checkPermission('folio-payment-actions')) {
      this.glb.permissionToast();
      return;
    }

    if (this.fQ.total === 0) {
      this.glb.shakeElement('payment-button');
      this.glb.toast(
        'folio',
        this.transloco.translate('You Cannot Close 0 Consistent Tickets'),
        'bottom',
        'warning'
      );
      return;
    }
    console.log('payment clicked!');
    if (this.fQ.mainRows().length == 0) {
      this.glb.shakeElement('payment-button');
    } else {
      // if (this.folioStore.getValue().activeFolio.lock.status == 'locked')
      //   {
      //     let diff = moment(this.folioStore.getValue().activeFolio.lock.time);
      //     let msg = `Masa  ${this.folioStore.getValue().activeFolio.lock.userName} tarafından  ${moment().diff(
      //       diff,
      //       'minute'
      //     )} dakika önce kilitlenmiştir.İşlem Yapamazsınız`;
      //     this.glb.toastButton('Masa Kilidi',msg,'bottom','warning')
      //     this.glb.closeAllLoading();
      //     // this.nav.back();
      //     return
      //   }


        if (
          this.parameterStore.getValue().posParameters.ecrParameters
            .ecrModel === 'pavo'
        ) {
          this.connect();
        }


      this.nav.navigateForward('pos-home/pos-payment');
    }
  }

  async connect() {
    //await this.ecrSettingService.getEcrSetting();

    this.ecrService.connectEcr().then((x) => {
      x;
      //TODO:x error ekrana bas
      this.glb.closeLoading();
    });
  }

  key(e) {
    if (e.key == 'Enter') {
    }
  }

  isSearched: boolean;
  handleInput(event) {
    const query: string = event.target.value.toLowerCase();
    this.isSearched = query.length > 0;
    this.menuService.serachProduct(query);
  }

  showModifiers() {
    if (!this.fStore.getValue().selectedRow) {
      this.glb.toast('', 'Please Select the Product', 'bottom', 'warning');
      return;
    }
    if (this.fStore.getValue().selectedRow.recordStatus == 'new') {
      this.modidierService.modifiers(
        this.modidierService.hasModifiers(
          this.fStore.getValue().selectedRow.itemID
        )
      );
    } else {
      this.glb.toast(
        '',
        'You Cannot Enter Variations in the Sent Product.',
        'bottom',
        'warning'
      );
    }
  }

  async setPax() {
    await this.glb.openModal({
      component: PaxComponent,
      cssClass: 'reason-modal',
    });
  }

  async setGift() {
    if (
      !this.ssoService.checkPermission('folio-gift-product') ||
      (!this.ssoService.checkPermission('folio-gift-old-product') &&
        this.folioStore.getValue().selectedRow.recordStatus === 'old')
    ) {
      this.glb.permissionToast();
      return;
    }

    if (!this.fStore.getValue().selectedRow) {
      this.glb.toast(
        '',
        'Please Select the Product to Be Treated',
        'bottom',
        'warning'
      );
      return;
    }
    this.fs.askGiftSelectedRows();
  }

  async printFolio(folio?: Folio) {
    if (
      !this.ssoService.checkPermission('folio-use-send-on-selfservice') &&
      this.fStore.getValue().activeFolio.type === 'self'
    ) {
      this.glb.permissionToast();
      return;
    }
    folio;
    if (this.fQ.mainRows().length == 0) {
      this.glb.shakeElement('done-button');
    } else {
      this.glb.showLoading();

      // if (this.folioStore.getValue().activeFolio.lock.status == 'locked')
      //   {
      //     let diff = moment(this.folioStore.getValue().activeFolio.lock.time);
      //     let msg = `Masa  ${this.folioStore.getValue().activeFolio.lock.userName} tarafından  ${moment().diff(
      //       diff,
      //       'minute'
      //     )} dakika önce kilitlenmiştir.İşlem Yapamazsınız`;
      //     this.glb.toast('Masa Kilidi',msg,'bottom','warning')
      //     this.glb.closeAllLoading();
      //     // this.nav.back();
      //     return
      //   }

      if (
        this.fStore
          .getValue()
          .activeFolio.rows.filter(
            (x) => x.folioId == '' && x.recordStatus == 'deleted'
          ).length > 0
      ) {
        this.fStore
          .getValue()
          .activeFolio.rows.filter(
            (x) => x.folioId == '' && x.recordStatus == 'deleted'
          )
          .map((x) => {
            let index = this.fStore.getValue().activeFolio.rows.indexOf(x);
            this.fStore.getValue().activeFolio.rows.splice(index, 1);
          });
      }

      this.fs._posSaveFolio().then((folioResult) => {
        setTimeout(async () => {
          await this.printerService.printLabel(folioResult);
          await this.printerService.printUnit(folioResult);
          this.printerService.printFolio(folioResult);
          this.fs._posDoneFolio(folioResult);
        }, 500);
      });
      this.glb.closeAllLoading();
      this.nav.back();
    }
  }
}
