import { Component, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { arrayAdd, arrayUpdate } from '@datorama/akita';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { PaymentQuery } from '../../state/payment.query';
import { PaymentStore } from '../../state/payment.store';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'payment-folio',
  templateUrl: './payment-folio.component.html',
  styleUrls: ['./payment-folio.component.scss'],
})
export class PaymentFolioComponent implements OnInit {
  folioRowsItem = {};
  selectedFolioRowLength = 0;
  quantityTracker: { [key: string]: number } = {};

  constructor(
    private glb: GlobalService,
    private printerCoverService: PrintCoverService,
    private folioStore: FolioStore,
    private folioService: FolioService,
    private paymentStore: PaymentStore,
    private uuidService: IdGeneratorService,
    public paymentQuery: PaymentQuery,
    public orwiStoreQuery: OrwiStoreQuery,
    private ssoService: SSOSessionService,
    public folioQuery: FolioQuery,
    private plt: Platform,
    private r2: Renderer2,
    private transloco : TranslocoService
  ) {}

  ngOnInit(): void {
    let fd = document.getElementById('fd');
    let os = this.plt.height() - 346;
    this.r2?.setStyle(fd, 'height', os + 'px');

    this.initializeQuantityTracker();
  }

  async split() {
    if (!this.ssoService.checkPermission('payment-split-folio')) {
      this.glb.permissionToast();
      return;
    }
    this.selectedFolioRowLength = 0;
    let masterActiveFolio = { ...this.getActiveFolio() };
    let activeFolio: Folio = this.folioStore.getValue().activeFolio;
    let cpy: Folio = JSON.parse(JSON.stringify(masterActiveFolio));

    console.log(
      'split işlemi',
      activeFolio.rows.filter((x) => x.rowType == 'product')
    );
    let filteredRows = activeFolio.rows.filter((x) => x.rowType == 'product')
    if (filteredRows.length == 1 && this.quantityTracker[filteredRows[0].id] == filteredRows[0].qty ) {
      this.glb.shakeElement('row-cover');
      this.glb.toast(
        "Divide Bill",
        'You cannot select all lines in the source bill!',
        'bottom',
        'warning'
      );
      activeFolio.rows.map((row) => (row.selected = false));
      return;
    }
    let masterActiveFolioSelectableRows = [
      ...masterActiveFolio.rows
        .filter((fr) => fr.recordStatus != 'deleted')
        .filter((fr) => fr.rowType == 'product'),
    ];
    const selectedRows: FolioRow[] = [
      ...masterActiveFolio.rows
        .filter((o) => o.selected)
        .filter((o) => o.recordStatus != 'deleted')
        .filter((fr) => fr.rowType == 'product'),
    ];

    console.log(selectedRows, masterActiveFolioSelectableRows);
    if (
      masterActiveFolioSelectableRows.length === selectedRows.length &&
      selectedRows.length > 0 && selectedRows.every((row) => row.qty == this.quantityTracker[row.id])
    ) {
      this.glb.toast(
        'Divide Bill',
        'You cannot select all lines in the source bill!',
        'bottom',
        'warning'
      );
      masterActiveFolio.rows.map((row) => (row.selected = false));
      this.glb.shakeElement('row-cover');
      return;
    }

    if (
      selectedRows.every((row) => row.price === 0 || row.isGift)
    ) {
      this.glb.toast(
        'Split Bill',
        'Rows with total price of 0 cannot be split!',
        'bottom',
        'warning'
      );
      masterActiveFolio.rows.map((row) => (row.selected = false));
      this.glb.shakeElement('row-cover');
      return;
    }

    console.log('selectedRows', selectedRows);
    if (this.canMove(selectedRows, masterActiveFolio)) {
      this.glb.shakeElement('row-cover');
      return;
    }

    let remainingSplitRows: FolioRow[] = masterActiveFolioSelectableRows

      selectedRows.forEach(row => {
        for (let i = masterActiveFolioSelectableRows.length - 1; i >= 0; i--) {
          if (masterActiveFolioSelectableRows[i].id === row.id) {
            masterActiveFolioSelectableRows.splice(i, 1);
          }
        }
      });

      if(remainingSplitRows.length > 0 && remainingSplitRows.every((row) => row.price === 0 || row.isGift)){
        this.glb.toast(
          'Split Bill',
          'Resulting folio cannot have a total of 0!',
          'bottom',
          'warning'
        );
        masterActiveFolio.rows.map((row) => (row.selected = false));
        this.glb.shakeElement('row-cover');
        this.glb.closeLoading();
        return;
      }
    for(const row of selectedRows) {
      await this.qtySplit(row)

      this.glb.showLoading('');
      cpy.shortCode = '';
  
      cpy.paxChild = 0;
      cpy.paxFemale = 0;
      cpy.paxMale = 0;
      cpy.pax = 0;
      cpy.note = '';
      // cpy.lock.status = 'unlocked'
      // masterActiveFolio.lock.status = 'unlocked'
      cpy.rows.map((x) => {
        console.log('cpy el:', x);
        if (
          !x.selected &&
          !x.isModifier &&
          !x.isMustModifier &&
          x.rowType != 'note'
        ) {
          x.recordStatus = 'deleted';
          this.getModifiers(cpy.rows, x.id).forEach((el) => {
            el.recordStatus = 'deleted';
          });
        } else if (!x.selected && !x.isModifier && !x.isMustModifier && x.rowType != 'note') {
          const row = this.getFolioRow(x.id);
          if (row.qty > 0) {
            x.qty = row.qty;
          }
        }
      });
    
      masterActiveFolio.rows.map((x) => {
        x.price = x.unitPrice * x.qty;
        if (
          x.selected &&
          !x.isModifier &&
          !x.isMustModifier &&
          x.recordStatus != 'deleted' &&
          x.rowType == 'product'
        ) {
          const row = this.getFolioRow(x.id);
          if (row.qty > 0) {
            const diff = +x.qty - +row.qty;
            if (diff <= 0) {
              x.recordStatus = 'deleted';
              this.getModifiers(masterActiveFolio.rows, x.id).forEach((el) => {
                el.recordStatus = 'deleted';
              });
            } else {
              x.qty = +diff;
              row.qty = +diff;
            }
          } else if (row.qty == 0) {
            x.recordStatus = 'deleted';
            this.getModifiers(masterActiveFolio.rows, x.id).forEach((el) => {
              el.recordStatus = 'deleted';
            });
          }
        }
      });
      
    }
      delete cpy.id;
      cpy.discount = 0;
      cpy.sequenceNo = 0;
      cpy.rows.map((o) => (o.selected = false));
      masterActiveFolio.rows.map((o) => (o.selected = false));
  
      masterActiveFolio.rows.map((x) => {
        if (x.recordStatus === 'deleted') {
          x.deleters.push({
            userID: this.ssoService.userInfo.id,
            userName: this.ssoService.userInfo.name,
            qty: x.qty,
            reason: 'split',
            reasonID: 'split',
            time: new Date(),
          });
        }
      });
      
  
      // masterActiveFolio.rows = masterActiveFolio.rows.filter(
      //   (x) => x.recordStatus !== 'deleted'
      // );
  
      cpy.rows = cpy.rows.filter((x) => x.recordStatus !== 'deleted');
      cpy.rows.map((row) => {
        let pId: string = this.uuidService.generate().toString();
        cpy.rows
          .filter((y) => y.parentID === row.id)
          .map((y) => {
            y.parentID = pId;
          });
        row.qty = this.quantityTracker[row.id]
        row.price = row.qty * row.unitPrice
        row.id = pId;

        if (this.quantityTracker[row.id] === undefined) {
          this.quantityTracker[row.id] = 1;
        }
      });
  
      cpy.parentID = masterActiveFolio.id;
      await this.folioService._posSaveFolio(cpy, 'high').then(async (result) => {
      //Hedef Ads Kitlenir
      cpy = result;
      await this.folioService.lockUnlockFolio(result.id, 'locked');
    });

    console.log('CPY', cpy);
    console.log('masterActiveFolio', masterActiveFolio);
    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayAdd(openFolios, cpy),
    }));

    await this.folioService._posSaveFolio(masterActiveFolio, 'high').then((resultFolio) => {
      this.paymentStore.update(({folios}) => ({
        folios: arrayUpdate(
          folios,
          resultFolio.id,
          resultFolio,
          'id'
        )
      }))
    })

    let logObject = {
      id: this.uuidService.generate(),
      storeId: masterActiveFolio.storeId,
      folioId: this.folioStore.getValue().activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'split-folio',
      userId: this.ssoService.userInfo.id,
      userName: this.ssoService.userInfo.name,
      data: JSON.stringify(masterActiveFolio.rows.filter(x=>x.recordStatus === 'deleted')),
      infoType: 'information',
      description: this.transloco.translate('Split Folio'),
    };
    await this.folioService.saveFolioLog(logObject);
    //Kaynak Ads Kitlenir.
    // await this.folioService.lockUnlockFolio(
    //   masterActiveFolio.id,
    //   'locked'
    // );
    this.paymentStore.update(({ folios }) => ({
      folios: arrayAdd(folios, cpy),
    }));

    //let lastId = this.paymentQuery.getValue().folios[folioCount - 1].id;
    // this.folioStore.update(() => ({
    //   activeFolio: cpy,
    // }));
    // this.paymentStore.update({ activeFolioId: cpy.id });
   await this.selectFolio(cpy.id);
    // console.log(cpy);

    await this.glb.playSplitFolio();
    Object.keys(this.quantityTracker).forEach(id => {
      this.quantityTracker[id] = 1;
    });
    this.glb.closeLoading();
  }

  qtyChange(e, folio: FolioRow) {
    // row.price = row.qty * row.unitPrice;
    //   let subModifiers = this.folioStore.getValue().activeFolio.rows.filter(rw => rw.parentID == row.id);

    //   subModifiers.forEach(rw => {
    //     rw.qty = row.qty;
    //   })
    const fRowItem = this.getFolioRow(folio.id);
    fRowItem.qty = +e.detail.value;
    fRowItem.valid = +folio.qty >= fRowItem.qty && fRowItem.qty > 0;
    console.log('', fRowItem);
  }

  select(item: FolioRow) {
    item.selected = item.selected ? false : true;
    this.getFolioRow(item.id).selected = item.selected;
    this.selectedFolioRowLength = this.getActiveFolio().rows.filter(
      (o) => o.selected
    ).length;
  }

  initializeQuantityTracker() {
    const folios = this.getFolioWithMDF();
    folios.forEach((item) => {
      if (this.quantityTracker[item.id] === undefined) {
        this.quantityTracker[item.id] = 1;
      }
    });
  }

  getFolioWithMDF(){
    let fls = this.paymentStore.getValue().folios;
    let id = this.paymentQuery.getValue().activeFolioId;
    return fls
      .find((ns) => ns.id == id)
      ?.rows?.filter(
        (o) =>
          o.recordStatus !== 'deleted' &&
          o.rowType !== 'payment' &&
          o.rowType !== 'discount'
      );
  }

  getFolio() {
    let fls = this.paymentStore.getValue().folios;
    let id = this.paymentQuery.getValue().activeFolioId;
    return fls
      .find((ns) => ns.id == id)
      ?.rows?.filter(
        (o) =>
          o.recordStatus !== 'deleted' &&
          o.parentID == '' &&
          o.rowType !== 'payment' &&
          o.rowType !== 'discount'
      );
  }

  selectFolio(folioId) {
    let ac: Folio = this.folioStore
      .getValue()
      .openFolios.find((x) => x.id === folioId);
    this.folioStore
      .getValue()
      .activeFolio.rows.map((x) => (x.selected = false));
    this.folioStore.update({ activeFolio: ac });
    this.paymentStore.update({ activeFolioId: ac.id });
    this.paymentQuery.getValue().activeFolioId = ac.id;
  }

  getFolioRow(id: string) {
    const activeFolioid = this.paymentQuery.getValue().activeFolioId;

    let item = this.folioRowsItem[id + activeFolioid];
    if (item != null && item != undefined) {
      return item;
    }
    const folioRow: FolioRow = this.getFolio().find((el) => el.id === id);
    this.folioRowsItem[id + activeFolioid] = {
      qty: folioRow.qty ?? 0,
      valid: true,
    };
    return this.getFolioRow(id);
  }

  canMove(selectedRows: FolioRow[], masterActiveFolio: Folio) {
    selectedRows
    return (
      // selectedRows.length == 0 ||
      // this.isHaveEnoughCout(selectedRows, masterActiveFolio) ||
      // selectedRows.some((el) => !this.getFolioRow(el.id).valid) ||
      this.paymentQuery.folioIsDone(masterActiveFolio.id)
    );
  }

  isHaveEnoughCout(selectedRows: FolioRow[], masterActiveFolio): boolean {
    return (
      selectedRows.length == masterActiveFolio.rows.length &&
      selectedRows.every((el) => +el.qty - +this.getFolioRow(el.id).qty <= 0)
    );
  }

  getActiveFolio() {
    return this.paymentStore
      .getValue()
      .folios.find((o) => o.id == this.paymentQuery.getValue().activeFolioId);
  }

  getModifiers(row: FolioRow[], id: string): FolioRow[] {
    return row.filter((el) => el.parentID == id);
  }

  async openPrinters() {
    if (!this.ssoService.checkPermission('folio-print')) {
      this.glb.permissionToast();
      return;
    }
    this.printerCoverService.printFolio(this.folioStore.getValue().activeFolio);
  }

  async qtySplit(item: FolioRow) {
    console.log('item', item);
    if (!this.ssoService.checkPermission('payment-split-folio')) {
      this.glb.permissionToast();
      return;
    }

    let selectedQty = this.quantityTracker[item.id]
    if (!selectedQty) {
      selectedQty = 0;
    }

    if (selectedQty > 0) {
      let modifiers = this.getModifiers(this.getActiveFolio().rows, item.id);
        let folioRow = this.folioService.createFolioRow(
          item.id,
          item.name,
          selectedQty,
          '',
          item.unitPrice,
          item.printer,
          item.ecrDepartmentId,
          item.tax
        );
      this.getActiveFolio().rows.forEach((row) => {
        row.selected = false;
      });
      folioRow.selected = true;
      folioRow.folioId = item.folioId;
      let newModifiers = [];
      modifiers.forEach((modifier) => {
        console.log(modifier);
        let newModifier = this.folioService.createFolioRow(
          modifier.id,
          modifier.name,
          selectedQty,
          folioRow.id,
          modifier.unitPrice,
          modifier.printer,
          modifier.ecrDepartmentId
        );
        newModifier.rowType = modifier.rowType;
        newModifier.isModifier = modifier.isModifier;
        newModifier.isMustModifier = modifier.isMustModifier;
        newModifier.folioId = modifier.folioId;
        // if (localStorage.getItem('activeStore') == '050d-57752172364-f961') {
        newModifier.itemID = newModifier.id;
          modifier.qty -= selectedQty;
        newModifiers.push(newModifier);
      });
      console.log('', modifiers);
      let foundedRow = this.paymentStore
        .getValue()
        .folios.find(
          (fl) => fl.id == this.paymentQuery.getValue().activeFolioId
        )
        ?.rows.find((row) => row.id == item.id);
      if (foundedRow.qty != selectedQty) {
        foundedRow.qty -= selectedQty;
      } else {
        foundedRow.qty = selectedQty
        foundedRow.recordStatus = 'deleted'
      }
      if(selectedQty != item.qty){
        this.paymentStore
          .getValue()
          .folios.find(
            (fl) => fl.id == this.paymentQuery.getValue().activeFolioId
          )
          ?.rows.push(folioRow, ...newModifiers);
      }
      // }
      // this.split();
    }
  }

  increase(id: string, item: any) {
    item.selected = true
    this.getFolioRow(item.id).selected = item.selected;
    this.selectedFolioRowLength = this.getActiveFolio().rows.filter(
      (o) => o.selected
    ).length;
    if (this.quantityTracker[id] < item.qty) {
      this.quantityTracker[id] += 1;
      if(this.getModifiers(this.getActiveFolio().rows, id).length > 0){
        this.getModifiers(this.getActiveFolio().rows, id).forEach(modifier => {
          this.quantityTracker[modifier.id] += 1;
        });
      }
    }
  }
  
  decrease(id: string) {
    if (this.quantityTracker[id] > 1) {
      this.quantityTracker[id] -= 1;
      if(this.getModifiers(this.getActiveFolio().rows, id).length > 0){
        this.getModifiers(this.getActiveFolio().rows, id).forEach(modifier => {
          this.quantityTracker[modifier.id] -= 1;
        });
      }
    }
  }
}

@Component({
  selector: 'qty-popover',
  template: `
    <div>
      <div class="popover-qty-content">
        <ion-button
          size="small"
          (click)="decrease()"
          fill="clear"
          [disabled]="this.selectedQty == 1"
        >
          <ion-icon slot="icon-only" name="remove-circle-outline"></ion-icon>
        </ion-button>
        <ion-text style="user-select:none"> {{ selectedQty }} </ion-text>
        <ion-button
          size="small"
          (click)="increase()"
          fill="clear"
          [disabled]="this.selectedQty == this.maxQty - 1"
        >
          <ion-icon slot="icon-only" name="add-circle-outline"></ion-icon>
        </ion-button>
      </div>
      <div>
        <ion-button size="small" (click)="submit()" expand="block">
          {{ 'Submit' | transloco }}
        </ion-button>
      </div>
    </div>
  `,
})
export class QtyPopoverComponent {
  globalService = inject(GlobalService);
  @Input({ required: true })
  maxQty = 0;

  selectedQty = 1;

  increase() {
    console.log('', this.selectedQty);
    if (this.selectedQty < this.maxQty - 1) this.selectedQty += 1;
  }

  decrease() {
    console.log('', this.selectedQty);
    if (this.selectedQty >= 2) this.selectedQty -= 1;
  }

  submit() {
    console.log('', this.selectedQty);
    this.globalService.closePopover({
      selectedQty: this.selectedQty,
    });
  }
}
