import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { SideMenuService } from 'src/app/modules/side-menu/state/side-menu.service';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { TableQuery } from '../../state/table.query';
import { TableService } from '../../state/table.service';
import { TableStore } from '../../state/table.store';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'orwi-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
})
export class TableContainerComponent implements OnInit, OnDestroy {
  items: ITable[] = [];
  newTable: ITable;
  mode: string = '';
  selectedPosMenuSubscription: Subscription;
  constructor(
    public tq: TableQuery,
    public glb: GlobalService,
    private sideMenuQuery: SideMenuQuery,
    private folioService: FolioService,
    private folioStore: FolioStore,
    private ts: TableService,
    private sideMenuService: SideMenuService,
    public tableStore: TableStore,
    private modalService: ModalService,
    private orwiStoreStore: OrwiStoreQuery,
    private transloco: TranslocoService
  ) {

    this.tableStore.getValue().tables.forEach((el) => (el.selected = false));
    this.selectedPosMenuSubscription =
      this.sideMenuQuery.posMenuSelected$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.mode = value.id;

        this.tableStore
          .getValue()
          .tables.forEach((el) => (el.selected = false));
        if (this.tableStore.getValue().selectedTableGroup) {
          this.tableStore.getValue().selectedTableGroup.selected = false;
        }
        this.tableStore.update({
          selectedTable: null,
          selectedTableGroup: null,
          activeTables: null,
        });
      });



  }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  async itemSelected(tb: ITable) {
    console.log(tb)
    /* this.oe.activeTable = tb
    this.oe.tableEvents.next("select") */
    // this.folioService.updateActiveFolio(tb.folios[0])

    if (this.mode === 'change') {
      if (!this.tableStore.getValue().tables.some((o) => o.selected)) {
        tb.selected = true;
        this.tableStore.update({ selectedTable: tb });
        this.glb.toast(
          `Lütfen ${tb.name} masasını taşıyacağınız masayı seçiniz`,
          '',
          'bottom',
          'success'
        );
      } else {
        const movedTable: ITable = this.tableStore.getValue().selectedTable;
        const movedFolio: Folio = movedTable?.folios[0];
        /**
         *  area. Do you confirm?
         */
        // Question
        const infoModal = await this.glb.openAlert({
          // component: QuestionDialogComponent,
          // showBackdrop: true,
          backdropDismiss: true,
          cssClass: 'question-dialog',
          header: this.transloco.translate('Table Transport'),
          message: this.transloco.translate('tableTransportMessage', {
            movedTable: movedTable.name,
            tb: tb.name,
            // selectedTableGroup: this.tableStore.getValue().selectedTableGroup.name,
          }),
          buttons: [
            this.transloco.translate('No'),
            {
              text: this.transloco.translate('Yes'),
              handler: () => { },
              role: 'evet',
            },
          ],
        });

        await infoModal.onDidDismiss().then(async ({ role }) => {
          // console.log(data);
          if (role == 'evet') {
            await this.folioService.moveFolio(tb, movedFolio);
            this.tableStore
              .getValue()
              .tables.forEach((el) => (el.selected = false));
            this.glb.toast('Successfully updated', '', 'bottom', 'success');
            this.folioStore.update({ activeFolio: null });
            this.sideMenuService.selectMenuById('open-folios');
          }
        });
      }
      return;
    }

    // eski seçim
    if (this.tableStore.getValue().draggingTable?.id !== tb.id) {
      tb.selected = true;
      this.ts.selectTable(tb.id);
    }
  }

  async addTable() {
    this.modalService.showNewTableModal()
  }

  ngAfterViewInit() {


    this.tq.select("activeTables").pipe(takeUntil(this.destroy$)).subscribe(o => {
      o//reserved
      // if (o) {
      //   for (const iterator of o) {

      //     setTimeout(() => {
      //       let el = document.getElementById(iterator.id);
      //       if (iterator.coordinates) {
      //         el.style.position = "absolute";
      //         el.style.top = iterator.coordinates.y
      //         el.style.left = iterator.coordinates.x
      //       }
      //       if (iterator.coordinates) {
      //         el.style.transform = `rotate(${iterator.transform.rotate}deg) scale(${iterator.transform.scale})`
      //       }

      //     }, 10);


      //   }
      // }



    })






    /* this.oe.tableGroupEvents.pipe(takeUntil(this.destroy$)).subscribe(o => {
      if (o == "select") {
        this.glb.tableItems = this.storeService.tables.filter(o => o.groupId == this.oe.activeTableGroup.id)
      }
    }) */

    // setTimeout(() => {
    //   let tc = document.getElementById('table-cover');
    //   let tg = document.getElementById('table-group-content');
    //   let hd = document.getElementById('pos-header');
    //   let ht =
    //     tg.getBoundingClientRect().height +
    //     hd.getBoundingClientRect().height +
    //     64;

    //   tc.style.height = `Calc( 100vh - ${ht}px )`
    //   tc.innerHTML = tg.getBoundingClientRect().height + "  " + hd.getBoundingClientRect().height + " " + document.body.getBoundingClientRect().height + " " + tc.style.height
    // }, 100);

    /*    this.tq.selectedTableGroup.pipe(takeUntil(this.destroy$)).subscribe((o) => {
         let tc = document.getElementById("table-cover")
         tc.style.maxHeight = "100%"
         setTimeout(() => {

           let vh = this.visibleArea(tc)
           let hv = this.getVisibleDimensions(tc, undefined)
           console.log(vh)

           setTimeout(() => {

             tc.style.maxHeight = vh.height + "px"
           }, 100);

         }, 100);
       }); */
  }

  ngOnInit() {
    this.tq.selectedTableGroup.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      if (o) {
        this.newTable = {
          storeId: this.orwiStoreStore.getValue().id,
          id: 'new-table',
          name: '',
          defaultPax: 0,
          folios: [],
          groupId: o.id,
          state: 'close',
          info: null,
          selected: false,
          lock: null,
        };
      }
    });
  }

  visibleArea(node) {
    var o = { height: node.offsetHeight, width: node.offsetWidth }, // size
      d = {
        y: node.offsetTop || 0,
        x: node.offsetLeft || 0,
        node: node.offsetParent,
      }, // position
      css,
      y,
      x;
    while (null !== (node = node.parentNode)) {
      // loop up through DOM
      css = window.getComputedStyle(node);
      if (css && css.overflow === 'hidden') {
        // if has style && overflow
        y = node.offsetHeight - d.y; // calculate visible y
        x = node.offsetWidth - d.x; // and x
        if (node !== d.node) {
          y = y + (node.offsetTop || 0); // using || 0 in case it doesn't have an offsetParent
          x = x + (node.offsetLeft || 0);
        }
        if (y < o.height) {
          if (y < 0) o.height = 0;
          else o.height = y;
        }
        if (x < o.width) {
          if (x < 0) o.width = 0;
          else o.width = x;
        }
        return o; // return (modify if you want to loop up again)
      }
      if (node === d.node) {
        // update offsets
        d.y = d.y + (node.offsetTop || 0);
        d.x = d.x + (node.offsetLeft || 0);
        d.node = node.offsetParent;
      }
    }
    return o; // return if no hidden
  }

  getVisibleDimensions(node, referenceNode) {
    referenceNode = referenceNode || node.parentNode;

    var pos = node.getBoundingClientRect();
    var referencePos = referenceNode.getBoundingClientRect();

    return {
      width: Math.min(
        node.clientWidth,
        referencePos.left + referenceNode.clientWidth - pos.left,
        node.clientWidth - (referencePos.left - pos.left)
      ),
      height: Math.min(
        node.clientHeight,
        referencePos.top + referenceNode.clientHeight - pos.top,
        node.clientHeight - (referencePos.top - pos.top)
      ),
    };
  }
  async onClickInfo(e: ITable, tableName: string) {
    console.log(e);
    const data: Folio = [...e.folios].pop();
    if (data === null || data === undefined) {
      this.glb.toast('No open ticket found.', '', 'bottom', 'danger');
      return;
    }
   this.modalService.showTableInfoModal(e,tableName,data)
  }

  checkChange(item: ITable): boolean {
    // console.log('+item.info?.open ', +item.info?.open, 'mode:', this.mode);

    if (
      this.mode === 'change' &&
      item.state === 'close' && // boş masa
      !this.tableStore.getValue().tables.some((o) => o.selected)
    )
      return true;
    if (
      this.mode === 'change' &&
      item.state === 'open' &&
      this.tableStore.getValue().tables.some((o) => o.selected)
    )
      return true;

    return false;
  }
}
