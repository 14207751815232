export class EcrContract {
  contractStatus: "active" | "not-found" | "ended";
  licanceId: string;
  legalId: string;
  contractId: string;
  eftPosActive: boolean;
  folioClose: boolean;
  model: string;
  type: string;
  brand: string;
  enabled: boolean;
}
