import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { AppUsersQuery } from '../../state/pos-users.query';
import { AppUsersService } from '../../state/pos-users.service';
import { AppUser, Role } from 'src/app/modules/session/state/dto/sso-dto';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.css']
})
export class SelectUserComponent implements OnInit, OnDestroy {

  list$: Observable<AppUser[]>
  public searchField: FormControl;
  roles: Role[] = []
  constructor(
    private modalService: ModalService,
    public sQ: SessionQuery,
    public bQ: AppUsersQuery,
    private bs: AppUsersService
  ) {
    this.searchField = new FormControl('');
  }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {

    this.bs.getUsers()
    this.bs.getRoles()

    this.intiSearch()

    this.bQ.appUsers$.pipe(takeUntil(this.destroy$)).subscribe(o => {
      console.log(o)

      for (const iterator of o) {
        let lic = iterator.licances[0]
        let mainLevel = Math.min.apply(Math, lic.roles.map(function (item) { return item.level }));
        let role = lic.roles.find(rl => rl.level == mainLevel)
        console.log(role.name)
        this.roles.push(role)
      }

      let xpl = [...new Set(this.roles.sort(x => x.level).map(obj => obj.id))];
      console.log(xpl)

    })


  }

  selectBossUser(item: AppUser) {
    this.modalService.modalController.dismiss(item)
  }


  isOwner() {
    let ns = this.sQ.activeLicense?.allPermissions.find(n => n.id == "boss-user-add")
    return (ns !== undefined)
  }

  getRole(item) {
    item//reserved
    return ""
  }

  addButonDisabled() {
    let ns = this.sQ.activeLicense?.allPermissions.find(n => n.id == "boss-user-add")
    return (ns == undefined)
  }


  intiSearch() {
    const searchTerm$ = this.searchField.valueChanges.pipe(
      startWith(this.searchField.value)
    );



    this.list$ = combineLatest([this.bQ.appUsers$, searchTerm$]).pipe(
      map(([clients, searchTerm]) =>
        clients.filter(
          (client) =>
            (searchTerm === '' ||
              client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              client.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (
              client.licances.find(lc => lc.roles.find(rl => rl.name == 'Courier') && lc.licanceId == this.sQ.activeLicense.licanceId)
            )
        )
      )
    )
  }

  cancel() {
    this.modalService.modalController.dismiss("cancel")
  }

  clear() {
    this.modalService.modalController.dismiss("clear")
  }

}
