import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NavController } from '@ionic/angular';
import { FolioService as FolioService } from '../../state/folio.service';
import { FolioQuery } from '../../state/folio.query';
import { FolioStore } from '../../state/folio.store';
import { NumpadQuery } from 'src/app/components/ui/numpad/state/numpad.query';
import { NumpadStore } from 'src/app/components/ui/numpad/state/numpad.store';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { PaymentTypeListComponent } from '../payment-type-list/payment-type-list.component';
import { EcrService } from 'src/app/services/ecr/ecr.service';
// import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { ParametersStore } from 'src/app/modules/parameters/state/parameters.store';
import { TranslocoService } from '@ngneat/transloco';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { arrayUpdate } from '@datorama/akita';
import { TableStore } from 'src/app/modules/tables/state/table.store';
import { Subject, takeUntil } from 'rxjs';
// import * as moment from 'moment';

@Component({
  selector: 'orwi-folio',
  templateUrl: './folio.component.html',
  styleUrls: ['./folio.component.scss'],
})
export class FolioComponent implements OnInit, OnDestroy {
  constructor(
    private fs: FolioService,
    private nav: NavController,
    private fq: FolioQuery,
    private folioStore: FolioStore,
    private glb: GlobalService,
    // private plt: Platform,
    private printerService: PrintCoverService,
    private numpadQuery: NumpadQuery,
    private numpadStore: NumpadStore,
    private modalService: ModalService,
    private ecrService: EcrService,
    // private foodAppService: FoodAppsIntegrationService,
    private ssoService: SSOSessionService,
    private parameterStore: ParametersStore,
    private transloco: TranslocoService,
    private numpadService: NumpadService,
    private tableStore: TableStore
  ) {}
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    this.fq.changedRows$.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      o; //reserved
      this.stringValue = '0';
      this.value = undefined;
    });
  }

  async goBack() {
    const activeFolio: Folio = this.folioStore.getValue().activeFolio;
    if (!activeFolio) {
      return;
    }
    this.folioStore.update({ selectedRow: null, activeFolioRow: null });
    // if (activeFolio.rows.every((el) => el.recordStatus == 'deleted')) {
    //   activeFolio.status = 'cancelled';
    //   this.folioService._posSaveFolio(activeFolio);
    // }
    if (activeFolio.type == 'table') {
      let folioEquivalentTable = this.tableStore
        .getValue()
        .activeTables?.find((table) => table.folios[0]?.id == activeFolio.id);
      if (
        folioEquivalentTable?.folios.length == 1 &&
        activeFolio.grandTotal == 0
      ) {
        folioEquivalentTable.state = 'close';
        await this.tableStore.update(({ activeTables }) => ({
          activeTables: arrayUpdate(
            activeTables,
            folioEquivalentTable.id,
            folioEquivalentTable
          ),
        }));
      }
    }
    this.numpadService.reset('qty');
    await this.fs.loadOpenFolios();
    this.nav.pop();
  }

  // close() {
  //   this.fs._posCloseFolio();
  //   this.nav.navigateRoot('/');
  // }

  async done() {
    let folio: Folio = this.fq.getValue().activeFolio;
    if (folio.sequenceNo != 0) {
      const fetchedFolio = await this.fs._posGetFolioById(folio.id);
        if (fetchedFolio.lock.status === 'locked') {
          if (this.ssoService.checkPermission('user-lock-table')) {
            const userResponse = await this.glb.toastButton(
              'Folio Lock', 
              'This folio has been locked, would you like to unlock it?', 
              'bottom', 
              'warning', 
              true, 
              true
            );
      
            if (userResponse === 'yes') {
              await this.fs.lockUnlockFolio(fetchedFolio.id, 'unlocked');
            } else {
              return
            }
          } else {
            await this.glb.toast(
              'Folio Lock', 
              'This folio has been locked, you cannot proceed to the payment page', 
              'bottom', 
              'warning'
            );
            return;
          }
        }
    }

    if (
      !this.ssoService.checkPermission('folio-use-send-on-selfservice') &&
      folio.type === 'self'
    ) {
      this.glb.permissionToast();
      return;
    }

    if (this.fq.mainRows().length == 0) {
      this.glb.shakeElement('done-button');
    } else {
      if (
        folio.status == 'open' &&
        folio.type == 'delivery' &&
        folio.deliveryPaymentType == ''
      ) {
        const paymentModal = await this.modalService.openModal({
          component: PaymentTypeListComponent,
          initialBreakpoint: 0.8,
          breakpoints: [0, 0.8, 1],
        });

        await paymentModal.present();
        const result = await paymentModal.onDidDismiss();
        if (!result.data) {
          return;
        }
      }

      this.glb.showLoading();

      if (
        folio.rows.filter((x) => x.folioId == '' && x.recordStatus == 'deleted')
          .length > 0
      ) {
        folio.rows
          .filter((x) => x.folioId == '' && x.recordStatus == 'deleted')
          .map((x) => {
            let index = folio.rows.indexOf(x);
            folio.rows.splice(index, 1);
          });
      }

      this.fs._posSaveFolio(folio).then((folioResult) => {
        setTimeout(async () => {
          await this.printerService.printLabel(folioResult);
          await this.printerService.printUnit(folioResult);
          await this.fs._posDoneFolio(folioResult);
          await this.fs.loadOpenFolios();

          console.log('save burada');
          if (folio.type == 'self') {
            if (folio.sequenceNo == 0) {
              this.fs.createSelfFolio().then((folio: any) => {
                console.log('SELF FOLIO', folio);
                setTimeout(async () => {
                  this.fq.getValue().activeFolio = folio;
                  this.glb.navigateFolioForm();
                  this.glb.closeAllLoading();
                }, 1000);
              });
            }
            this.glb.closeAllLoading();
          } else {
            this.glb.closeAllLoading();
          }
        }, 500);
      });
      this.nav.back();
    }
    this.numpadService.reset('qty');
  }

  //status ve type

  async payment() {
    if (!this.ssoService.checkPermission('folio-payment-actions')) {
      this.glb.permissionToast();
      return;
    }

    if (this.fq.total === 0) {
      this.glb.shakeElement('payment-button');
      this.glb.toast(
        'folio',
        this.transloco.translate('You Cannot Close 0 Consistent Tickets'),
        'bottom',
        'warning'
      );
      return;
    }

    if (this.fq.activeFolio.sequenceNo != 0) {
      const fetchedFolio = await this.fs._posGetFolioById(
        this.fq.activeFolio.id
      );
    
      if (fetchedFolio.lock.status === 'locked') {
        if (this.ssoService.checkPermission('user-lock-table')) {
          const userResponse = await this.glb.toastButton(
            'Folio Lock', 
            'This folio has been locked, would you like to unlock it?', 
            'bottom', 
            'warning', 
            true, 
            true
          );
    
          if (userResponse === 'yes') {
            await this.fs.lockUnlockFolio(fetchedFolio.id, 'unlocked');
          } else {
            return
          }
        } else {
          await this.glb.toast(
            'Folio Lock', 
            'This folio has been locked, you cannot proceed to the payment page', 
            'bottom', 
            'warning'
          );
          return;
        }
      }
    }
    

    if (this.fq.mainRows().length == 0) {
      this.glb.shakeElement('payment-button');
    } else {
      // if (this.folioStore.getValue().activeFolio.lock.status == 'locked')
      //   {
      //     let diff = moment(this.folioStore.getValue().activeFolio.lock.time);
      //     let msg = `Masa  ${this.folioStore.getValue().activeFolio.lock.userName} tarafından  ${moment().diff(
      //       diff,
      //       'minute'
      //     )} dakika önce kilitlenmiştir.İşlem Yapamazsınız`;
      //     this.glb.toast('Masa Kilidi',msg,'bottom','warning')
      //     return
      //   }


        if (
          this.parameterStore.getValue().posParameters.ecrParameters
            .ecrModel === 'pavo'
        ) {
          this.connect();
        }


      this.nav.navigateForward('pos-home/pos-payment');
    }
    this.numpadService.reset('qty');
  }

  async connect() {
    //await this.ecrSettingService.getEcrSetting();
    this.ecrService.connectEcr().then((x) => {
      x;
      //TODO:x error ekrana bas
      this.glb.closeLoading();
    });
  }

  cancel() {
    this.glb.navigateHomeForm();
  }

  setQty(value: string) {
    this.select(value);
  }

  stringValue = '0';
  value = 0;
  select(value) {
    if (value == 'del') {
      this.stringValue = this.stringValue.substr(
        0,
        this.stringValue.length - 1
      );
      this.value = parseFloat(this.stringValue) || 1;
    } else if (value == 'enter') {
      if (this.value == 0) {
        this.value = 1;
      }
      //this.glb.numpadValue = this.
      //this.updateStore();
      this.value = 0;
      this.stringValue = '0';
      this.numpadStore.update({ visible: false });
      //this.onAccept.emit(+this.numpadStore.getValue().value);
      return;
    } else {
      if (this.stringValue == '0') {
        this.stringValue = '';
      }
      this.stringValue += value;
      this.value = parseFloat(this.stringValue);
    }

    if (this.stringValue == '') {
      this.stringValue = '0';
    }
    if (this.value == 0) {
      this.value = 1;
    }
    this.updateStore();
  }

  updateStore() {
    const key: any = this.numpadQuery.getValue().for;
    const old: any = { ...this.numpadQuery.getValue().value };
    old[key] = +this.value.toFixed(2);
    this.numpadStore.update({ value: old });
  }

  async printFolio() {
    if (this.fq.mainRows().length == 0) {
      this.glb.shakeElement('print-button');
      this.glb.toast(
        'Orwi Pos',
        'Empty Folio Cannot Be Printed',
        'bottom',
        'warning'
      );
      return;
    }
    const activeFolio: Folio = this.folioStore.getValue().activeFolio;
    // if (this.folioStore.getValue().activeFolio.lock.status == 'locked')
    //   {
    //     let diff = moment(this.folioStore.getValue().activeFolio.lock.time);
    //     let msg = `Masa  ${this.folioStore.getValue().activeFolio.lock.userName} tarafından  ${moment().diff(
    //       diff,
    //       'minute'
    //     )} dakika önce kilitlenmiştir.İşlem Yapamazsınız!`;
    //     this.glb.toast('Masa Kilidi',msg,'bottom','warning')
    //     this.glb.closeAllLoading();
    //     // this.nav.back();
    //     return
    //   }
    if (
      activeFolio.rows.filter(
        (x) => x.folioId == '' && x.recordStatus == 'deleted'
      ).length > 0
    ) {
      activeFolio.rows
        .filter((x) => x.folioId == '' && x.recordStatus == 'deleted')
        .map((x) => {
          let index = activeFolio.rows.indexOf(x);
          activeFolio.rows.splice(index, 1);
        });
    }
    if (
      activeFolio.status == 'open' &&
      activeFolio.type == 'delivery' &&
      activeFolio.deliveryPaymentType == ''
    ) {
      const modalRef = await this.modalService.openModal({
        component: PaymentTypeListComponent,
        initialBreakpoint: 0.8,
        breakpoints: [0, 0.8, 1],
      });

      await modalRef.present()
      const result = await modalRef.onDidDismiss()
      if (!result.data) {
        return;
      }
    }
      if (activeFolio.sequenceNo != 0) {
        const fetchedFolio = await this.fs._posGetFolioById(activeFolio.id);
        if (fetchedFolio.lock.status == 'locked') {
          this.glb.toast(
            'Error',
            'This folio has been locked, you cannot save changes',
            'bottom',
            'warning'
          );
          return;
        }
      }
      this.fs._posSaveFolio(activeFolio).then((folioResult) => {
        setTimeout(async () => {
          await this.printerService.printUnit(folioResult);
          await this.printerService.printFolio(folioResult);
          await this.printerService.printLabel(folioResult);
          await this.fs._posDoneFolio(folioResult);
        }, 500);
      });
      this.glb.closeAllLoading();
      this.nav.back();
      if (activeFolio.type == 'self') {
        if (activeFolio.sequenceNo == 0) {
          this.fs.createSelfFolio().then((folio: any) => {
            console.log('SELF FOLIO', folio);
            setTimeout(() => {
              this.fq.getValue().activeFolio = folio;
              this.glb.navigateFolioForm();
            }, 1000);
          });
        }
      }
      this.numpadService.reset('qty');

    // this.foodAppService.printProcess(activeFolio).then((o) => {
    //   console.log('printProcess', o);
    //   if (o) {
    //     // this.socketService.socket.emit("send-client", JSON.stringify(pc))
    //   } else {
    //     // this.socketService.socket.emit("send-client", JSON.stringify(pc))
    //   }
    // });
    // this.printerService.printFolio();
  }
}
