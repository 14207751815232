import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { arrayUpsert } from '@datorama/akita';
import { IonPopover } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { DeliveryStatus, Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../state/folio.query';
import { FolioStore } from '../../state/folio.store';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { AppUser } from 'src/app/modules/session/state/dto/sso-dto';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { AppUsersQuery } from 'src/app/modules/pos-users/state/pos-users.query';
//  import { AppUsersService } from 'src/app/modules/pos-users/state/pos-users.service';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { FolioService } from '../../state/folio.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';

@Component({
  selector: 'orwi-folio-header',
  templateUrl: './folio-header.component.html',
  styleUrls: ['./folio-header.component.scss'],
})
export class FolioHeaderComponent implements OnInit, OnDestroy {
  @Input() type: 'folio' | 'payment' = 'folio';
  list$: Observable<AppUser[]>
  waiterList: AppUser[] = [];
  folio$ = this.fq.activeFolio$;
  folio: Folio = new Folio();
  waiterMenuToggle: boolean = false;
  @ViewChild('waiterPopover') waiterPopover
  private destroy$ = new Subject<void>();

  constructor(
    private fq: FolioQuery,
    private fs: FolioStore,
    private glb: GlobalService,
    private folioService: FolioService,
    private idGen: IdGeneratorService,
    private foodAppService: FoodAppsIntegrationService,
    private transloco: TranslocoService,
    private OrwiStoreQuery: OrwiStoreQuery,
    private sessionQuery: SessionQuery,
    private appUsersQuery: AppUsersQuery,
    // private appUsersSerivce: AppUsersService,
    private ssoService: SSOSessionService
  ) { }

  ngOnInit() {
    // this.appUsersSerivce.getUsers();
    this.folio$ = this.fq.activeFolio$;
    this.folio$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
      this.folio = x;
    });

    this.appUsersQuery.appUsers$.pipe(takeUntil(this.destroy$)).subscribe(o => {
      this.waiterList = o.filter(user =>

        user.licances.some(license =>
          license.orwiStore?.id === this.sessionQuery.getValue().activeLicense?.orwiStore?.id &&
          license.roles.length > 0 &&
          license.roles[0].name === "Waiter"
        ))
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showWaiters(e: Event) {
    if (!this.ssoService.checkPermission('folio-change-waiter')) {
      this.glb.permissionToast();
      return
    }
    this.waiterPopover.event = e;
    this.waiterPopover.present();
  }

  async changeWaiter(waiter: AppUser) {
    this.fq.activeFolio.creator.userName = waiter.name;
    this.fq.activeFolio.creator.userID = waiter.id;
    this.waiterPopover.dismiss().then(() => {
      this.waiterMenuToggle = false;
    });
    let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.fs
        .getValue()
        .activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'waiter-change',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: '',
      infoType: 'information',
      description: this.transloco.translate('Waiter Change'),
    };
    await this.folioService.saveFolioLog(logObject)
  }

  getPaymentType(type) {
    return this.OrwiStoreQuery.getValue().payTypes.filter(
      (elm) => elm.payType == type
    )[0]?.name;
  }

  async changeDeliveryStatus(
    deliveryStatus: DeliveryStatus,
    popover?: IonPopover
  ) {
    popover; //reserved
    if (this.folio.deliveryStatus == deliveryStatus) return;
    await this.glb.openAlert({
      header: this.transloco.translate('Delivery Status'),
      message: `Siparişin durumunu <b>${this.transloco.translate(
        this.folio.deliveryStatus
      )}</b>'dan <b>${this.transloco.translate(
        deliveryStatus
      )}</b>'a güncellemek ister misiniz?`,
      buttons: [
        this.transloco.translate('No'),
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            const value = deliveryStatus; //deliveryStatus.target['value'];
            // popover.dismiss(value);
            this.foodAppService.updateOrderStatus(this.folio.id, value).then(
              (res) => {
                console.log('Response from FoodApp', res);
                this.folio.deliveryStatus = value;
                this.fs.update(({ openFolios }) => ({
                  openFolios: arrayUpsert(
                    openFolios,
                    this.folio.id,
                    this.folio
                  ),
                }));
                this.glb.toast('', 'Action Successful', 'middle', 'success');
              },
              (err) => {
                this.glb.toast('An error occured', err, 'middle', 'danger');
              }
            );
          },
        },
      ],
    });
  }

  getDeliveryStatus() {
    if (this.folio.deliveryStatus == 'new') {
      return '/assets/order-status/order-new.gif';
    } else if (this.folio.deliveryStatus == 'accepted') {
      return '/assets/order-status/order-accept.gif';
    } else if (this.folio.deliveryStatus == 'prepared') {
      return '/assets/order-status/order-prepering.gif';
    } else if (this.folio.deliveryStatus == 'routing') {
      return '/assets/order-status/order-routing.gif';
    } else if (this.folio.deliveryStatus == 'completed') {
      return '/assets/order-status/order-complated.gif';
    } else if (this.folio.deliveryStatus == 'cancelled') {
      return '/assets/order-status/order-cancel.gif';
    } else {
      return '/assets/order-status/order-cancel.gif';
    }
  }

  toggle() {
    let comp = document.getElementById('toggleBtn');
    if (comp.classList.contains('active')) {
      comp.classList.remove('active');
    } else {
      comp.classList.add('active');
    }

    let comp2 = document.getElementById('toggleList');
    if (comp2.classList.contains('active')) {
      comp2.classList.remove('active');
    } else {
      comp2.classList.add('active');
    }
  }
}
