<ion-header [translucent]="true">
  <ion-toolbar style="margin-top: 20px">
    <ion-title>
      {{ "Payment Types" | transloco }}
    </ion-title>
    <!-- <ion-searchbar
      class="custom"
      [debounce]="1000"
      (ionChange)="handleChange($event)"
      placeholder="{{ 'Search' | transloco }}"
    ></ion-searchbar> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <div
    *ngIf="!isLoaded"
    style="
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    "
  >
    <ion-spinner></ion-spinner>
  </div> -->
  <ion-list>
    <ion-item *ngFor="let pitem of paymentTypes" (click)="select(pitem)">
      <ion-label>{{ pitem.name }}</ion-label>
    </ion-item>
  </ion-list>
  <!-- <ion-infinite-scroll (ionInfinite)="doInfinit($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll> -->
</ion-content>
