import { EventEmitter, OnDestroy } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ChangeCombineModalType } from 'src/app/modules/tables/model/table.model';
import { TableGroup, ITable, TableState } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { TableQuery } from '../../state/table.query';
import { Subject, takeUntil } from 'rxjs';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { TableService } from '../../state/table.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { Folio } from 'src/app/services/dto/orwi-folio';

@Component({
  selector: 'orwi-table-list',
  templateUrl: './orwi-table-list.component.html',
  styleUrls: ['./orwi-table-list.component.scss'],
})
export class OrwiTableListComponent implements OnInit, OnDestroy {
  selectedTableGroup: TableGroup;
  selectedTable: ITable;
  openFolios: ITable[] = [];

  @Input() set ShowType(value: ChangeCombineModalType) {
    this.showType = value == ChangeCombineModalType.Change ? 'close' : 'open';
  }
  @Input() movedTable: ITable;
  @Output() onSelect: EventEmitter<ITable> = new EventEmitter<ITable>();
  showType: TableState = 'close';
  constructor(
    public tq: TableQuery,
    public glb: GlobalService,
    private folioQuery: FolioQuery,
    private tableService: TableService,
    private modalService: ModalService
  ) {}
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    console.log(this.showType);
    this.folioQuery.openFolios$.pipe(takeUntil(this.destroy$)).subscribe((folios) => {
      folios.forEach((folio) => {
        const convertedFolio: ITable = this.tableService.convertFolioToTable(
          folio
        );
        if (
          folio.type !== 'table' &&
          !this.openFolios.some(
            (existingFolio) => existingFolio.id === convertedFolio.id
          )
        ) {
          this.openFolios.push(convertedFolio);
        }
      });
    });
    console.log(this.openFolios);
  }

  tableGroupSelected(tableGroup: TableGroup) {
    this.selectedTableGroup = tableGroup;
    this.tableSelected(null);
  }
  async tableSelected(tb: ITable) {
    console.log('tableSelected', tb);
    if (!tb) {
      this.selectedTable = null;
      this.onSelect.emit(null);
      return;
    }
    let table = Object.assign({}, tb);
    if (tb.folios.length > 1) {
      const selectedTargetFolio = await this.selectFolio(tb, [
        this.movedTable.id,
      ]);

      if (selectedTargetFolio) {
        table.folios = [selectedTargetFolio];
        this.selectedTable = table;
        this.onSelect.emit(table);
      } else {
        this.tableSelected(null);
      }

      return;
    }

    if (tb.folios.length == 1) {
      this.selectedTable = table;
      this.onSelect.emit(table);
      return;
    }

    if (this.showType == 'close') {
      this.selectedTable = table;
      this.onSelect.emit(table);
    }
  }

  isShowTableGroup(e: TableGroup): boolean {
    const tables: ITable[] = this.tq
      .getValue()
      .tables.filter((el) => el.groupId == e.id);

    return !tables.some((el) => el.state == this.showType);
  }
  clear() {
    this.tableSelected(null);
  }

  async selectFolio(
    selectedTable: ITable,
    excludedFolioIds = []
  ): Promise<Folio> {
    let data = await this.modalService.showSelectFolioModal(
      selectedTable,
      excludedFolioIds
    );

    return data;
  }
}
