import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonCheckbox, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Observable, Subject, Subscription, of, takeUntil } from 'rxjs';
import { BrandsQuery } from 'src/app/modules/brands/state/brands.query';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FoodAppsIntegrationQuery } from 'src/app/modules/food-apps-integration/state/food-apps-integration.query';
import { Folio, FolioRow, ServiceType } from 'src/app/services/dto/orwi-folio';
import { CountOfSelectedRowsPipe } from '../count-of-selected-rows.pipe';
import { KitchenDisplayLog, OrderStatus, OrderStatusArray } from '../state/dto';
import { KitchenDisplayQuery } from '../state/kitchen-display.query';
import { KitchenDisplayService } from '../state/kitchen-display.service';
import { OrwiArrayFilter } from 'src/app/helpers/pipes/orwi-array-filter';
import { Device } from '@capacitor/device';
import { GlobalService } from 'src/app/services/global.service';
import { FormBuilder } from '@angular/forms';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'kitchen-display',
  templateUrl: './kitchen-display.page.html',
  styleUrls: ['./kitchen-display.page.scss'],
})
export class KitchenDisplayPage implements OnInit, OnDestroy {
  Math: any = Math
  reverseOrder = false;
  async onBrandChange(event: any) {
    this.selectedBrandId = event;
    await this.onFilterStatusChange(
      { target: { value: OrderStatus.waiting } },
      this.filteredList
    );
    await this.productFilter();
    this.statusCountChecker();
  }
  async onAppChange(event: any) {
    this.selectedAppId = event;
    await this.onFilterStatusChange(
      { target: { value: OrderStatus.waiting } },
      this.filteredList
    );
    await this.productFilter();
    this.statusCountChecker();
  }
  date: Date = new Date();
  progress: boolean = false;
  kitchenDisplayLogs: KitchenDisplayLog[] = [];
  list$: Observable<Folio[]> = of([]);
  openFoliosSubscriber: Subscription;
  _list: Folio[] = [];
  brands$ = this.brandsQuery.brand$;
  apps = ['yemek-sepeti', 'getir', 'trendyol', 'fuudy', 'orwi', 'migros-yemek','kiosk'];
  statusCounter = {
    null: 0,
    waiting: 0,
    prepering: 0,
    prepered: 0,
    cancelled: 0,
  };
  folioSubscription;
  folioSettings = this.fb.group({
    orderNumber: true,
    waiter: true,
    table: true,
    warningColor: 'light',
    warningTime: 15
  });

  selectedBrandId: string | null = null;
  selectedAppId: string | null = null;
  OrderStatusArray = OrderStatusArray;
  selectedIds = [];
  deviceID = '';
  filteredList = [];
  isLoading: boolean = true;

  constructor(
    private kitchenDisplayService: KitchenDisplayService,
    private fb: FormBuilder,
    private folioQuery: FolioQuery,
    private glb: GlobalService,
    public kitchenDisplayQuery: KitchenDisplayQuery,
    private nav: NavController,
    public foodAppQuery: FoodAppsIntegrationQuery,
    private countOfSelectionPipe: CountOfSelectedRowsPipe,
    private brandsQuery: BrandsQuery,
    private ssoService: SSOSessionService
  ) {
   
  }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
   }

   ionViewWillEnter() {
    this.list$ = this.kitchenDisplayQuery.folioListData$.pipe();
    this.folioSubscription = this.list$.pipe(takeUntil(this.destroy$)).subscribe(async (o) => {
        await this.updateList(o); // Update _list
        await this.getKitchenLogs(o);
        await this.kitchenDisplayService.fetchData(this.date);
        await this.productFilter();
        setTimeout(async () => {
            await this.onFilterStatusChange(
                { target: { value: this.activeStatus || OrderStatus.waiting } },
                this.filteredList
            );
        }, 500);
    });
}

async updateList(o: Folio[]) {
    // Update _list without erasing previous members
    o.forEach(item => {
        const index = this._list.findIndex(existingItem => existingItem.id === item.id);
        if (index === -1) {
            this._list.push(item); // If not found, add it
        }
        //  else {
        //     this._list[index] = item; // If found, update it
        // }
    });
}

  ionViewDidEnter() {
    
    let settings = localStorage.getItem('kitchenDisplaySettings')
    if(settings !== null){this.folioSettings.patchValue(JSON.parse(settings))} else {
      this.folioSettings.patchValue({
        orderNumber: true,
        waiter: true,
        table: true,
        warningColor: '#f4f5f8',
        warningTime: 15,
      })
    }
    this.openFoliosSubscriber = this.folioQuery.openFolios$.subscribe(
      async (o) => {
        if (o) {
          // console.log('subscriber worked')
          this.getSettings();
          // await this.kitchenDisplayService.fetchData(this.date);
          // await this.onFilterStatusChange(
          //   { target: { value: this.activeStatus || OrderStatus.waiting } },
          //   this.filteredList
          // );
          // await this.productFilter();
          this.statusCountChecker();
          this.onBrandChange(null)
        }
      }
    );
    setTimeout(() => {
      this.glb.closeAllLoading();
      this.refresh()
    }, 500);
  }

  ionViewWillLeave() {
    this.openFoliosSubscriber.unsubscribe();
    this.folioSubscription.unsubscribe();
  }

  async getKitchenLogs(o?: any[]) {
    let ps;
    if (o) {
      ps = [...o.map((n) => n.id)];
    } else {
      ps = [...this._list.map((n) => n.id)];
    }
    this.kitchenDisplayLogs = await this.kitchenDisplayService.getKitchenDisplayLogs(
      ps,
      undefined
    );
  }

  async updateStatusHandler() {
    this.isLoading = true;
    this.glb.showLoading();
    console.log('showLoading updateStatusHandler')
    await this.onFilterStatusChange(this.activeStatus, this._list);
    await this.productFilter();
    await this.statusCountChecker();
    setTimeout(() => this.glb.closeAllLoading(), 1000);
    this.isLoading = false;
  }

  calcOrderTime(item: Folio) {
    return moment(new Date()).diff(item.creation, 'minute');
  }

  getOrderTypeImage(orderType: ServiceType) {
    switch (orderType) {
      case 'delivery':
        return '/assets/images/delivery.png';

      case 'self':
        return '/assets/images/coffee-bag.png';
        break;

      case 'table':
        return '/assets/images/table.png';
        break;

      default:
        return '/assets/images/table.png';
        break;
    }
  }

  checkItem(event: IonCheckbox, item: FolioRow, folio: Folio) {
    folio //reserved
    //TODO:folio kullanilmiyorsa kaldir
    //  console.log(event.checked);
    item.customChecked = event.checked;
  }

  getItemNextStatus(itemStatus: OrderStatus) {
    if (
      itemStatus == OrderStatus.cancelled ||
      itemStatus == OrderStatus.prepered
    )
      return itemStatus;
    return OrderStatusArray[
      OrderStatusArray.findIndex((el) => el == itemStatus) + 1
    ];
  }

  async getCountByStatus(status) {
    let folios = this.kitchenDisplayQuery.getValue().folioListData;
    await this.getKitchenLogs(folios);
    //console.log("folios filter", folios);
    let foliosByStatus = [];
    folios.forEach((folio) => {
      let rows = this.kitchenDisplayQuery.mainRows(folio.id);

      rows.forEach((row) => {
        let kitchenRow = this.kitchenDisplayLogs.find(
          (x) => x.folioId == folio.id && x.itemId == row.itemID
        );
        if (kitchenRow) {
          if (kitchenRow.status == status) {
            foliosByStatus.push(folio.id);
          }
        } else {
          if (status == OrderStatus.waiting) {
            foliosByStatus.push(folio.id);
          }
        }
      });
    });
    foliosByStatus = [...new Set(foliosByStatus)];
    if (foliosByStatus.length == 0 && status == OrderStatus.cancelled) {
      foliosByStatus.push('0');
    }
    return foliosByStatus.length;
  }
  settings() {
    if (!this.ssoService.checkPermission('kitchen-settings')) {
      this.glb.permissionToast();
      return;
    }
    this.kitchenDisplayService.terminateFetchData();
    this.nav.navigateForward('kitchen-display-settings');
  }

  async startOrder(folio: Folio) {
    let rows: FolioRow[] = this.countOfSelectionPipe.transform(folio);
    for await (const row of rows) {
      let rowStatus = this.getProductStatus(folio.id, row.itemID);
      let kitchenRow = this.kitchenDisplayLogs.find(
        (x) => x.folioId == folio.id && x.itemId == row.itemID
      );
      this.kitchenDisplayService.upsertKitchenDisplayLog({
        itemId: row.itemID,
        folioId: folio.id,
        id: kitchenRow?.id ?? '',
        status: this.getItemNextStatus(rowStatus),
      } as KitchenDisplayLog);
    }
  }

  getProductStatus(folioId: string, itemId: string) {
    let kdlog = this.kitchenDisplayLogs.find(
      (x) => x.folioId == folioId && x.itemId == itemId
    );
    if (!kdlog) return OrderStatus.waiting;
    else return kdlog.status;
  }

  refresh() {
    this.progress = true;
    this.getSettings();
    this.kitchenDisplayService.fetchData(this.date);
    setTimeout(() => {
      this.progress = false;
    }, 3000);
  }
  async handleRefresh(event) {
    this.getSettings();
    this.progress = true;
    this.kitchenDisplayService.fetchData(this.date);
    setTimeout(() => {
      this.progress = false;
      event.target.complete();
    }, 500);
  }
  activePage = 1
  pageIndex = 1

  getNumberArray(length: number): number[] {
    return new Array(length);
  }

  pageButtonHandler(event: any){
    this.pageIndex = event.detail.value
    this.getLimitedItems(event.detail.value * 6)
  }

  getLimitedItems(limit: number) {
    return this.filteredList.slice(limit - 6, limit);
  }

  getGrandTotal(objectArray: { grandTotal: number }[]) {
    return objectArray.reduce((total, obj) => total + obj.grandTotal, 0).toFixed(2);
  }

  statusFilteredFolios(folios, status){
    // console.log('selectedStatus', status)
    // folios.map((x) => {
    //   console.log('status' , x.status, 'deliveryStatus', x.deliverStatus)
    // })
    return folios.filter((x) => x.status === status)
  }

  activeStatus: string | null = OrderStatus.waiting;
  async statusButtonHandler(event: any) {
    this.filteredList = [];
    this.glb.showLoading();
    console.log('showLoading statusButtonHandler')
    await this.onFilterStatusChange(event);
    await this.productFilter();
    this.statusCountChecker();
    setTimeout(() => this.glb.closeAllLoading(), 1000);
  }
  async onFilterStatusChange(event: any, folioList?) {
    this.isLoading = true;
    let folios;
    let status;
    if (typeof event == 'string') {
      status = event;
      folios = folioList;
    } else {
      status = event.target.value;
      this.activeStatus = event.target.value;
      folios = this.kitchenDisplayQuery.getValue().folioListData;
    }
    await this.getKitchenLogs(folios);
    //console.log("folios filter", folios);
    this.selectedIds = [];
    let foliosByStatus = [];
    folios.forEach((folio) => {
      let rows = this.kitchenDisplayQuery.mainRows(folio.id);

      rows.forEach((row) => {
        let kitchenRow = this.kitchenDisplayLogs.find(
          (x) => x.folioId == folio.id && x.itemId == row.itemID
        );
        if (kitchenRow) {
          if (kitchenRow.status == status) {
            foliosByStatus.push(folio.id);
          }
        } else {
          if (status == OrderStatus.waiting) {
            foliosByStatus.push(folio.id);
          }
        }
      });
    });
    foliosByStatus = [...new Set(foliosByStatus)];
    typeof event == 'string'
      ? (this.statusCounter[status] = foliosByStatus.length)
      : null;
    if (foliosByStatus.length == 0 && status == OrderStatus.cancelled) {
      foliosByStatus.push('0');
    } else if (foliosByStatus.length == 0 && status == OrderStatus.prepering) {
      foliosByStatus.push('0');
    } else if (foliosByStatus.length == 0 && status == OrderStatus.prepered) {
      foliosByStatus.push('0');
    } else if (foliosByStatus.length == 0 && status == OrderStatus.waiting) {
      foliosByStatus.push('0');
    }
    this.selectedIds = [...foliosByStatus];
    this.getSettings();
    this.isLoading = false;
    this.glb.closeLoading();
  }

  getSettings() {
    Device.getId().then(async (x) => {
      this.kitchenDisplayService.getSettings(x.identifier).subscribe((x) => {
        if (x.length > 0) {
          this.kitchenDisplayService.kitchenDisplaySettings = x[0];
          //  this.productFilter()
        }
      });
    });
  }

  async productFilter() {
    // other filtering logic...
    let filteredByIds;
    if (this.selectedIds[0] == '0') {
        filteredByIds = [];
    } else {
        filteredByIds = new OrwiArrayFilter().transform(
            this._list,
            'id',
            this.selectedIds
        );
    }
    
    // Update filteredList
    this.checkingDublicateFolios(filteredByIds);

    if (this.reverseOrder) {
        this.filteredList.reverse();
    }

    setTimeout(() => {
        this.glb.closeAllLoading();
    }, 500);
}

checkingDublicateFolios(folio: Folio[]) {
    // Iterate over folio array and update filteredList in place
    folio.forEach((e, _idx) => {
        // Check if the item already exists in filteredList
        const existingIndex = this.filteredList.findIndex(item => item.id === e.id);
        if (existingIndex === -1) {
            // If not found, add it to filteredList
            this.filteredList.push(e);
        }
    });
}

  async statusCountChecker() {
    this.isLoading = true;
    let copyOfList = [...this._list];
    let filteredByApp = new OrwiArrayFilter().transform(
      copyOfList,
      'appName',
      this.selectedAppId
    );
    let filteredByBrand = new OrwiArrayFilter().transform(
      copyOfList,
      'brand',
      this.selectedBrandId
    );
    console.log('statusCountChecker', filteredByApp)
    let keys = Object.keys(OrderStatus);
    // keys.forEach(async (key) => {
    //   await this.onFilterStatusChange(key, filteredByApp);
    // });
    keys.forEach(async (key) => {
      await this.onFilterStatusChange(key, filteredByBrand);
    });
    this.selectedIds = [];
    this.isLoading = false;
  }
}
