import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { arrayUpdate } from '@datorama/akita';
import { AlertController } from '@ionic/angular';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { SideMenuStore } from 'src/app/modules/side-menu/state/side-menu.store';
import { GlobalService } from 'src/app/services/global.service';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import * as moment from 'moment'
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'phone-folio-rows',
  templateUrl: './phone-folio-rows.component.html',
  styleUrls: ['./phone-folio-rows.component.scss'],
})
export class PhoneFolioRowsComponent implements OnInit, OnDestroy {

  @Input() folio: Folio;
  @Input() totalRowVisible = false;
  @Input() multiSelect = false;
  @Input() segmentValuePayment: 'all' | 'paidfull' | 'unpaidfull' = 'all';
  @Input() segmentValueFolio: 'all' | 'old' | 'new' = 'all';
  @Input() showPaymentRows = false;
  @Input() component: 'payment' | 'folio' = 'folio';

  folio$ = this.folioQuery.activeFolio$;
  folioRows$ = this.folioQuery.folioRowsWODeleted$;
  total$ = this.folioQuery.total$;

  id = '';

  initOk = false;
  selectedRowIndex: number;
  constructor(
    private folioStore: FolioStore,
    public folioQuery: FolioQuery,
    private sideMenuStore: SideMenuStore,
    private glb :GlobalService,
    private modifiersService: ModifiersService,
    private folioService: FolioService,
    public alertController: AlertController,
    private ssoService: SSOSessionService
  ) { }
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    this.folioQuery.selectedRow$.pipe(takeUntil(this.destroy$)).subscribe((folioRow) => {
      let hasModifiers = false;

      if (folioRow) {

        hasModifiers = this.modifiersService.hasModifiers(folioRow.itemID);
        folioRow.hasModifiers = hasModifiers

        let ids = [];
        let rows = this.sideMenuStore.getValue().folioMenu.filter(
          (menuItem) =>
            menuItem.id == 'delete' ||
            menuItem.id == 'gift' ||
            menuItem.id == 'modifier' ||
            menuItem.id == 'note'
        );

        rows.map((o) => (o.disabled = folioRow == null));

        rows.find((o) => o.id == 'modifier').disabled = !hasModifiers;

        for (const iterator of rows) {
          ids.push(iterator.id);

          this.sideMenuStore.update(({ folioMenu }) => ({
            folioMenu: arrayUpdate(folioMenu, [iterator.id], iterator, 'id'),
          }));
        }

      }

    });

    this.folioQuery.prepareDelete$.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      if (o) {
        //let el = document.getElementById("folio-row-" + o.id)
        //if (el) el.classList.add("animate__slideOutLeft")
      }
    });

    this.folioQuery.newRow$.pipe(takeUntil(this.destroy$)).subscribe((o) => {
      if (o) {
        setTimeout(() => {
          let el = document.getElementById('folio-row-' + o.id);
          if (el) el.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    });
  }



  getRows() {
    return [];
    // if (this.component == "payment") {
    //   return this.getRowsPaymentComponent()
    // } else {
    //   return this.getRowFolioComponent()
    // }
  }

  getRowsPaymentComponent() {
    return [];
    // if (this.segmentValuePayment == "all") {
    //   return this.fs.getRows(this.folio)
    // } else if (this.segmentValuePayment == "paidfull") {
    //   return this.fs.getPaidRows(this.folio)
    // } else {
    //   return this.fs.getUnpaidRows(this.folio)
    // }
  }

  getRowFolioComponent() {
    return [];
    // if (this.segmentValueFolio == "all") {
    //   return this.fs.getRows(this.folio)
    // } else if (this.segmentValueFolio == "old") {
    //   return this.fs.getOldRows(this.folio)
    // } else if (this.segmentValueFolio == "new") {
    //   return this.fs.getNewRows(this.folio)
    // }
  }

  getPaymentRows() {
    // return this.fs.getPaymentRows(this.folio)
    return [];
  }

  select(item: FolioRow, index) {
    this.folioStore.update({ selectedRow: item, activeFolioRow: item });
    this.selectedRowIndex = index;
  }

  toggleSelect(item: FolioRow, index) {
    if (this.folioStore.getValue().selectedRow?.id == item.id) {
      this.folioStore.update({ selectedRow: null, activeFolioRow: null });
      this.selectedRowIndex = -1
    } else {
      this.select(item, index)
    }
  }

  segmentChangedFolio(e) {
    this.segmentValueFolio = e.detail.value;
    this.folioStore.update({ activeSegment: e.detail.value });
  }

  getColor(item: Folio) {
    item//reserved
    // return this.fs.activeFolio.id == item.id ? "secondary" : "success"
  }

  trackBy(item, id) {
    id//reserved
    if (!item) return null;
    return item.id;
  }

  async deletNote(e, item: FolioRow) {
    e.preventDefault();
    e.stopPropagation();

    const { role } = await this.askForDelete();
    if (role === 'cancel') {
      return;
    }
    const activeFolio: Folio = this.folioQuery.getValue().activeFolio;
    // activeFolio.rows = activeFolio.rows.filter((el) => el.id !== item.id);
    activeFolio.rows.map(fr => {
     if (fr.id == item.id)
     {
      fr.recordStatus = 'deleted'
      fr.deleters.push({
        qty : 1,
        reason : 'note-delete',
        time : new Date(),
        userID : this.ssoService.userInfo.id,
        reasonID : 'note-delete',
        userName : this.ssoService.userInfo.name
      })
     }
    })
    await this.folioService._posSaveFolio(activeFolio,"low");
  }

  async askForDelete() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Deleteing',
      message: 'Do you want delete this note?',
      buttons: [
        {
          text: 'Yes',
          role: 'yes',
          cssClass: 'secondary',
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });

    await alert.present();
    return await alert.onDidDismiss();
  }

  async delete(item: FolioRow, index) {
    console.log('item', item)
    let currentDate = moment(new Date)
      if (
        !this.ssoService.checkPermission('folio-delete-product') ||
        (
            !this.ssoService.checkPermission('folio-delete-product-10m') &&
            currentDate.diff(moment(item.creation), 'minutes') > 10
        ) ||
        (
            !this.ssoService.checkPermission('folio-delete-old-product') &&
            item.recordStatus === 'old'
        )
    ) {
        this.glb.permissionToast();
        return;
    }
    this.select(item, index)
    setTimeout(() => {
      this.folioService.askDeleteSelectedRows()
    }, 100);

}

  applyNote(item, i) {
    this.select(item, i)
    setTimeout(() => {
      this.folioService.addNote()
    }, 100);

  }

  applyModifiers(item, i) {
    this.select(item, i)
    setTimeout(() => {
      if (!this.folioStore.getValue().selectedRow) {
        this.glb.toast('', 'Please Select the Product', 'bottom', 'warning')
        return
      }
      if (this.folioStore.getValue().selectedRow.recordStatus == 'new') {
        this.modifiersService.modifiers(this.modifiersService.hasModifiers(this.folioStore.getValue().selectedRow.itemID));
      } else {
        this.glb.toast('', 'You Cannot Enter Variations in the Sent Product.', 'bottom', 'warning')
      }
    }, 100);

  }

  setQty(type: 'decrease' | 'increase' = 'decrease', row) {
    if(type == 'decrease' && row.qty == 1) return;
    if(type == 'decrease') row.qty -= 1;
    if(type == 'increase') row.qty += 1;
    row.price = row.qty * row.unitPrice;
    let subModifiers = this.folioStore.getValue().activeFolio.rows.filter(rw => rw.parentID == row.id);
    subModifiers.forEach(rw => {
      rw.qty = row.qty;
      rw.price = rw.unitPrice * rw.qty;
    })
  }

  showModifiers() { 


  }

  applyGift(item, i) {
    if (
      !this.ssoService.checkPermission('folio-gift-product') ||
      (
          !this.ssoService.checkPermission('folio-gift-old-product') &&
          this.folioStore.getValue().selectedRow.recordStatus === 'old'
      )
  ) {
      this.glb.permissionToast();
      return;
  }
    this.select(item, i)
    setTimeout(() => {
       this.folioService.askGiftSelectedRows();
    }, 100);

  }



}
