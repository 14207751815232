import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { SideMenuStore } from 'src/app/modules/side-menu/state/side-menu.store';
import { FolioQuery } from '../../state/folio.query';
import { FolioService } from '../../state/folio.service';
import { OpenFoliosQuery } from '../../state/open-folios/open-folios.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'orwi-open-folios',
  templateUrl: './open-folios.component.html',
  styleUrls: ['./open-folios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenFoliosComponent implements OnInit, OnDestroy {
  openFolios: Folio[] = [];
  selectedPosMenuId: string = '';
  sortDir: 'asc' | 'desc' = 'asc';
  private destroy$ = new Subject<void>();

  constructor(
    private ms: SideMenuStore,
    public ofq: OpenFoliosQuery,
    public tq: FolioQuery,
    public mq: SideMenuQuery,
    private fs: FolioService,
    private glb: GlobalService,
    private modalService: ModalService,
    private ssoService: SSOSessionService
  ) {}

  ngOnInit() {
    // Menü seçimini dinle
    this.mq.posMenuSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((menu) => {
        this.selectedPosMenuId = menu.id;
      });

    // Folio listesini dinle ve sıralama uygula
    this.tq.openFolios$
      .pipe(
        map((folios) => this.arraySorter(folios, 'creation', this.sortDir)),
        takeUntil(this.destroy$)
      )
      .subscribe((sortedFolios) => {
        this.openFolios = sortedFolios;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Yeni işlem oluştur
  newTransaction() {
    if (!this.ssoService.checkPermission('folio-open')) {
      this.glb.permissionToast();
      return;
    }
    const type = this.ms.getValue().servicetType;
    this.fs.createFolio(type, type, type);
    this.glb.navigateFolioForm();
  }

  // Folio bilgilerini göster
  async onClickInfo(e: any, tableName: string, folio: Folio) {
    this.modalService.showTableInfoModal(e, tableName, folio);
  }

  // Sıralama işlemi
  onSort() {
    this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
    this.openFolios = this.arraySorter(this.openFolios, 'creation', this.sortDir);
  }

  // Sıralama fonksiyonu
  arraySorter(array: Folio[], key: keyof Folio, order: 'asc' | 'desc'): Folio[] {
    return array.sort((a, b) =>
      order === 'asc' ? (a[key] > b[key] ? 1 : -1) : (a[key] < b[key] ? 1 : -1)
    );
  }

  // Track by ID
  trackByFolioId(index: number, item: Folio): string {
    index
    return item.id;
  }
}
