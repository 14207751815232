<ion-content>
  <div>
    <h4 class="title">{{'Table Groups' | transloco}}</h4>
    <div style="width: 100%">
      <div class="table-group-cover-modal">
        <orwi-table-group-button *ngFor="let item of tq.tableGroups$ | async" [ngClass]="{ hide: isShowTableGroup(item) }"
          class="button c-table-button-group"
          id="{{ item.id }}"
          (itemSelected)="tableGroupSelected($event)"
          [tableGroup]="item">
        </orwi-table-group-button>
      </div>
      <div id="table-cover" class="table-cover" style="flex-grow: 2">
        <table-button
          [ngClass]="{
            hide: item.state !== showType || movedTable?.id == item.id,
            selected: selectedTable?.id == item.id}"
          class="c-table-button"
          (itemSelected)="tableSelected($event)"
          *ngFor="
            let item of tq.tables$
              | async
              | orwiArrayFilterLive
                : 'groupId'
                : (selectedTableGroup?.id ? selectedTableGroup?.id : '@~')
          "
          [table]="item"
          [active]="selectedTable?.id === item.id"
        >
        </table-button>
      </div>
      @if (showType === 'open') {
        <div>
          <h4>{{'Open Folios' | transloco}}</h4>
          <div style="display: flex; flex-wrap: wrap;">
            <table-button
            [ngClass]="{
              selected: selectedTable?.id == item.id}"
            class="c-table-button"
            id="{{ item.id }}"
            OrwiDraggable
            (itemSelected)="tableSelected($event)"
            *ngFor="let item of openFolios"
            [table]="item"
          >
          </table-button>
          </div>
        </div>
      }
    </div>
  </div>
</ion-content>
