import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../state/folio.query';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'delete-product-folio',
  templateUrl: './delete-product-folio.component.html',
  styleUrls: ['./delete-product-folio.component.scss'],
})
export class DeleteProductFolioComponent implements OnInit, OnDestroy {
  constructor(
    private glb: GlobalService,
    public fQ: FolioQuery,
    private fb: UntypedFormBuilder,
    public sQ: OrwiStoreQuery,
  ) {
    

    this.reasonText.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.form.patchValue({ reasonId: 'other', reasonName: data });
    });
  
  }

  reasons: any[] = [];
  reasonText = new UntypedFormControl('', [Validators.required, Validators.minLength(5)]);
  private destroy$ = new Subject<void>();
  form = this.fb.group({
    reasonId: [null, Validators.required],
    reasonName: [null, [Validators.required, Validators.minLength(5)]],
  });

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.glb.closeModal();
  }

  change(e) {
    if(e?.detail?.value == 'other') {
      this.form.get('reasonName').setValue(this.reasonText.value);
    }
  }

  selectReason(reason: any) {
    let patchData = {
      reasonId: reason.id,
      reasonName: reason.name,
    };
    console.log(patchData);
    this.form.patchValue(patchData);
  }

  save() {
    this.form.markAllAsTouched();
    console.log(this.form.value, this.form.hasError("isRequired"));

    if (this.form.valid) {
    
     

        this.glb.closeModal({
          reason: this.form.value
        });

    
     
    } else {
      this.glb.shakeElement("delete-resasons")
    }
      
  }

  ngOnInit() {
    console.log(this.form.value)
  }
}
