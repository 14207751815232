import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FoodAppsIntegrationRoutingModule } from './food-apps-integration-routing.module';
import { FoodAppsHome } from './components/food-apps-home/food-apps-home.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YsFormComponent } from './components/forms/ys-form/ys-form.component';
import { ProductSyncComponent } from './components/forms/product-sync/product-sync.component';
import { ProductSelectComponent } from './components/forms/product-select/product-select.component';
import { ModiferSyncComponent } from './components/forms/modifer-sync/modifer-sync.component';
import { ModiferSelectComponent } from './components/forms/modifer-select/modifer-select.component';
import { FoodAppsStoreListComponent } from './components/food-apps-store-list/food-apps-store-list.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FoodAppsCancelReasonComponent } from './components/food-apps-cancel-reason/food-apps-cancel-reason.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BulkProductSyncComponent } from './components/bulk-product-sync/bulk-product-sync.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DxDateBoxModule, DxPieChartModule } from 'devextreme-angular';
import { BulkModifierSyncComponent } from './components/bulk-modifier-sync/bulk-modifier-sync.component';
import { FoodAppsLogsComponent } from './components/food-apps-logs/food-apps-logs.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PaymentSyncComponent } from './components/forms/payment-sync/payment-sync.component';
import { PaymentSelectComponent } from './components/forms/payment-select/payment-select.component';
import { SyncAccountMenuErrorComponent } from './components/sync-account-menu-error/sync-account-menu-error.component';


@NgModule({
  declarations: [
    FoodAppsHome,
    YsFormComponent,
    ProductSyncComponent,
    ProductSelectComponent,
    ModiferSyncComponent,
    ModiferSelectComponent,
    FoodAppsStoreListComponent,
    FoodAppsCancelReasonComponent,
    BulkProductSyncComponent,
    BulkModifierSyncComponent,
    FoodAppsLogsComponent,
    NotificationsComponent,
    PaymentSyncComponent,
    PaymentSelectComponent,
    SyncAccountMenuErrorComponent,
  ],
  imports: [
    IonicModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    CommonModule,
    ScrollingModule,
    FoodAppsIntegrationRoutingModule,
    DxPieChartModule,
    DxDateBoxModule,
    SharedModule,
  ]
})
export class FoodAppsIntegrationModule { }
