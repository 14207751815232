import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppsSyncService } from '../../../service/food-apps-sync.service';
import { FoodAppsIntegrationService } from '../../../state/food-apps-integration.service';
import { FoodAppsIntegrationStore } from '../../../state/food-apps-integration.store';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { FoodAppPaymentType } from '../../../state/dto';
import { PaymentSelectComponent } from '../payment-select/payment-select.component';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { arrayRemove } from '@datorama/akita';

@Component({
  selector: 'payment-sync',
  templateUrl: './payment-sync.component.html',
  styleUrls: ['./payment-sync.component.scss'],
})
export class PaymentSyncComponent implements OnInit {
  progress: boolean = false;
  constructor(
    private modalController: ModalController,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private foodAppsSyncService: FoodAppsSyncService,
    private integrationService: FoodAppsIntegrationService,
    private integrationStore: FoodAppsIntegrationStore,
    public integrationQuery: FoodAppsIntegrationQuery,
    private sessionQuery: SessionQuery,
    private orwiStoreQuery: OrwiStoreQuery
  ) {
    this.sessionQuery;
    this.modalController;
    this.glb;
    this.transloco;
    this.foodAppsSyncService;
    this.integrationService;
    this.integrationStore;
  }

  ngOnInit() { }

  async save() {
    const paymentSave = this.integrationQuery.foodAppPayments.filter(
      (o) => o.isChanged == true
    );

    for (let index = 0; index < paymentSave.length; index++) {
      const element = paymentSave[index];
      delete element.isChanged
      await this.integrationService.saveFoodAppPaymentTypes(element)
    }

  }

  async syncSelect(item) {
    this.integrationStore.update({ activePaymentType: item });
    this.integrationQuery.activePaymentType;
    const infoModal = await this.modalController.create({
      component: PaymentSelectComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      o; //reserved
    });
  }

  getName(internalId) {
    return this.orwiStoreQuery
      .getValue()
      .payTypes.find((x) => x.id === internalId)?.name;
  }

  synsPaymentType() {
    this.glb.showLoading()
    this.integrationService.syncFoodAppPayments(this.sessionQuery.activeLicense.orwiStore.id).then(x => {
      if (x) {
        this.integrationService.getFoodAppPayments(this.integrationStore.getValue().activeAccount.id)
      }
    })
  }

  deleteMatch(slidingItem: any, paymentType: FoodAppPaymentType) {
    slidingItem.close();
    this.integrationService.deleteFoodAppPayments(this.integrationStore.getValue().activeAccount.id, paymentType.id).then(x => {
      if (x) {
        this.integrationStore.update(({ foodAppPaymentType }) => ({
          foodAppPaymentType: arrayRemove(foodAppPaymentType, paymentType.id),
        }));
      }

    })

  }

  deleteRow(slidingItem: any, payment: FoodAppPaymentType) {
    slidingItem.close();
    this.glb.openAlert({
      header: this.transloco.translate('Delete'),
      subHeader:
        payment.name + ' isimli ödeme tipi eşleşme listesinden silinecektir?',
      backdropDismiss: false,
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            // this.integrationService.deleteModifier(modifier).then((x) => {
            //   x
            //   this.integrationStore.update(({ foodAppModifiers }) => ({
            //     foodAppModifiers: arrayRemove(foodAppModifiers, modifier.id),
            //   }));
            //   this.glb.toast(
            //     'Successfully deleted',
            //     '',
            //     'bottom',
            //     'success'
            //   );
            // });
          },
        },
        {
          text: this.transloco.translate('No'),
        },
      ],
    });
  }
}
