<ion-header>
  <ion-toolbar>
    <ion-title>
      {{'Process Result' | transloco}}
    </ion-title>
    <ion-icon slot="end" name="close-outline" (click)="modalController.dismiss()"></ion-icon>
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-list>
  <ion-item lines="full" *ngFor="let store of erroredAccount">
    <div slot="start">
      {{store.name}}
    </div>
    <div slot="end">
      {{store.error}}
    </div>
  </ion-item>
</ion-list>
</ion-content>


<!--

<ion-content>
  <ion-grid id="delete-resasons" fixed style="overflow-y: auto">
    <ion-row>
      <ion-col size="12">
        <ion-list [formGroup]="form" lines="none">
          <ion-radio-group
            (ionChange)="change($event)"
            formControlName="reasonId"
          >
            <ion-item
              class="reason-item"
              *ngFor="let item of sQ.activeGiftReasons$ | async"
              (click)="selectReason(item)"
              [ngClass]="{ selected: item.id == form.get('reasonId').value }"
            >
              <ion-radio [value]="item.id"></ion-radio>
              <ion-label>{{ item.name | titlecase }}</ion-label>
            </ion-item>
            <ion-item
              lines="none"
              [ngClass]="{ selected: 'other' == form.get('reasonId').value }"
            >
              <ion-radio [value]="'other'"></ion-radio>
              <ion-label> {{ "other" | transloco }} </ion-label>
              <ion-input
                class="custom-reason"
                *ngIf="form.get('reasonId').value == 'other'"
                [formControl]="reasonText"
                [placeholder]="'please define' | transloco"
              >
              </ion-input>
            </ion-item>
          </ion-radio-group>

          <ion-item
            *ngIf="form.touched && form.get('reasonId').hasError('required')"
          >
            <ion-text color="danger" style="padding: 10px 25px">
              <small>{{ "reason required" | transloco }}.</small>
            </ion-text>
          </ion-item>

          <ion-item
            *ngIf="
              form.get('reasonId').value == 'other' &&
              form.touched &&
              form.get('reasonName').hasError('required')
            "
          >
            <ion-text color="danger" style="padding: 10px 25px">
              <small>{{ "other reason required" | transloco }}.</small>
            </ion-text>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content> -->
