import { Pipe, PipeTransform } from '@angular/core';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { FolioRow } from 'src/app/services/dto/orwi-folio';

@Pipe({
  name: 'folioRowModifierTotal',
  pure: false
})
export class FolioRowModifierTotal implements PipeTransform {
  /**
   *
   */
  constructor(
    private folioStore: FolioStore
  ) { }

  transform(item: FolioRow): number {
    if (item.isGift) return 0;
    let total = 0;

    let modifiers = this.folioStore
      .getValue()
      .activeFolio.rows.filter((element) => element.parentID == item.id);

    let modifierTotal = modifiers.reduce(
      (acc, curr) => acc + curr.unitPrice * curr.qty,
      0
    );
    total = (item.unitPrice * item.qty) + modifierTotal


    return total;
  }
}
