import { Injectable } from '@angular/core';
import { FoodAppsIntegrationStore } from './food-apps-integration.store';
import { arrayUpdate, arrayUpsert } from '@datorama/akita';
import { FoodAppsIntegrationQuery } from './food-apps-integration.query';
import { OrwiStoreQuery } from '../../store/state/store.query';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionQuery } from '../../session/state/session.query';
import {
  FoodAppAccount,
  FoodAppProduct,
  FoodAppModifier,
  FoodAppCategory,
  RestaurantMenu,
  FoodAppStore,
  FoodAppServiceStatus,
  Notification,
  FoodAppPaymentType,
} from './dto';
import { GlobalService } from 'src/app/services/global.service';
import { StringSmiltaryService } from 'src/app/services/helpers/string-smiltary.service';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { DeliveryStatusComponent } from '../../delivery/components/delivery-status/delivery-status.component';
import {
  ActionSheetButton,
  ModalController,
  NavController,
} from '@ionic/angular';
import { DeliveryStatus, Folio } from 'src/app/services/dto/orwi-folio';
import { OrwiStoreStore } from '../../store/state/store.store';
import { MenuStore } from '../../menu/state/menu.store';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { FolioService } from '../../folio/state/folio.service';
import {
  CollectionChangedResponse,
  SocketService,
} from 'src/app/services/helpers/socket.service';
import { TranslocoService } from '@ngneat/transloco';
import { FolioStore } from '../../folio/state/folio.store';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { FoodAppsCancelReasonComponent } from '../components/food-apps-cancel-reason/food-apps-cancel-reason.component';
import { FoodAppsIntegrationOrderService } from './food-apps-integration-order.service';
import { mongo_upsert_params } from 'src/app/services/public-api/dto';
import { AppService } from 'src/app/services/utils/app.service';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { SSOSessionService } from '../../session/state/sso-session.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FoodAppsIntegrationService {
  closedStores: number;
  // foodSeriviceUrl = 'https://127.0.0.1:4400';
  foodSeriviceUrl = 'https://food-apps-service.kerzz.com:4400';
  foodAppAccounts: FoodAppAccount[] = [];
  foodAppProducts: FoodAppProduct[] = [];
  foodAppModifiers: FoodAppModifier[] = [];
  foodAppPaymentType: FoodAppPaymentType[];
  foodAppCategories: FoodAppCategory[] = [];
  restaurantMenu: RestaurantMenu;
  footAppStores: FoodAppStore[] = [];
  private progressSubject = new BehaviorSubject<{
    matchedName: string;
    progress: number;
  }>({ matchedName: '', progress: 0 });
  progress$ = this.progressSubject.asObservable();

  constructor(
    private modalController: ModalController,
    private publicApi: PublicApiService,
    private integrationQuery: FoodAppsIntegrationQuery,
    private integrationStore: FoodAppsIntegrationStore,
    private orwiStoreQuery: OrwiStoreQuery,
    private http: HttpClient,
    private foodAppOrderService: FoodAppsIntegrationOrderService,
    private modalService: ModalService,
    private fs: FolioStore,
    private orwiService: OrwiService,
    private stringSmiltary: StringSmiltaryService,
    private glb: GlobalService,
    private sessionQuery: SessionQuery,
    private orwiStore: OrwiStoreStore,
    private transloco: TranslocoService,
    private menuStore: MenuStore,
    private folioService: FolioService,
    private printCoverService: PrintCoverService,
    private socketService: SocketService,
    private appService: AppService,
    private ssoService: SSOSessionService,
    private toastrService: ToastrService,
    private nav: NavController
  ) {
    this.sessionQuery.activeLicense$.subscribe((o) => {
      if (o) {
        this.initApplications();
        // this.initAps();
        this.initStatuses();
        this.trackStore();
        this.listenNotifications();
      }
    });

    this.appService.stateChanged$.subscribe((o) => {
      if (o) {
        this.refreshFoodAppStoreStatus();
      }
    });
  }

  async showDeliveryStatusModal(folio: Folio) {
    const modal = await this.modalController.create({
      component: DeliveryStatusComponent,
      backdropDismiss: true,
      initialBreakpoint: 0.8,
      breakpoints: [0, 0.8, 1],
      componentProps: { folio: folio },
    });
    await modal.present();
    return modal;
  }

  getFoodAppIntegrations() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/account/getAccounts`,
          { storeId: this.sessionQuery.activeLicense.orwiStore.id },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.foodAppAccounts = o;
          this.integrationStore.update({
            foodAppAccounts: this.foodAppAccounts,
          });
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  async copyMenu(sourceAccountId: string, targetAccountId: string) {
    try {
      const response = await this.http
        .post(
          `${this.foodSeriviceUrl}/api/menu/copyMenuMapping`,
          { sourceAccountId, targetAccountId },
          this.getHeaders()
        )
        .toPromise();
      return response;
    } catch (e: any) {
      // this.glb.closeAllLoading();

      // Check for 500 status code in the error response
      if (e.status === 500) {
        return e
        this.glb.toast('Internal Server Error', e.error.message, 'bottom', 'danger');
        console.error("Internal Server Error:", e);
        // Handle specific 500 error case (e.g., show user-friendly message)
        // Example: this.glb.showError("Something went wrong, please try again later.");
      }

      throw e; // Re-throw the error after handling it
    }
  }


  saveAccount(account) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/account/saveAccount`,
          account,
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.integrationStore.update(({ foodAppAccounts }) => ({
            foodAppAccounts: arrayUpdate(foodAppAccounts, o.id, o, 'id'),
          }));
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  getFoodAppProducts(source: string) {
    return new Promise((resolve, reject) => {
      try {
        this.publicApi
          .getCollection({
            _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
            col: 'food-apps-products',
            _filter: {
              accountId: this.integrationQuery.activeAccount.id,
              source: source,
            },
          })
          .toPromise()
          .then((o: FoodAppProduct[]) => {
            for (const iterator of o) {
              iterator.changed = false;
              iterator.auto = false;
            }

            this.foodAppProducts = o;

            this.integrationStore.update({
              foodAppProducts: this.foodAppProducts,
            });
            resolve(true);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  getFoodAppPayments(selectedAccountId) {
    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(
            `${this.foodSeriviceUrl}/api/payment/getPaymentTypes`,
            { accountId: selectedAccountId },
            this.getHeaders()
          )
          .toPromise()
          .then((o: any) => {
            console.log(o)
            this.foodAppPaymentType = o;

            this.integrationStore.update({
              foodAppPaymentType: this.foodAppPaymentType,
            });
            resolve(true);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }


  syncFoodAppPayments(storeId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/payment/syncPaymentTypes`,
          { storeId: storeId },
          this.getHeaders()
        )
        .toPromise()
        .then((response: any) => {
          this.glb.closeAllLoading()
          if (response.statusCode === "200") {
            this.glb.toast("", "Ödeme Tipi Seknronizasyonu Başarılı", "bottom", "success");
            resolve(true);
          } else {

            this.glb.toast("", "Ödeme tipi senkron işlemi tamamlanamadı!", "bottom", "danger");
            resolve(false);
          }
        })
        .catch((error: any) => {
          this.glb.closeAllLoading()
          console.error('syncFoodAppPayments error:', error);
          this.glb.toast("", "Ödeme tipi senkron işlemi tamamlanamadı!", "bottom", "danger");
          reject(false);
        });
    });
  }

  deleteFoodAppPayments(accountID: string, id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .request('delete', `${this.foodSeriviceUrl}/api/payment/deletePaymentType`, {
          body: { accountId: accountID, id: id }, // Pass the body this way
          headers: this.getHeaders().headers
        })
        .toPromise()
        .then((response: any) => {
          this.glb.closeAllLoading();
          if (response.statusCode === "200") {
            this.glb.toast("", "Ödeme Tipi Silme Başarılı", "bottom", "success");
            resolve(true);
          } else {
            this.glb.toast("", "Ödeme tipi silme işlemi tamamlanamadı!", "bottom", "danger");
            resolve(false);
          }
        })
        .catch((error: any) => {
          this.glb.closeAllLoading();
          console.error('deleteFoodAppPayments error:', error);
          this.glb.toast("", "Ödeme tipi silme işlemi tamamlanamadı!", "bottom", "danger");
          reject(false);
        });
    });
  }

  saveFoodAppPaymentTypes(paymentData) {
    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(
            `${this.foodSeriviceUrl}/api/payment/savePaymentType`,
            paymentData,
            this.getHeaders()
          )
          .toPromise()
          .then((o: any) => {
            console.log(o)
            this.glb.toast("", "Ödeme Tipi Eşleştirme Başarılı", "bottom", "success")
            resolve(true);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  getFoodAppModifiers(source: string) {
    return new Promise((resolve, reject) => {
      this.publicApi
        .getCollection({
          _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
          col: 'food-apps-modifiers',
          _filter: {
            accountId: this.integrationQuery.activeAccount.id,
            source: source,
          },
        })
        .toPromise()
        .then((o: FoodAppModifier[]) => {
          for (const iterator of o) {
            iterator.changed = false;
            iterator.auto = false;
          }

          this.foodAppModifiers = o;
          this.integrationStore.update({
            foodAppModifiers: this.foodAppModifiers,
          });
          resolve(true);
        })
        .catch((e) => {
          if (
            e.error.statusCode == 500 &&
            e.error.message.indexOf('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'success'
            );
            reject(e);
          } else {
            this.glb.toast(
              'Hata Alındı',
              e?.error?.message,
              'middle',
              'success'
            );
            reject(e);
          }
        });
    });
  }

  getFoodAppCategories(source: string) {
    return new Promise((resolve, reject) => {
      try {
        this.publicApi
          .getCollection({
            _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
            col: 'food-apps-categories',
            _filter: {
              accountId: this.integrationQuery.activeAccount.id,
              source: source,
            },
          })
          .toPromise()
          .then((o: FoodAppCategory[]) => {
            this.foodAppCategories = o;
            this.integrationStore.update({
              foodAppCategories: this.foodAppCategories,
            });
            resolve(true);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  initApplications() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/account/getFoodApplications`,
          {},
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.integrationStore.update({ foodAppAplications: o });
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  getRestaurantMenu(accountId) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/menu/getInternalMenu`,
          { accountId: accountId },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.restaurantMenu = o;
          this.integrationStore.update({ restaurantMenu: this.restaurantMenu });
          resolve(true);
        })
        .catch((e) => {
          if (
            e.error.statusCode == 500 &&
            e.error.message.indexOf('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'success'
            );
            reject(e);
          } else {
            this.glb.toast(
              'Hata Alındı',
              e?.error?.message,
              'middle',
              'success'
            );
            reject(e);
          }
        });
    });
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        accept: 'application/json',
        'x-api-key': 'UjJ4dlltRnVJRmRsWWlCVGFYUmw',
        'x-user-name': this.glb.notTurkishCharacter(
          this.sessionQuery?.user?.name || ''
        ),
      }),
    };
    return httpOptions;
  }

  syncProduct() {
    let prds = this.integrationQuery.foodAppProducts.filter(
      (o) => o.internalId == ''
    );
    for (const iterator of prds) {
      let restPrds = [
        ...new Set(
          this.integrationQuery.restaurantProducts.map((obj) =>
            obj.name.toLocaleLowerCase()
          )
        ),
      ];
      let res = this.stringSmiltary.findBestMatch(
        iterator.name.toLocaleLowerCase(),
        restPrds
      );
      if (res.bestMatch.rating > 0.6) {
        console.log(res.bestMatch.target, iterator.name);
        let prd = this.integrationQuery.restaurantProducts.find(
          (o) => o.name.toLocaleLowerCase() == res.bestMatch.target
        );
        iterator.internalId = prd.id;
        iterator.changed = true;
        iterator.auto = true;
      }

      let prd = this.integrationQuery.restaurantProducts.find(
        (o) =>
          o.name.toLocaleLowerCase() == iterator.name.toLocaleLowerCase() &&
          o.price == iterator.price
      );
      if (prd) {
        iterator.internalId = prd.id;
        iterator.changed = true;
        iterator.auto = true;
      }
    }
  }

  resetProgress() {
    this.progressSubject.next({ matchedName: '', progress: 0 });
  }

  syncModifiers() {
    let prds = this.integrationQuery.foodAppModifiers.filter(
      (o) => o.internalId == ''
    );
    for (const iterator of prds) {
      let restPrds = [
        ...new Set(
          this.integrationQuery.restaurantModifiers.map((obj) =>
            obj.name.toLocaleLowerCase()
          )
        ),
      ];
      if (iterator.name) {
        let res = this.stringSmiltary.findBestMatch(
          iterator.name.toLocaleLowerCase(),
          restPrds
        );
        console.log(res);
        if (res.bestMatch.rating > 0.5) {
          console.log(res.bestMatch.target, iterator.name);
          let prd = this.integrationQuery.restaurantModifiers.find(
            (o) => o.name.toLocaleLowerCase() == res.bestMatch.target
          );
          iterator.internalId = prd.id;
          iterator.changed = true;
          iterator.auto = true;
        }

        let prd = this.integrationQuery.restaurantModifiers.find(
          (o) =>
            o.name.toLocaleLowerCase() == iterator.name.toLocaleLowerCase() &&
            o.price == iterator.price
        );
        if (prd) {
          iterator.internalId = prd.id;
          iterator.changed = true;
          iterator.auto = true;
        }
      }
    }
  }

  async saveProducts() {
    // this.glb.showLoading();
    this.resetProgress();

    const productsToSave = this.integrationQuery.foodAppProducts.filter(
      (o) => o.changed == true
    );
    const totalProducts = productsToSave.length;

    for (let i = 0; i < totalProducts; i++) {
      const iterator = productsToSave[i];

      await this.publicApi.upsertFoodAppProduct({
        _db: this.orwiStoreQuery._orwiStore.cloudId,
        col: 'food-apps-products',
        data: iterator,
      });

      iterator.changed = false;
      this.progressSubject.next({
        matchedName: iterator.name,
        progress: ((i + 1) / totalProducts) * 100,
      });
    }

    this.progressSubject.next({
      matchedName: '',
      progress: 100,
    });

    // this.glb.closeLoading();
  }

  async deleteModifier(item: FoodAppModifier) {
    // console.log({ id: item.id,accountId :item.accountId ,source : item.source});
    await this.publicApi.delete({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: 'food-apps-modifiers',
      filter: { id: item.id, accountId: item.accountId, source: item.source },
    });
  }

  async deleteProduct(item: FoodAppProduct) {
    // console.log({ id: item.id,accountId :item.accountId ,source : item.source});
    await this.publicApi.delete({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: 'food-apps-products',
      filter: { id: item.id, accountId: item.accountId, source: item.source },
    });
  }

  async saveFoodAppStore(foodAppStore: FoodAppStore) {
    this.glb.showLoading();
    await this.publicApi.upsert({
      _db: this.orwiStoreQuery._orwiStore.cloudId,
      col: 'food-apps-stores',
      data: foodAppStore,
    });
    // await this.getFoodAppIntegrations();
    this.glb.closeLoading();
  }

  async deleteFoodAppAccount() {
    try {
      this.glb.showLoading();
      let url = `${this.foodSeriviceUrl}/api/account/deleteAccount`;
      const options: any = {
        headers: this.getHeaders().headers,
        body: { id: this.integrationQuery.activeAccount.id },
      };
      await this.http.delete(url, options).subscribe(async (x: any) => {
        console.log('deleteAccount', x);
        if (x.statusCode === '200') {
          this.glb.toast('Silindi', '', 'middle', 'success');
          await this.getFoodAppIntegrations();
        }
      });

      this.glb.closeLoading();
    } catch (error) {
      this.glb.toast('', 'An error occured', 'middle', 'warning');
    }
  }

  async deleteFoodAppStore() {
    try {
      await this.publicApi.delete({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'food-apps-stores',
        filter: {
          id: this.integrationQuery.activeAccount.restaurantId,
          accountId: this.integrationQuery.activeAccount.id,
        },
      });
    } catch (error) {
      this.glb.toast('', 'An error occured', 'middle', 'warning');
    }
  }

  async saveModifiers() {
    // this.glb.showLoading();
    this.resetProgress();

    const modifiersToSave = this.integrationQuery.foodAppModifiers.filter(
      (o) => o.changed == true
    );
    const totalModifiers = modifiersToSave.length;

    for (let i = 0; i < totalModifiers; i++) {
      const iterator = modifiersToSave[i];

      await this.publicApi.upsertFoodAppModifiers({
        _db: this.orwiStoreQuery._orwiStore.cloudId,
        col: 'food-apps-modifiers',
        data: iterator,
      });

      iterator.changed = false;
      this.progressSubject.next({
        matchedName: iterator.name,
        progress: ((i + 1) / totalModifiers) * 100,
      });
    }

    // this.glb.closeLoading();
  }

  getStores(storeId): Promise<FoodAppStore[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/store/getStores`,
          { storeId: storeId },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          //console.log("stores", o)
          if (o) {
            this.footAppStores = o;
            console.log('getStores', this.footAppStores);
            this.integrationStore.update({ foodAppStores: this.footAppStores });
            resolve(this.footAppStores);
          }
        })
        .catch((e) => {
          if (
            e?.error?.statusCode == 500 &&
            e?.error?.message?.includes('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast(
                'Hata Alındı',
                e?.error?.message,
                'middle',
                'warning'
              );
            }
            reject(e);
          }
        });
    });
  }

  syncStoreMenu(storeId): Promise<FoodAppStore[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/menu/syncMenu`,
          { storeId: storeId },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          //console.log("stores", o)
          console.log('SyncStoreMenu', o);
          resolve(o);
        })
        .catch((e) => {
          if (
            e?.error?.statusCode == 500 &&
            e?.error?.message?.includes('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast(
                'Hata Alındı',
                e?.error?.message,
                'middle',
                'warning'
              );
            }
            reject(e);
          }
        });
    });
  }

  async cancelFolio(folio: Folio) {
    if (!this.ssoService.checkPermission('folio-cancel')) {
      this.glb.permissionToast();
      return;
    }
    let modal = await this.modalService.openModal({
      component: FoodAppsCancelReasonComponent,
      componentProps: {
        folio: folio,
      },
    });
    modal.onDidDismiss().then(async (response) => {
      console.log('selected reason', response);
      return;
      if (response.data) {
        if (folio.type === 'delivery' && folio.appName !== 'orwi') {
          this.foodAppOrderService
            .cancelOrder(folio.id, response.data.reason)
            .then(async (result) => {
              if (result.statusCode === '200') {
                if (folio.deliveryStatus == 'cancelled') {
                  this.folioService._posCloseFolio(folio);
                }
                this.glb.toast(
                  'Sipariş İptali',
                  'Sipariş İptal Edildi.',
                  'bottom',
                  'success'
                );
              } else {
                this.glb.toast(
                  'Dikkat',
                  'Bir Sorun İle Karşılaşıldı.',
                  'bottom',
                  'danger'
                );
              }
            });
        } else {
          folio.status = 'cancelled';
          folio.deliveryStatus = 'cancelled';
          folio.deliveryStatus = 'cancelled';
          folio.note = response.data.reason.description;
          this.fs.update(({ openFolios }) => ({
            openFolios: arrayUpsert(openFolios, folio.id, folio),
          }));
          this.folioService._posSaveFolio(folio);
          this.glb.toast(
            'Sipariş İptali',
            'Sipariş İptal Edildi.',
            'bottom',
            'success'
          );
        }
      }
    });
  }

  async openSheetForMobile(folio: Folio) {
    this.integrationQuery.statuses;
    console.log('statuses', this.integrationQuery.statuses);
    const actionSheet = await this.glb.actionSheetController.create({
      header: this.transloco.translate('Delivery Status'),
      // subHeader: 'Gerekirse Kullanılacak.',
      buttons: [
        ...this.integrationQuery.statuses.map(
          (el) =>
          ({
            data: el.id,
            id: el.id,
            text: el.title,
            cssClass:
              folio.deliveryStatus == el.id ? 'active-sheet-item' : '',
          } as ActionSheetButton)
        ),
        {
          text: this.transloco.translate('Close'),
          role: 'cancel',
          cssClass: 'active-sheet-cancel',
        },
      ],
    });

    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    const deliveryStatus: DeliveryStatus = result.data;

    if (result.data == 'cancelled') {
      if (
        !this.ssoService.checkPermission(
          'folio-list-change-status-no-cancel'
        ) ||
        !this.ssoService.checkPermission('folio-list-cancel-folio')
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('folio-list-cancel-closed-folio') &&
        folio.status === 'closed'
      ) {
        this.glb.permissionToast();
        return;
      }
      let modal = await this.modalService.openModal({
        component: FoodAppsCancelReasonComponent,
        componentProps: {
          folio: folio,
        },
      });

      modal.onDidDismiss().then(async (response) => {
        console.log('selected reason', response);
        if (response.data) {
          if (folio.type === 'delivery' && folio.appName !== 'orwi') {
            this.foodAppOrderService
              .cancelOrder(folio.id, response.data.reason)
              .then(async (result) => {
                if (result.statusCode === '200') {
                  if (folio.deliveryStatus == 'cancelled') {
                    this.folioService._posCloseFolio(folio);
                  }
                  this.glb.toast(
                    'Sipariş İptali',
                    'Sipariş İptal Edildi.',
                    'bottom',
                    'success'
                  );
                } else {
                  this.glb.toast(
                    'Dikkat',
                    'Bir Sorun İle Karşılaşıldı.',
                    'bottom',
                    'danger'
                  );
                }
              });
          } else {
            folio.status = 'cancelled';
            folio.deliveryStatus = 'cancelled';
            folio.note = response.data.reason.description;
            this.fs.update(({ openFolios }) => ({
              openFolios: arrayUpsert(openFolios, folio.id, folio),
            }));
            this.folioService._posSaveFolio(folio);
          }
        }
      });
    } else {
      if (deliveryStatus && deliveryStatus !== folio.deliveryStatus) {
        this.updateOrderStatus(folio.id, result.data).then(
          (res) => {
            console.log('Response from FoodApp', res);
            folio.deliveryStatus = result.data;

            if (folio.deliveryStatus == 'cancelled') {
              this.folioService._posCloseFolio(folio);
            } else {
              this.fs.update(({ openFolios }) => ({
                openFolios: arrayUpsert(openFolios, folio.id, folio),
              }));
            }
            this.modalController.dismiss();
            this.glb.toast('', 'İşlem Başarılı', 'middle', 'success');
          },
          (err) => {
            this.modalController.dismiss();
            this.glb.toast('An error occured', err, 'middle', 'danger');
          }
        );
      }
    }
  }

  changeStatus(item: FoodAppStore, status: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/store/updateStoreStatus`,
          { accountId: item.accountId, storeId: item.id, status: status },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          console.log('stores', o);

          if (o.statusCode == '200') {
            item.status = status;
            const prm = {} as mongo_upsert_params;
            let filter = { accountId: item.accountId, id: item.id };
            prm._filter = filter;
            prm.data = item;
            prm.col = 'food-apps-stores';
            prm._db = this.sessionQuery.activeLicense.orwiStore.cloudId;
            this.publicApi.upsertGeneric(prm);

            this.integrationStore.update(({ foodAppStores }) => ({
              foodAppStores: arrayUpsert(foodAppStores, item.id, item, 'id'),
            }));
            resolve(true);
          } else {
            if (o.statusCode == '500') {
              this.glb.toast('', o.message, 'bottom', 'danger'), resolve(false);
            }
          }
        })
        .catch((e) => {
          this.integrationStore.update(({ foodAppStores }) => ({
            foodAppStores: arrayUpsert(foodAppStores, item.id, item, 'id'),
          }));

          if (
            e.error.statusCode == 500 &&
            e.error.message.indexOf('client not found')
          ) {
            this.glb.toast(
              'İstek Gerçekleştirilemedi',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'success'
            );
            reject(e);
          } else {
            this.glb.toast(
              'İstek Gerçekleştirilemedi',
              e?.error?.message,
              'middle',
              'success'
            );
            reject(e);
          }
        });
    });
  }

  getApplications() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/account/getFoodApplications`,
          {},
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.integrationStore.update({ fooAppApplications: o });
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  initStatuses() {
    this.updateStatuses();

    this.transloco.langChanges$.subscribe(() => {
      this.updateStatuses();
    });
  }

  updateStatuses() {
    let statuses: FoodAppServiceStatus[] = [
      {
        id: 'cancelled',
        selected: false,
        status: -30,
        title: this.transloco.translate('Cancel'),
      },
      {
        id: 'accepted',
        selected: false,
        status: 0,
        title: this.transloco.translate('Accepted'),
      },
      {
        id: 'prepared',
        selected: false,
        status: 30,
        title: this.transloco.translate('Prepared'),
      },
      {
        id: 'routing',
        selected: false,
        status: 80,
        title: this.transloco.translate('Routing'),
      },
      {
        id: 'completed',
        selected: false,
        status: 120,
        title: this.transloco.translate('Completed'),
      },
      {
        id: 'new',
        selected: false,
        status: -1,
        title: this.transloco.translate('New'),
      },
    ];
    this.integrationStore.update({ fooAppStatuses: statuses });
  }

  initAps() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/account/getFoodApplications`,
          {},
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          this.integrationStore.update({ fooAppApplications: o });
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  saveOrder(order: Folio) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/order/saveOrder`,
          { order },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          o;
          // this.integrationStore.update({ fooAppApplications: o });
          this.glb.toast(
            'Success',
            'Folio has been synchronized with Kerzz',
            'bottom',
            'success'
          );
          resolve(true);
        })
        .catch((e: any) => {
          this.glb.toast(
            'Error',
            'Folio could not be synchronized with Kerzz',
            'bottom',
            'danger'
          );
          reject(e);
        });
    });
  }

  async printProcess(folio: Folio, isSocketFolio = false) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          this.orwiStore
            .getValue()
            .printers.filter((p) => p.printerType == 'folio').length > 0
        ) {
          let promise;
          if (isSocketFolio) {
            promise = this.printCoverService.printSocketFolio(folio);
          } else {
            promise = this.printCoverService.printFolio(folio);
          }
          if (!promise) promise = this.printCoverService.printFolio(folio);
          await promise.then(async (c) => {
            await this.printCoverService.printLabel(folio);
            resolve(c);
          });
          // this.glb.toast("", 'Sipariş Yazıdırıldı.', 'bottom', 'success')
        }

        if (
          this.orwiStore
            .getValue()
            .printers.filter((p) => p.printerType === 'label').length > 1
        ) {
          await this.folioService._posDoneFolio(folio);
        }

        if (
          this.orwiStore
            .getValue()
            .printers.filter((p) => p.printerType == 'unit').length == 0
        ) {
          //!YAZDIRMA UYARISI KALDIRILDI
        } else {
          folio.rows.map((val) => {
            if (val.printer || val.printer == '') {
              val.printer =
                this.menuStore
                  .getValue()
                  ?.products.find((p) => p.id == val.itemID)?.printer ||
                this.orwiStore
                  .getValue()
                  .printers.filter((p) => p.printerType == 'unit')[0].name;
            }
          });

          folio.rows.map((val) => {
            val.tax =
              this.menuStore.getValue().products.find((x) => x.id == val.itemID)
                ?.vat || 10;
          });
          console.warn('Befora Print : ', folio);
          await this.printCoverService.printUnit(folio);
        }
      } catch (error) {
        reject(false);
      }
    });
  }

  removeFromSaleProduct(id, soldOut, kerzzId) {
    let status = soldOut === true ? false : true;
    if (this.integrationStore.getValue().foodAppStores.length > 0) {
      this.orwiService
        .serviceRequestPromise(
          '/api/menu/setProductStatus',
          {
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
            id: id,
            soldOut: status,
          },
          this.sessionQuery.token
        )
        .then();
      return this.updateProductStatus(kerzzId, soldOut).then();
    } else {
      return this.orwiService
        .serviceRequestPromise(
          '/api/menu/setProductStatus',
          {
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
            id: id,
            soldOut: status,
          },
          this.sessionQuery.token
        )
        .then();
    }
  }

  async updateProductStatus(productId: string, status: boolean) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.foodSeriviceUrl}/api/menu/updateProductStatus`,
          {
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
            externalId: productId,
            isActive: status,
          },
          this.getHeaders()
        )
        .toPromise()
        .then((o: any) => {
          //console.log("stores", o)
          o;
          resolve(true);
        })
        .catch((e) => {
          if (
            e.error.statusCode == 500 &&
            e.error.message.indexOf('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'success'
            );
            reject(e);
          } else {
            this.glb.toast(
              'Hata Alındı',
              e?.error?.message,
              'middle',
              'success'
            );
            reject(e);
          }
        });
    });
  }

  async updateOrderStatus(folioId: string = '', status: DeliveryStatus) {
    let req = this.http.post(
      `${this.foodSeriviceUrl}/api/order/updateOrderStatus`,
      { orderId: folioId, status: status },
      this.getHeaders()
    );
    return req.toPromise();
  }

  listenNotifications() {
    this.socketService.kerzzSocket.on(
      'changed-collection',
      async (c: CollectionChangedResponse) => {
        if (
          c.ns.coll == 'food-apps-notifications' &&
          c.ns.db == this.sessionQuery.activeLicense.orwiStore.cloudId
        ) {
          let nofify: Notification = new Notification();
          nofify = c.fullDocument;
          console.log('NOFIFY', nofify);
          if (!nofify.readed) {
            let seconds = moment().diff(nofify.createDate, 'seconds');
            if (
              nofify.storeId === this.sessionQuery.activeLicense.orwiStore.id &&
              seconds < 600
            ) {
              await this.printCoverService.printNotifications(nofify).then();
              this.toastrService
                .info(nofify.text, nofify.title, {
                  timeOut: 7000,
                  extendedTimeOut: 7000,
                  payload: nofify,
                  progressBar: true,
                })
                .onAction.subscribe((res) => {
                  this.nav.navigateForward(
                    '/food-app-integration/notifications',
                    {
                      queryParams: {
                        selectedNotify: res.id,
                      },
                    }
                  );
                });
            }
          }
        }
      }
    );
  }

  async trackStore() {
    //TODO STORE CHANGE EVENT CONTROL ET
    await this.refreshFoodAppStoreStatus();
    this.socketService.kerzzSocket.on(
      'changed-collection',
      async (c: CollectionChangedResponse) => {
        if (
          c.ns.coll == 'food-apps-stores' &&
          c.ns.db == this.sessionQuery.activeLicense.orwiStore.cloudId
        ) {
          this.integrationStore.update(({ foodAppStores }) => ({
            foodAppStores: arrayUpsert(
              foodAppStores,
              c.fullDocument.id,
              c.fullDocument
            ),
          }));
          console.log('trackStore', c.fullDocument);
          // if (this.sessionQuery.activeLicense.orwiStore.id === this.foodAppAccounts.find(x=>x.id === c.fullDocument.accountId).storeId)
          // {

          // }
          // this.printCoverService
          //   .printFoodAppStatus(c.fullDocument, c.fullDocument.status)
          //   .then();
          this.closedStores = this.integrationQuery.foodAppStores.filter(
            (o) => o.status !== 'open'
          ).length;
        }
      }
    );

    this.socketService.kerzzSocket.emit('subscribe-collection', {
      dbName: this.sessionQuery.activeLicense.orwiStore.cloudId,
      collection: 'food-apps-stores',
    });
  }

  async getNotifications(pagination?: Pagination): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      lastValueFrom(
        this.http.post(
          `${this.foodSeriviceUrl}/api/notification/getStoreNotifications`,
          {
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
            skip: pagination?.skip ?? 0,
            limit: pagination?.limit ?? 5,
          },
          this.getHeaders()
        )
      )
        .then((o: Notification[]) => {
          resolve(o);
        })
        .catch((e) => {
          if (
            e.error.statusCode == 500 &&
            e.error.message.indexOf('client not found')
          ) {
            this.glb.toast(
              'İşletmeye Bağlanamıyorum',
              'Bilgisayar kapalı yada internet problemi olabilir',
              'middle',
              'success'
            );
            reject(e);
          } else {
            this.glb.toast(
              'Hata Alındı',
              e?.error?.message,
              'middle',
              'success'
            );
            reject(e);
          }
        });
    });
  }

  async refreshFoodAppStoreStatus() {
    if (!this.sessionQuery.activeLicense?.orwiStore?.id) return;
    let stores = await this.getStores(
      this.sessionQuery.activeLicense.orwiStore.id
    );
    this.closedStores = stores?.filter((o) => o.status !== 'open')?.length;
  }
}

interface Pagination {
  skip?: number;
  limit?: number;
}
