import { paymentTypes } from '../../dto/orwi-store';

export class CompleteSale {
  TransactionHandle: TransactionHandle;
  Sale: Sale;
  /**
   *
   */
  constructor() {
    this.Sale = new Sale();
  }
}

export interface TransactionHandle {
  SerialNumber: string;
  Fingerprint: string;
  TransactionSequence: number;
  TransactionDate: string;
}

export class Sale {
  /**
   * Harici Uygulama adı
   */
  RefererApp: string;
  /**
   * Harici Uygulama Version
   */
  RefererAppVersion?: string;
  /**
   * Harici Sipariş Numarası
   */
  OrderNo?: string;
  /**
   * E-Fatura 1
   */
  MainDocumentType: number;
  /**
   * Sepetin Brüt Tutarı
   */
  GrossPrice: number;
  /**
   * Sepetin Net Tutarı
   */
  TotalPrice: number;
  /**
   * Sms Bilgilendirmesi Yapılsın mı
   */
  SendPhoneNotification: boolean;
  /**
   * E-posta Bilgilendirmesi Yapılsın mı
   */
  SendEMailNotification: boolean;
  /**
   * Sms Bilgilendirmesi True ise zorunlu
   */
  NotificationPhone?: boolean;
  /**
   * E-posta Bilgilendirmesi True ise zorunlu
   */
  NotificationEMail?: boolean;
  /**
   * Ödeme iptalini sonraya bırak
   */
  CancelPaymentLater?: boolean;
  /**
   * Dövizli ödeme için döviz kodu
   */
  CurrencyCode?: string;
  /**
   * Dövizli ödeme için döviz kuru
   */
  ExchangeRate?: string;
  /**
   * Ürün Listesi
   */
  AddedSaleItems: SaleItem[];
  /**
   * Nihai Tüketici Değilse Zorunlu
   *
   * Nihai Tüketici Bilgileri
   */
  CustomerParty?: CustomerParty;
  /**
   *
   */
  ExternalPayments?: Payments[];
  /**
   * Kart ekranında görüntülenmesi istenen slot tipleri
   * @params rf(Temassız), icc(Çip), magneticStripe(Manyetik), qr(Karekod), manual(Manuel)
   *
   * boş olursa hepsi gider.
   */
  SelectedSlots: string[];
  PriceEffect?: PriceEffectType;
  ReceiptInformation?: ReceiptInformation;

  AllowDismissCardRead?: boolean;
  TryAgainOnPaymentFailure?: boolean;
  CardReadTimeout?: number;

  /**
   * Yazıcıya parçalı ön ödemeler göndermek için kullanılıyor.
   *
   * Örneğin 50TL tutarındaki fatura için 20 Nakit, 30 Kredi Kartı
   *
   * Öncelikle pavoService.getPaymentMediators() çağrılmalı
   */
  PaymentInformations?: PaymentInformations[];

  /**
   * Pos üzerinden nakit tutarın değiştirebileceğini belirler.
   * @default false
   */
  SkipAmountCash: boolean;

  /**
   * Pos üzerinden kredi kartı satış ekranını gösterir.
   * @default true
   */
  ShowCreditCardMenu: boolean;

  /**
   *
   */
  constructor() {
    this.SelectedSlots = [];
    this.SendPhoneNotification = false;
    this.SendEMailNotification = false;
    this.RefererAppVersion = '1';
    this.RefererApp = 'Kerzz POS';
    this.MainDocumentType = 1;
    this.AddedSaleItems = [];
    this.PaymentInformations = [];
    this.PriceEffect = {} as any;
    this.SkipAmountCash = true;
    this.ShowCreditCardMenu = false;
  }
}

export class ReceiptInformation {
  ReceiptImageEnabled: true;
  ReceiptWidth: '58mm' | '80mm' = '58mm';

  /**
   *
   */
  constructor() {
    this.ReceiptImageEnabled = true;
    this.ReceiptWidth = '58mm';
  }
}

export interface SaleItem {
  Name: string;
  IsGeneric: boolean;
  UnitCode: string;
  TaxGroupCode: string;
  ItemQuantity: number;
  UnitPriceAmount: number;
  GrossPriceAmount: number;
  TotalPriceAmount: number;
}

export class CustomerParty {
  /**
   * 1 - Gerçek Kişi
   *
   * 2 - Tüzel Kişi
   */
  CustomerType: 1 | 2;
  /**
   * Gerçek Kişi ise zorunlu
   */
  FirstName: string;
  MiddleName?: string;
  /**
   * Gerçek Kişi ise zorunlu
   *
   * Müşteri Soyadı
   */
  FamilyName: string;
  /**
   * Tüzel Kişi ise zorunlu
   */
  CompanyName: string;
  /**
   * Tüzel Kişi ise zorunlu
   */
  TaxOfficeCode: string;
  /**
   * Vergi Numarası
   */
  TaxNumber: string;
  /**
   * Müşteri Telefon Numarası
   */
  Phone?: string;
  /**
   * Müşteri E-posta adresi
   */
  EMail?: string;

  Country: string;
  City: string;
  District: string;
  Neighborhood?: string;
  Address?: string;

  /**
   *
   */
  constructor() {}
}

/**
 * Yazıcıya parçalı ön ödemeler göndermek için kullanılıyor
 */
export class PaymentInformations {
  Mediator: number;
  Amount: number;
}

export interface Payments {
  Type: PavoPaymentTypes;
  Mediator: number;
  Brand: number;
  Amount: number;
  CardNo: string;
  AuthorizationCode: string;
}

export type PriceEffectType = {} & (
  | {
      Amount: number;
      Type: PavoPriceEffectTypes;
      Rate?: number;
    }
  | {
      Rate: number;
      Type: PavoPriceEffectTypes;
      Amount?: number;
    }
);

export enum PavoPriceEffectTypes {
  LineBasedDiscount = 1, // Satır bazlı indirim
  SaleBasedDiscount = 2, // Satış bazlı indirim
  InterestOfSale = 3, // Satış bazlı artırım
  InterestOfLine = 4, // Satır bazlı artırım
}

export enum PavoPaymentTypes {
  Nakit = 1,
  Kredi_Karti = 2,
  Yemek_Karti = 3,
  Fatura = 10,
  Hediye_Karti = 13,
  Cek = 18,
}

export const PavoPaymentTypesArray: { type: paymentTypes; id: number }[] = [
  {
    type: 'cash',
    id: PavoPaymentTypes.Nakit,
  },
  {
    type: 'bank',
    id: PavoPaymentTypes.Kredi_Karti,
  },
  {
    type: 'meal-voucher',
    id: PavoPaymentTypes.Yemek_Karti,
  },
  {
    type: 'account',
    id: PavoPaymentTypes.Cek,
  },
  {
    type: 'other',
    id: PavoPaymentTypes.Fatura,
  },
];

export interface IPaymentMediators {
  id: number;
  Name: string;
  PaymentTypeId: PavoPaymentTypes;
  IsExternal: boolean;
  IsOffline: boolean;
  PaymentMediatorOperationTypes: OparationTypes;
  OperationTypeId: number;
  Amount: number;
}

export enum OparationTypes {
  Payment = 1,
  Void = 2,
  Refund = 3,
}

export interface PaymentProviderBrand {
  Name: string;
  Mediator: number;
}

export enum PavoAndroidIntents {
  StartSale = 'pavopay.intent.action.start.sale',
  StartPayment = 'pavopay.intent.action.start.payment',
  CompleteSale = 'pavopay.intent.action.complete.sale',
  CancelSale = 'pavopay.intent.action.cancel.sale',
  CurrentSale = 'pavopay.intent.action.current.sale',
  GetMediators = 'pavopay.intent.action.get.mediators',
  CompletedSale = 'pavopay.intent.action.completed.sale',
  PrintOut = 'pavopay.intent.action.print.out',
  CreateReport = 'pavopay.intent.action.create.report',
  GetDeviceInfo = 'pavopay.intent.action.get.device.info',
  SendOfflineSale = 'pavopay.intent.action.send.offline.sales',
  PerformEOD = 'pavopay.intent.action.performEOD',
}

// {
//     TaxGroupId : 48,
//     Name : KDV %18,
//     Code : KDV18
// },
// {
//     TaxGroupId : 49,
//     Name : KDV %8,
//     Code : KDV8
// },
// {
//     TaxGroupId : 1,
//     Name : KDV %1,
//     Code : KDV1
// }

export class PavoCompleteSaleResult {
  HasError: any;
  Data: CompleteSaleResult;
  Errors: string[];

  constructor(data: any, hasError: any) {
    this.HasError = hasError;
    this.Data = data as CompleteSaleResult;
  }
}

interface CompleteSaleResult {
  Id: number;
  SaleNumber: string;
  IsOffline: boolean;
  OrderNo: string;
  TypeId: number;
  StatusId: number;
  GrossPrice: number;
  TotalPrice: number;
  TotalVATAmount: number;
  MerchantId: number;
  TerminalId: number;
  ApplicationId: number;
  MultiPayment: boolean;
  MultiDocument: boolean;
  GMUVersion: string;
  RefererApp: string;
  RefererAppVersion: string;
  SendPhoneNotification: boolean;
  SendEMailNotification: boolean;
  AddedSaleItems: AddedSaleItem[];
  AddedPriceEffects: any[];
  AddedPayments: AddedPayment[];
  FinancialDocuments: FinancialDocument[];
  SaleUid: string;
  Taxes: Tax[];
  Errors: string[];
}

interface Tax {
  TaxRate: number;
  TotalTaxAmount: number;
  TotalAmount: number;
}

interface FinancialDocument {
  Id: number;
  InvoiceNo: string;
  DocumentNo: string;
  Type: Type;
}

interface Type {
  Id: number;
  Name: string;
  Cancellable: boolean;
}

interface AddedPayment {
  Id: number;
  SaleId: number;
  StatusId: number;
  MerchantId: number;
  PaymentAmount: number;
  PaymentTypeId: number;
  PaymentMediatorId: number;
  OperationTypeId: number;
  IsExternal: boolean;
  CashPayment: CashPayment;
}

interface CashPayment {
  Id: number;
  PaymentId: number;
  SaleId: number;
  GivenAmount: number;
  ChangeAmount: number;
}

interface AddedSaleItem {
  Id: number;
  SaleId: number;
  StatusId: number;
  Name: string;
  ItemQuantity: number;
  UnitPriceAmount: number;
  GrossPriceAmount: number;
  TotalPriceAmount: number;
  VATAmount: number;
  VATRate: number;
  UnitName: string;
  TaxGroupId: number;
  IsGeneric: boolean;
}
