import { Injectable, inject } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  IonRouterOutlet,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  PopoverOptions,
  ToastController,
  ToastOptions,
} from '@ionic/angular';
// import { LoadingOptions } from '@ionic/core';
import { TranslocoService } from '@ngneat/transloco';
import { feedback } from './dto/orwi-feedback';
import { Device } from '@capacitor/device';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { ActionSheetOptions } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { AlertOptions } from '@ionic/core/dist/types/components/alert/alert-interface';
import { ModalOptions } from '@ionic/core/dist/types/components/modal/modal-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrwiLockModalComponent } from '../components/ui/orwi-lock-modal/orwi-lock-modal.component';
import { NativeAudio } from '@capgo/native-audio';
import * as XLSX from 'xlsx';
import { locale, loadMessages } from 'devextreme/localization';
import * as trMessages from 'devextreme/localization/messages/tr.json';
import { OrwiBarcodeListModalComponent } from '../shared/components/orwi-barcode-list-modal/orwi-barcode-list-modal.component';
import { InvoiceService } from '../modules/backOffice/invoice/state/invoice.service';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { InventorySelectItem } from 'kerzz-base';
import { BackOfficeSharedService } from '../modules/backOffice/shared/state/backOffice.global.service';
import { ModalService } from './helpers/modal.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  smartyUrl = 'https://smarty.kerzz.com:4004';
  deliveryAudio = new Audio();
  feedback: feedback;

  DeviceInfo: any;
  DeviceUUID: string;
  paramId: string;
  userLocale: any = '';
  enterKeyAction: DxDataGridTypes.EnterKeyAction = 'startEdit';
  PushToken = '';
  ecrIPAddress = '';
  terminalIdAddress = '';
  ecrServiceType = 'none';
  darkTheme = false;
  startupQrCode = '';
  socketStatus: 'connecting' | 'connected' | 'offline' = 'offline';
  networkConnected = true;
  googleMapSdkInjected = false;
  googleApiKey = 'AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c';

  oLoading: HTMLIonLoadingElement;

  ipAddress = '';

  orwiContent: any;

  socketId = '';

  tempBanner =
    'https://www.turizmgunlugu.com/wp-content/uploads/2017/12/mila-restoran.jpg';

  exchangeRates: Exchange[] = [];

  routerOutlet: HTMLIonRouterOutletElement;

  LOCALES = [
    {
      id: 'tr',
      locale: 'tr-TR',
    },
    {
      id: 'en',
      locale: 'en-US',
    },
  ];

  LANGUAGES = [
    {
      id: 'tr',
      name: 'Türkçe',
    },
    {
      id: 'en',
      name: 'English',
    },
    {
      id: 'de',
      name: 'Deutsch',
    },
    {
      id: 'ar',
      name: 'عربى',
    },
    {
      id: 'ru',
      name: 'Русский',
    },
  ];

  banks = [
    {
      value: 'garanti',
      name: 'Garanti Bankası',
      vendorId: '62',
    },
    {
      value: 'isbank',
      name: 'İş Bankası',
      vendorId: '64',
    },
    {
      value: 'merkez',
      name: 'TC Merkez Bankası',
      vendorId: '1',
    },
    {
      value: 'iller',
      name: 'İller Bankası',
      vendorId: '4',
    },
    {
      value: 'ziraat',
      name: 'Ziraat Bankası',
      vendorId: '10',
    },
    {
      value: 'halk-bankasi',
      name: 'Halk Bankası',
      vendorId: '12',
    },
    {
      value: 'sinai',
      name: 'Sınai Merkez Bankası',
      vendorId: '14',
    },
    {
      value: 'vakiflar',
      name: 'Vakıflar Bankası',
      vendorId: '15',
    },
    {
      value: 'exim',
      name: 'Exim Bank',
      vendorId: '16',
    },
    {
      value: 'kalkinma',
      name: 'Kalkınma Bankası',
      vendorId: '17',
    },
    {
      value: 'birlesik-fon',
      name: 'Birleşik Fon Bankası',
      vendorId: '29',
    },
    {
      value: 'ekonomi',
      name: 'Ekonomi Bankası',
      vendorId: '32',
    },
    {
      value: 'akbank',
      name: 'Akbank',
      vendorId: '46',
    },
    {
      value: 'sekerbank',
      name: 'Şekerbank',
      vendorId: '59',
    },
    {
      value: 'yapi-ve-kredi',
      name: 'Yapı Ve Kredi Bankası',
      vendorId: '67',
    },
    {
      value: 'arap-turk',
      name: 'Arap Türk Bankası',
      vendorId: '91',
    },
    {
      value: 'citibank',
      name: 'Citi Bank',
      vendorId: '92',
    },
    {
      value: 'bank-mellat',
      name: 'Bank Mellat Türkiye',
      vendorId: '94',
    },
    {
      value: 'turkish',
      name: 'Turkish Bank',
      vendorId: '96',
    },
    {
      value: 'jpmorgan',
      name: 'JP Morgan Chase Bank',
      vendorId: '98',
    },
    {
      value: 'ing',
      name: 'Ing Bank',
      vendorId: '99',
    },
    {
      value: 'adabank',
      name: 'Adabank',
      vendorId: '100',
    },
    {
      value: 'fiba',
      name: 'Fibabanka',
      vendorId: '103',
    },
    {
      value: 'turkland',
      name: 'Turkland Bank',
      vendorId: '108',
    },
    {
      value: 'icbc',
      name: 'ICBC Turkey Bank',
      vendorId: '109',
    },
    {
      value: 'qnb-finans',
      name: 'QNB Finansbank',
      vendorId: '111',
    },
    {
      value: 'deutsche',
      name: 'Deutsche Bank',
      vendorId: '115',
    },
    {
      value: 'pasha',
      name: 'Pasha Yatırım Bank',
      vendorId: '116',
    },
    {
      value: 'standard-chartered',
      name: 'Standard Chartered Yatırım Bankası',
      vendorId: '121',
    },
    {
      value: 'societe',
      name: 'Societe Generale',
      vendorId: '112',
    },
    {
      value: 'hsbc',
      name: 'HSCB Bank',
      vendorId: '123',
    },
    {
      value: 'alternatif',
      name: 'Alternatif Bank',
      vendorId: '124',
    },
    {
      value: 'burgan',
      name: 'Burgan Bank',
      vendorId: '125',
    },
    {
      value: 'merrill-lynch',
      name: 'Merrill Lynch Yatırım Bank',
      vendorId: '129',
    },
    {
      value: 'ist-takas-saklama',
      name: 'İstanbul Takas ve Saklama Bank',
      vendorId: '132',
    },
    {
      value: 'denizbank',
      name: 'Denizbank',
      vendorId: '134',
    },
    {
      value: 'anadolubank',
      name: 'Anadolubank',
      vendorId: '125',
    },
    {
      value: 'rabobank',
      name: 'Rabobank',
      vendorId: '137',
    },
    {
      value: 'diler-yatirim',
      name: 'Diler Yatırım Bankası',
      vendorId: '138',
    },
    {
      value: 'gsd',
      name: 'GSD Yatırım Bankası',
      vendorId: '139',
    },
    {
      value: 'nurol',
      name: 'Nurol Yatırım Bankası',
      vendorId: '141',
    },
    {
      value: 'bankpozitif',
      name: 'Bankpozitif Kredi ve Halk Bankası',
      vendorId: '142',
    },
    {
      value: 'aktif-yatirim',
      name: 'Aktif Yatırım Bankası',
      vendorId: '143',
    },
    {
      value: 'odea',
      name: 'Odea Bank',
      vendorId: '146',
    },
    {
      value: 'mufg',
      name: 'Mufg Bank Turkey',
      vendorId: '147',
    },
    {
      value: 'intesa',
      name: 'Intesa Sanpaolo',
      vendorId: '148',
    },
    {
      value: 'bank-of-china',
      name: 'Bank Of China Turkey',
      vendorId: '149',
    },
    {
      value: 'albaraka',
      name: 'Albaraka Türk Katılım Bankası',
      vendorId: '203',
    },
    {
      value: 'kuveyt',
      name: 'Kuveyt Türk Katılım Bankası',
      vendorId: '205',
    },
    {
      value: 'finans',
      name: 'Türkiye Finans Katılım Bankası',
      vendorId: '206',
    },
    {
      value: 'ziraat-katilim',
      name: 'Ziraat Katılım Bankası',
      vendorId: '209',
    },
    {
      value: 'vakif-katilim',
      name: 'Vakıf Katılım Bankası',
      vendorId: '210',
    },
    {
      value: 'merkezi-kayit',
      name: 'Merkezi Kayıt Kuruluşu',
      vendorId: '806',
    },
  ];

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private translocoService: TranslocoService,
    public modalController: ModalController,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    public popoverController: PopoverController,
    private http: HttpClient,
    private plt: Platform,
    private nav: NavController,
    _routerOutlet: IonRouterOutlet
  ) {
    this.getExchangeRates();
    this.initDevice();
    this.getUserLocale();

    this.routerOutlet = _routerOutlet.nativeEl;

    let path = '';

    this.plt.ready().then((o) => {
      o; //reserved
      if (plt.is('capacitor')) path = 'public/assets/sounds/';
      path;
      NativeAudio.preload({
        assetId: 'delivery',
        assetPath: './assets/sounds/delivery.mp3',
        audioChannelNum: 1,
        isUrl: false,
      });

      NativeAudio.preload({
        assetId: 'alert',
        assetPath: './assets/sounds/alert.wav',
        audioChannelNum: 1,
        isUrl: false,
      });

      NativeAudio.preload({
        assetId: 'bell',
        assetPath: './assets/sounds/bell.wav',
        audioChannelNum: 1,
        isUrl: false,
      });

      NativeAudio.preload({
        assetId: 'assign_courier',
        assetPath: './assets/sounds/assign_courier.wav',
        audioChannelNum: 1,
        isUrl: false,
      });
    });
  }

  async initDevice() {
    await this.plt.ready();
    if (localStorage.getItem('deviceUuid')) {
      this.DeviceUUID = localStorage.getItem('deviceUuid');
    } else {
      this.DeviceInfo = await Device.getInfo();
      this.DeviceUUID = await (await Device.getId()).identifier;
      localStorage.setItem('deviceUuid', this.DeviceUUID);
    }
  }

  getUserLocale() {
    debugger;
    this.userLocale =
      this.LOCALES.find((elm) => elm.id == localStorage.getItem('lang'))
        ?.locale ?? 'tr';
    loadMessages(trMessages);
    locale(this.userLocale);
    console.log('ACTIVE LANG', localStorage.getItem('lang'));
    console.log('USER LOCALE', this.userLocale);
  }
  loadingTimeout = undefined;
  consolelog(
    log1,
    log2 = undefined,
    log3 = undefined,
    logType: 'log' | 'error' | 'warn' = 'log'
  ) {
    if (document.URL.indexOf('localhost') !== -1) {
    }
    if (logType == 'log') {
    } else if (logType == 'error') {
      console.error(log1, log2, log3);
    } else {
      console.warn(log1, log2, log3);
    }
  }

  overRideClosing = true;

  /**
   *
   * @param m mesaj
   * @param duration saniye cinsinden
   * @returns void
   */
  async showLoading(m: string = '', duration = 30) {
    m;
    const globalLoading = document.getElementById('orwi-global-loading');
    console.log('global', globalLoading);
    if (globalLoading) {
      if (!globalLoading.classList.contains('show')) {
        this.loadingTimeout = setTimeout(() => {
          this.overRideClosing = true;
          this.closeLoading();
        }, duration * 1000);
        globalLoading.classList.add('show');
      }
    }

    return;
  }

  truncateText(text: any, maxLength) {
    if (text?.length > maxLength) {
      return (text = text.substring(0, maxLength) + '…');
    } else {
      return text ? text : '';
    }
  }

  closeLoading(): Promise<boolean> {
    return new Promise((resolve) => {
      const globalLoading = document.getElementById('orwi-global-loading');
      setTimeout(() => {
        if (globalLoading && this.overRideClosing) {
          clearTimeout(this.loadingTimeout);
          globalLoading.classList.remove('show');
          this.overRideClosing = true;
        }
      }, 1000);

      return resolve(true);
    });
  }

  async closeAllLoading() {
    this.loadingCtrl;
    return new Promise((resolve) => {
      const globalLoading = document.getElementById('orwi-global-loading');

      setTimeout(() => {
        if (globalLoading && this.overRideClosing) {
          clearTimeout(this.loadingTimeout);
          globalLoading.classList.remove('show');
          this.overRideClosing = true;
        }
      }, 1000);

      return resolve(true);
    });
  }
  async toast(
    header,
    message,
    position: 'top' | 'middle' | 'bottom',
    color: 'danger' | 'warning' | 'success' | 'primary',
    duration: number = 2000,
    button: boolean = false,
    otherOpt: ToastOptions = {}
  ) {

    if(color === 'danger' || color === 'warning'){
    const alert = await this.alertController.create({
      header: this.translocoService.translate(header),
      message: this.translocoService.translate(message),
      buttons: [{
        text: this.translocoService.translate('Ok'),
        role: 'confirm',
      }]
    });
      await alert.present();
      return;
    }

    let config = {
      header: this.translocoService.translate(header),
      message: this.translocoService.translate(message),
      position: position,
      color: color,
      animated: true,
      cssClass: 'toast',
      ...otherOpt,
    };

    if (button) {
      config['buttons'] = [
        {
          text: this.translocoService.translate('Yes'),
          role: 'cancel',
          handler: () => {
            // this.consolelog('Cancel clicked');
          },
        },
        {
          text: this.translocoService.translate('No'),
          role: 'cancel',
          handler: () => {
            // this.consolelog('Cancel clicked');
          },
        },
      ];
    } else {
      config['duration'] = duration;
    }

    const toast = await this.toastCtrl.create(config);
    toast.present();
  }

  async permissionToast() {
    const alert = await this.alertController.create({
      header: this.translocoService.translate('Permission'),
      message: this.translocoService.translate('You do not have permission for this action, please contact your manager.'),
      buttons: [{
        text: this.translocoService.translate('Ok'),
        role: 'confirm'
      }]
    });
    await alert.present();
    return;
  }

  str2arrayBuffer(str: string): ArrayBuffer {
    var enc = new TextEncoder();
    let unit8Arr = enc.encode(str);
    return unit8Arr.buffer;
  }

  arrayBuffer2str(buf: ArrayBuffer) {
    var str = '';
    var ui8 = new Uint8Array(buf);
    for (var i = 0; i < ui8.length; i++) {
      str = str + String.fromCharCode(ui8[i]);
    }
    return str.replace('Â©Â¥Â¥Â©', '');
  }

  async toastButton(
    header,
    message,
    position: 'top' | 'middle' | 'bottom',
    color: 'danger' | 'warning' | 'success',
    okey = true,
    cancel = true,
    done = false
  ) {
    let duration = 0;

    let buttons = [];

    return new Promise(async (resolve, reject) => {
      try {
        let config = {
          header: this.translocoService.translate(header),
          message: this.translocoService.translate(message),
          position: position,
          color: color,
          animated: true,
          cssClass: 'toast',
        };

        if (cancel) {
          buttons.push({
            text: this.translocoService.translate('No'),
            role: 'cancel',
            handler: () => {
              resolve('no');
            },
          });
        }

        if (okey) {
          buttons.push({
            text: this.translocoService.translate('Yes'),
            role: 'okey',
            handler: () => {
              resolve('yes');
            },
          });
        }

        if (done) {
          buttons.push({
            text: this.translocoService.translate('Done'),
            role: 'okey',
            handler: () => {
              resolve('yes');
            },
          });
        }

        if (buttons) {
          config['buttons'] = buttons;
        } else {
          config['duration'] = duration;
        }

        const toast = await this.toastCtrl.create(config);
        toast.present();
      } catch (err: any) {
        reject(err);
      }
    });
  }

  titleCase(str) {
    return str
      .toLowerCase()
      .replace('-', ' ')
      .split(' ')
      .map(function (word) {
        if (word[0]) {
          return word.replace(word[0], word[0].toUpperCase());
        } else {
          return word;
        }
      })
      .join(' ');
  }

  notTurkishCharacter(value: string): string {
    let replacedVal = value
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
    return replacedVal;
  }

  cleanGsm(gsm: string): string {
    return gsm
      .replace('+', '')
      .replace('(', '')
      .replace('(', '')
      .replace('-', '')
      .replace(' ', '')
      .trim();
  }

  shakeElement(id) {
    let el = document.getElementById(id);

    if (!el) return;

    if (!el.classList.contains('animate__animated')) {
      el.classList.add('animate__animated');
    }

    el.classList.add('animate__shakeX');

    setTimeout(() => {
      el.classList.remove('animate__shakeX');
    }, 1000);
  }

  async playCash() {
    // let audio = new Audio();
    // audio.src = 'assets/sound/cash.wav';
    // audio.load();
    // await audio.play();
  }

  async playPayment() {
    // let audio = new Audio();
    // audio.src = 'assets/sound/payment.wav';
    // audio.load();
    // await audio.play();
  }

  async playSocketError() {
    NativeAudio.play({ assetId: 'alert', time: 0 });
  }

  playBell() {
    NativeAudio.play({ assetId: 'bell', time: 0 });
  }

  playAssignCourier() {
    NativeAudio.play({ assetId: 'assign_courier', time: 0 });
  }

  playDeliveryOrderReceived() {
    NativeAudio.play({ assetId: 'delivery', time: 0 });
  }

  stopDeliveryOrderSound() {
    this.deliveryAudio.pause();
  }
  async playSplitFolio() {
    // let audio = new Audio();
    // audio.src = 'assets/sound/split-folio.wav';
    // audio.load();
    // await audio.play();
  }

  filterData(data = [], term = '', searchKey = '') {
    if (term) {
      data = data.filter(
        (elm) =>
          String(elm[searchKey]).indexOf(term) >= 0 ||
          String(elm[searchKey])
            .toLocaleLowerCase()
            .indexOf(String(term).toLocaleLowerCase()) >= 0
      );
    } else {
      data = data;
    }
    return data;
  }

  deleteNull(data: {}) {
    let formatted = {};
    Object.keys(data).forEach((elm) => {
      if (!data[elm]) delete data[elm];
      else formatted[elm] = data[elm];
    });

    return formatted;
  }

  async openModal(modalOptions: ModalOptions) {
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    return modal;
  }

  closeModal(data?: any, role?: string, id?: string) {
    this.modalController.dismiss(data, role, id);
  }

  async openActionSheet(config: ActionSheetOptions) {
    const actionSheet = await this.actionSheetController.create(config);
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    return role;
  }

  async openAlert(config: AlertOptions) {
    const alert = await this.alertController.create(config);

    await alert.present();
    return alert;
  }

  async openPopOver(
    event,
    component,
    props = {},
    popoverOptions?: Partial<PopoverOptions>
  ) {
    const popover = await this.popoverController.create({
      component: component,
      componentProps: props,
      event: event,
      cssClass: 'pop-over-clazz',
      translucent: true,
      ...popoverOptions,
    });
    await popover.present();

    return await popover.onDidDismiss();
  }

  closePopover(data?: any) {
    this.popoverController.dismiss(data);
  }

  getError(cName: string, form: UntypedFormGroup | AbstractControl) {
    return form.get(cName)?.hasError('required') && form.get(cName)?.touched;
  }

  async openLockModal(open = false) {
    if (
      (localStorage.getItem('isLocked') == '1' || open) &&
      !document.getElementById('pin-lock-modal')
    ) {
      localStorage.setItem('isLocked', '1');
      return await this.openModal({
        component: OrwiLockModalComponent,
        backdropDismiss: false,
        cssClass: 'lock-modal',
        id: 'pin-lock-modal',
      });
    } else {
      return undefined;
    }
  }

  getExchangeRates() {
    this.http
      .get('https://finans.truncgil.com/today.json')
      .toPromise()
      .then((ex) => {
        let rates = Object.assign(ex);

        let usd: Exchange = {
          id: 'usd',
          rate: parseFloat(rates.USD['Satış'].replace(',', '.')),
          isBase: false,
          name: 'USD',
          symbol: '$',
        };
        let eur: Exchange = {
          id: 'eur',
          rate: parseFloat(rates.EUR['Satış'].replace(',', '.')),
          isBase: false,
          name: 'EUR',
          symbol: '€',
        };
        let gbp: Exchange = {
          id: 'gbp',
          rate: parseFloat(rates.GBP['Satış'].replace(',', '.')),
          isBase: false,
          name: 'GBP',
          symbol: '£',
        };
        let tl: Exchange = {
          id: 'tl',
          rate: 1,
          isBase: true,
          name: 'TL',
          symbol: '₺',
        };

        this.exchangeRates = [usd, eur, gbp, tl];

        //this.globalStore.update({ exhanges: exs })
      });
  }

  navigateFolioForm(_replaceUrl = false) {
    if (this.plt.width() <= 1024) {
      this.nav.navigateForward('pos-home/phone-folio', {
        replaceUrl: _replaceUrl,
      });
    } else {
      this.nav.navigateForward('pos-home/pos-folio', {
        replaceUrl: _replaceUrl,
      });
    }
  }

  navigateHomeForm(_replaceUrl = false) {
    if (this.plt.width() <= 1024) {
      return this.nav.navigateRoot('pos-home', { replaceUrl: _replaceUrl });
    } else {
      return this.nav.navigateRoot('pos-home', { replaceUrl: _replaceUrl });
    }
  }




  sendSms(gsm, text): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          apiKey: '1453',
        }),
      };
      this.http
        .post(
          `${this.smartyUrl}/api/sms/sendSms`,
          { gsm: gsm, text: text },
          httpOptions
        )
        .subscribe(
          (o) => {
            console.log(o);
            resolve(o);
          },
          (e) => {
            console.log(e);
            reject(e.error.message);
          }
        );
    });
  }

  sendEmail(name, mail, subject, text): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          apiKey: '1453',
        }),
      };
      this.http
        .post(
          `${this.smartyUrl}/api/mail/sendBasicMail`,
          { personName: name, personMail: mail, subject: subject, text: text },
          httpOptions
        )
        .subscribe(
          (o) => {
            console.log(o);
            resolve(o);
          },
          (e) => {
            console.log(e);
            reject(e.error.message);
          }
        );
    });
  }

  exportToExcel(jsons: any[][] = [[]], title = 'report') {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    jsons.forEach((json, index) => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
        cellStyles: true,
      });
      for (const [key, cell] of Object.entries(ws)) {
        if (cell.t === 'n') {
          ws[key].z = '"₺"#,##0.00_);\\("₺"#,##0.00\\)';
        }
      }
      const widthArray = [];
      for (
        let wchCount = 0;
        wchCount < Object.keys(json[0]).length;
        wchCount++
      ) {
        widthArray.push({ wch: 15 });
      }
      ws['!cols'] = widthArray;
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet ' + index);
    });

    XLSX.writeFile(wb, title + '.xlsx');
  }

  keyboardNavigation(direction) {
    return {
      enabled: true,
      enterKeyAction: this.enterKeyAction,
      enterKeyDirection: direction,
      editOnKeyPress: true,
    };
  }


  removeHtmlTags(htmlText: string) {
    console.log(htmlText);
    htmlText = this.replaceAll(String(htmlText), '</b>', '');
    htmlText = this.replaceAll(String(htmlText), '<b>', '');
    return htmlText;
  }

  setUIBackButtonAction(backButton, changedRows) {
    backButton.onClick = () => {
      if (changedRows.length > 0) {
        this.openAlert({
          header: this.translocoService.translate('Reset'),
          subHeader: this.translocoService.translate(
            'All your edited stock will be reset. Do you wish to continue?'
          ),
          backdropDismiss: false,
          buttons: [
            {
              text: this.translocoService.translate('Yes'),
              handler: () => {
                this.nav.pop();
              },
            },
            {
              text: this.translocoService.translate('No'),
            },
          ],
        });
      } else {
        this.nav.pop();
      }
    };
  }

  changeMuhTranslation() {
    return [
      {
        id: 0,
        label: this.translocoService.translate('Material'),
      },
      {
        id: 1,
        label: this.translocoService.translate('Expense'),
      },
    ];
  }

  invoiceTypes(filter: number[] = []) {
    return [
      {
        value: 0,
        display: this.translocoService.translate('Purchase Invoice'),
      },
      {
        value: 1,
        display: this.translocoService.translate('Sales Invoice'),
      },
      {
        value: 2,
        display: this.translocoService.translate('Purchase Dispatch'),
      },
      {
        value: 3,
        display: this.translocoService.translate('Sales Dispatch'),
      },
    ].filter((elm) => (filter.length > 0 ? filter.includes(elm.value) : true));
  }

  recordTypes() {
    return [
      {
        value: 0,
        display: this.translocoService.translate('Open'),
      },
      {
        value: 1,
        display: this.translocoService.translate('Closed'),
      },
      {
        value: 2,
        display: this.translocoService.translate('Refund Open'),
      },
      {
        value: 3,
        display: this.translocoService.translate('Refund Close'),
      },
    ];
  }

  getBanks() {
    return this.banks;
  }

  async showCustomerName() {
    return new Promise<any>(async (resolve, reject) => {
      const inputAlert = await this.openAlert({
        header: this.translocoService.translate('Enter Customer Name'),
        inputs: [
          {
            type: 'text',
            name: 'customerName',
          },
        ],
        buttons: [
          {
            text: this.translocoService.translate('Cancel'),
            handler: () => {
              this.navigateHomeForm();
            },
          },
          {
            text: this.translocoService.translate('Done'),
            handler: (input) => {
              if (!input.customerName) {
                this.toast(
                  '',
                  'Please Enter Customer Name',
                  'bottom',
                  'danger'
                );
                return false;
              } else {
                return input.customerName;
              }
            },
          },
        ],
      });
      await inputAlert.present();

      inputAlert.onDidDismiss().then(({ data }) => {
        console.log(data?.values);
        if (data?.values?.customerName)
          return resolve(data.values.customerName);
        reject(false);
      });
    });
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  modalService = inject(ModalService);
  invoiceService = inject(InvoiceService);
  backOfficeSharedService = inject(BackOfficeSharedService);
  globalService = inject(GlobalService);
  async barcodeSplitter(
    barcodeString: string,
    type: 'like' | 'exact' = 'exact'
  ): Promise<
    {
      barcode: string;
      quantity: number;
      unit: string;
      text?: string;
    } & Partial<InventorySelectItem>
  > {
    // '0002827290053'
    // birim 28
    // barkod 2729
    // adet 5

    // '0028110210003'
    // birim 28
    // barkod 1102
    // adet 1000
    let sendedBarcode = barcodeString;
    let units = [];
    if (
      barcodeString.length == 13 &&
      ['27', '28'].some((el) => barcodeString.startsWith(el))
    ) {
      sendedBarcode = barcodeString.slice(2, 7);
      units = await this.backOfficeSharedService.getInventoryUnits();
    }

    const products: any = await this.invoiceService.getInventorySelectListForBarcode(
      sendedBarcode,
      type // like: 4 exact: 6 numara
    );

    products.forEach((product) => {
      let quantity = 1;
      let unit = units.find((el) => el.unitCode == product.unitName);
      let parentUnit = units.find(
        (el) =>
          el.unitCode == unit?.parentUnitCode || el.unitCode == unit?.unitCode
      );
      if (parentUnit) {
        if (parentUnit.isPonderable) {
          quantity = +(Number(barcodeString.slice(7, -1)) / 1000).toFixed(2);
        } else {
          quantity = Number(barcodeString.slice(7, -1));
        }
      }

      product.quantity = quantity;
    });

    if (products.length > 0) {
      if (products.length == 1) {
        let product = products[0];
        return {
          barcode: product.barcode,
          quantity: product.quantity,
          unit: product.unitName,
          text: product.inventoryName,
          ...product,
        };
      }
      this.globalService.closeLoading();
      const modal = await this.modalService.openModal({
        component: OrwiBarcodeListModalComponent,
        componentProps: {
          barcodes: products.map((el) => ({
            ...el,
            text: el.inventoryName,
          })),
        },
      });

      const barcodeResponse = await (await modal.onDidDismiss()).data;
      if (!barcodeResponse) {
        return {
          barcode: 'not-selected',
          quantity: undefined,
          unit: undefined,
        };
      }
      return {
        ...barcodeResponse,
      };
    } else {
      return {
        barcode: barcodeString,
        quantity: undefined,
        unit: undefined,
      };
    }
  }
}

export interface Exchange {
  id: string;
  symbol: string;
  name: string;
  rate: number;
  isBase: boolean;
}
