<div
  class="mh-50"
  style="height: 100%; padding: 10px; background: white; padding-top: 0px"
>
  <div
    style="
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-left: 5px;
    "
  >
    <div style="font-size: 12px; color: #171e4e">
      <!-- <div style="display: flex">
          <div>{{'#'}}</div>
          <div>{{(paymentQuery.activeFolio$ | async).externalId || ""}}</div>
        </div> -->

      <div style="display: flex">
        <div>
          {{ (paymentQuery.activeFolio$ | async)?.type | transloco }}/{{
            (paymentQuery.activeFolio$ | async)?.table.name
          }}
        </div>
      </div>

      <div style="display: flex">
        <div></div>
        <div>
          {{
            (paymentQuery.activeFolio$ | async)?.creation
              | date: "dd-MM-yy HH:mm"
          }}
        </div>
      </div>

      <div style="display: flex">
        <div>{{ "Pax" | transloco}}&nbsp;</div>
        <div>{{ (paymentQuery.activeFolio$ | async)?.pax }}</div>
      </div>
      <div
      style="display: flex"
      class="deliver-folio-text"
      *ngIf="(paymentQuery.activeFolio$ | async)?.source == 'food-app'"
    >
      <div class="info">
        {{ (paymentQuery.activeFolio$ | async)?.deliveryPaymentType }}
      </div>
    </div>
    </div>

    <div>
      <ion-button
        style="--box-shadow: 0px 5px 15px #f0f2fe; --background: none"
        (click)="openPrinters()"
      >
        <div>
          <ion-icon
            style="font-size: 18px; margin-bottom: 3px; color: #184eae"
            name="receipt-outline"
          ></ion-icon>
          <div style="font-size: 12px; text-transform: none; color: #171e4e">
            {{ "Print" | transloco }}
          </div>
        </div>
      </ion-button>

      <ion-button
        style="--box-shadow: 0px 5px 15px #f0f2fe; --background: none"
        (click)="split()"
        [disabled]="selectedFolioRowLength == 0"
      >
        <div>
          <ion-icon
            style="font-size: 18px; margin-bottom: 3px; color: #184eae"
            name="duplicate-outline"
          ></ion-icon>
          <div style="font-size: 12px; text-transform: none; color: #171e4e">
            {{ "Split" | transloco }}
          </div>
        </div>
      </ion-button>
    </div>
  </div>

  <div style="display: flex; align-items: center; flex-wrap: wrap">
    <div
      style="position: relative; margin-left: 2px"
      *ngFor="let folio of paymentQuery.folios$ | async as list; let i = index"
    >
      <ion-button
        style="width: 55px"
        class="animate__animated animate__bounceIn"
        [ngStyle]="{
          '--background':
            (paymentQuery.activeFolioId$ | async) == folio.id
              ? '#184EAE'
              : '#171E4E'
        }"
        (click)="selectFolio(folio.id)"
      >
        {{ i }}
      </ion-button>
      <div
        class="animate__animated animate__bounceIn"
        style="
          position: absolute;
          top: -3;
          right: 3px;
          font-size: 20px;
          top: 4px;
          color: white;
        "
        *ngIf="paymentQuery.folioIsDone(folio.id)"
      >
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
    </div>
  </div>

  <ion-grid [fixed]="true" id="row-cover">
    <ion-row
      style="
        background: #184eae;
        color: #fff;
        border-radius: 6px;
        align-items: center;
      "
    >
      <ion-col size="1"> # </ion-col>
      <ion-col size="4">
        {{ "Item Name" | transloco }}
      </ion-col>
      <ion-col size="2" style="text-align: right">
        {{ "Qty" | transloco }}
      </ion-col>
      <ion-col style="text-align: right">
        {{ "U.Price" | transloco }}
      </ion-col>
      <ion-col style="text-align: right">
        {{ "Total" | transloco }}
      </ion-col>
      <ion-col style="text-align: right">
        {{ "Actions" | transloco }}
      </ion-col>
    </ion-row>
    <ion-grid
      id="fd"
      class="folio-detail"
      style="overflow: scroll; padding: 0px"
    >
      <ion-row
        style="
          border: #cbcdde 1px solid;
          border-radius: 8px;
          margin-top: 10px;
          color: #171e4e;
          align-items: center;
          cursor: pointer;
        "
        *ngFor="let item of getFolio()"
        nowrap
      >
        <ion-col 
        (click)="select(item)" size="auto" style="padding-top: 8px">
          <ion-icon
            style="color: #184eae"
            *ngIf="!item.selected"
            name="ellipse-outline"
          ></ion-icon>
          <ion-icon
            style="color: #184eae"
            *ngIf="item.selected"
            name="ellipse"
          ></ion-icon>
        </ion-col>
        <ion-col size="4" (click)="select(item)">
          {{ item.name }}
          <div
            *ngTemplateOutlet="
          tplModifier;
          context: {
            parentID: item.id,
          }
        "
          ></div>
        </ion-col>
        <ion-col size="2" style="text-align: right">
          <ion-input
            type="number"
            disabled="true"
            style="padding: 0px; --highlight-color: none; opacity: 1"
            [value]="item.qty"
            (ionChange)="qtyChange($event, item)"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          ></ion-input>
        </ion-col>
        <ion-col style="text-align: right">
          {{ item.unitPrice | orwiCurrency }}
        </ion-col>
        <ion-col style="text-align: right">
          {{ item | folioRowModifierTotal | orwiCurrency }}
        </ion-col>
        <ion-col size="2">
          <div style="display: flex; align-items: center; flex-wrap: nowrap">
              <ion-button
                class="qty-btn"
                size="small"
                (click)="decrease(item.id)"
                fill="clear"
                [disabled]="quantityTracker[item.id] == 1"
              >
                <ion-icon style="color: #184eae" slot="icon-only" name="remove-circle-outline"></ion-icon>
              </ion-button>
              <ion-text style="user-select:none"> {{ quantityTracker[item.id] }} </ion-text>
              <ion-button
                class="qty-btn"
                size="small"
                (click)="increase(item.id, item)"
                fill="clear"
                [disabled]="quantityTracker[item.id] == item.qty"
              >
                <ion-icon style="color: #184eae" slot="icon-only" name="add-circle-outline"></ion-icon>
              </ion-button>
            </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>
  <!--
    <div id="row-cover">
        <div *ngIf="(paymentQuery.folios$ | async).length > 0">
            <div class="payment-folio-row" style="font-weight: 600; font-size: 10px;">
                <div style="width: 40px"></div>

                <div style="flex-grow: 2; margin-left: 10px">
                    {{'Item Name' |transloco}}
                </div>

                <div sstyle="width: 40px; text-align: right;">
                    {{'Qty' |transloco}}
                </div>

                <div style="width: 90px; text-align: right;">
                    {{'Unit Price' |transloco}}
                </div>

                <div style="width: 90px; text-align: right;">
                    {{'Total' |transloco}}
                </div>
            </div>

            <div [ngStyle]="{ 'background-color': !getFolioRow(item.id)?.valid ? 'rgb(242 62 62 / 63%)' : '' }" class="payment-folio-row" style="white-space: nowrap" *ngFor="let item of getFolio()" (click)="select(item)">
                <div style="font-size: 20px ; margin-right: 10px;">
                    <ion-icon *ngIf="!item.selected" name="ellipse-outline"></ion-icon>
                    <ion-icon *ngIf="item.selected" name="ellipse"></ion-icon>
                </div>

                <div class="item-name">{{item.name}}</div>

                <div style="width: 40px; text-align: right">
                    <ion-input [value]="item.qty" (ionChange)="qtyChange($event,item)" (click)="$event.preventDefault(); $event.stopPropagation()"></ion-input>
                </div>

                <div style="width: 90px; text-align: right">
                    {{item.unitPrice | orwiCurrency}}
                </div>

                <div style="width: 90px; text-align: right">
                    {{item | folioRowTotal | orwiCurrency}}
                </div>
            </div>
        </div>
    </div> -->
</div>
<!-- MODIFIER TEMPLATE -->
<ng-template #tplModifier let-parentId="parentID" let-isSelected="isSelected">
  <!-- <ng-container *ngIf="isSelected"> -->
  <div *ngFor="let item of folioQuery.mustModifiers(parentId)">
    <!-- <div *ngIf="item.qtyID !== oldID;" class="modifier-qtID">
      {{showID(item.qtyID)}}
    </div> -->

    <!-- <div style="margin-left: 10px; font-size: 12px">
      <div style=" display: flex; justify-content: space-between;  align-items: center;">
        <div style="width: 100%; padding-left: 5px"
          [ngStyle]="{'text-decoration': item.isDetachableModifier ? 'line-through' : 'unset'}">
          <ion-icon [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }" name="return-down-forward-outline">
          </ion-icon>&nbsp;{{ item.name }}
        </div>

      </div>
      <div style="margin-left: 10px" *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div>
    </div> -->
  </div>

  <div
    style="height: 5px"
    *ngIf="
      folioQuery.modifiers(parentId).length > 0 &&
      folioQuery.mustModifiers(parentId).length > 0
    "
  ></div>

  <div *ngFor="let item of folioQuery.modifiers(parentId)">
    <!-- <div *ngIf="item.qtyID !== oldID;" class="modifier-qtID">
      {{showID(item.qtyID)}}
    </div> -->

    <div style="font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          style="width: 100%"
          [ngStyle]="{
            'text-decoration': item.isDetachableModifier
              ? 'line-through'
              : 'unset'
          }"
        >
          <ion-icon
            [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }"
            name="return-down-forward-outline"
          >
          </ion-icon
          >&nbsp;{{ item.name }}
        </div>
        <div *ngIf="item.price > 0 && !item.isGift">
          {{ item.price | orwiCurrency }}
        </div>
        <div *ngIf="item.isGift">
          {{ 0 | orwiCurrency }}
        </div>
      </div>
      <div
        style="margin-left: 10px"
        *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"
      ></div>
    </div>
  </div>

  <!-- flio note start -->
  <!-- <div *ngFor="let item of folioQuery.notes(parentId)" style="margin-top: 5px">
    <div style="margin-left: 10px; font-size: 12px">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div style="width: 100%; padding-left: 5px; font-weight: lighter">
          <ion-icon style="color: rgb(28, 167, 1); font-weight: 600" name="document-text-outline"></ion-icon>
          &nbsp;
          <span style="color: rgb(28, 167, 1); font-weight: 600">{{
            item.name
            }}</span>
        </div>
        <div class="ion-activatable ripple-parent">
          <ion-icon name="trash-outline" style="
              color: rgb(223, 50, 116);
              margin-right: 15px;
              transform: scale(1.5);
              cursor: pointer;
            "></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </div>


      </div>
    </div>
  </div> -->
  <!-- Folio note end-->
  <!-- </ng-container> -->
</ng-template>
