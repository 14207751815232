<div class="modalContainer" *ngIf="this.content == 'form'">
  <ion-grid
    [formGroup]="taxForm"
    style="width: 100%"
    *transloco="let t"
    class="cover_container"
  >
    <ion-row>
      <ion-col size="12">
        <ion-card-title style="padding-left: 35px">{{
          "Tax Definition" | transloco
        }}</ion-card-title>
      </ion-col>

      <!-- <div class="inputDepartment">
          <ion-item class="" lines="none">
            <ion-label position="floating">{{t('tax rate')}} </ion-label>
            <ion-input formControlName="rate" type="number"></ion-input>
          </ion-item>

        </div> -->
      <!-- <div>
        </div> -->
    </ion-row>
    <ion-row style="padding-left: min(36px, 5%)">
      <ion-col size="12" sizeMd="9">
        <ion-item class="input-item" lines="none">
          <ion-input
            formControlName="rate"
            type="number"
            labelPlacement="floating"
            [errorText]="
              taxForm.get('rate').hasError('isSameRate') &&
              taxForm.get('rate').touched
                ? ('same rate registration is available' | transloco)
                : null
            "
            [label]="t('tax rate')"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="status-toggle" size="12" sizeMd="3">
        <ion-item class="ion-no-padding" lines="none">
          <ion-toggle formControlName="status" mode="ios" color="secondary">
            {{ t("status") }}
          </ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
    <footer>
      <ion-buttons style="width: 94.5%">
        <ion-button
          size="small"
          (click)="this.content = 'list'"
          class="header_giveUp"
        >
          {{ "GiveUp" | transloco }}
        </ion-button>
        <ion-button
          size="small"
          (click)="save()"
          [disabled]="taxForm.invalid"
          class="header_complete"
        >
          {{ "Complete" | transloco }}
        </ion-button>
      </ion-buttons>
    </footer>
  </ion-grid>
</div>
<!-- <ion-col class="d-flex">
            <ion-row class="definition-container">
              <ion-col size="4" class="info-col">
                <div class="bkm-content">
                  <b>{{ department.name }}</b>
                </div>
              </ion-col>
              <ion-col size="4" class="info-col">
                <div class="bkm-content">
                  <b>{{ "Sequence" | transloco }} </b>
                  <p>{{ department.sequence }}</p>
                </div>
              </ion-col>
              <ion-col size="4" class="info-col">
                <div class="bkm-content" *transloco="let t">
                  <b> {{ "Rate" | transloco }} </b>
                  <p>{{ department.rate }}</p>
                </div>
              </ion-col>
              <ion-col size="1" class="ion-align-items-center">
                <ion-buttons>
                  <ion-button
                    class="cancel-btn"
                    (click)="deleteDepartment(department.id)"
                    >{{ "Delete" | transloco }}</ion-button
                  >
                </ion-buttons>
              </ion-col> -->

<ion-row *ngIf="this.content == 'list'" class="list-container">
  <ion-card-title>{{ "Tax Definitions" | transloco }}</ion-card-title>
  <ion-col size="12" *ngFor="let tax of taxList">
    <ion-card class="ion-no-margin">
      <ion-card-content>
        <ion-row class="definition-container">
          <ion-col class="d-flex">
            <ion-row class="definition-container">
              <ion-col size="auto" class="info-col">
                <div class="bkm-content">
                  <b> {{ "Rate" | transloco }} </b>
                  <p>{{ tax.rate }}</p>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="auto" class="status-col">
            <ion-toggle
              [checked]="tax.status"
              disabled="true"
              mode="ios"
              color="secondary">
            </ion-toggle>
          </ion-col>
          <ion-col size="auto" class="ion-align-items-center">
            <!-- <ion-row
              class="ion-align-items-center ion-justify-content-end w-100"
            > -->
            <ion-buttons>
              <ion-button
                class="cancel-btn"
                [disabled]="canDelete(tax.rate)"
                (click)="deleteTaxRate(tax)"
                >{{ "Delete" | transloco }}</ion-button
              >
            </ion-buttons>
            <!-- </ion-row> -->
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-col>
  <div class="add-btn">
    <ion-button (click)="openForm()">{{ "Add New" | transloco }}</ion-button>
  </div>
</ion-row>
