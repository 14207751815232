import { Injectable } from '@angular/core';
import {
  arrayAdd,
  arrayRemove,
  arrayUpdate,
  arrayUpsert,
} from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import * as localforage from 'localforage'; // this works!!!
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { OrwiPromptService } from 'src/app/components/ui/orwi-prompt/orwi-prompt.service';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { MenuQuery } from 'src/app/modules/menu/state/menu.query';
import {
  Deleters,
  deliveryFolio,
  Folio,
  FolioLog,
  FolioRow,
  rowType,
  ServiceType,
} from 'src/app/services/dto/orwi-folio';
import { Product } from 'src/app/services/dto/orwi-product';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { DeleteProductFolioComponent } from '../components/delete-product-folio/delete-product-folio.component';
import { PaxComponent } from '../components/pax/pax.component';
import { FolioRowListItem } from '../components/product-transfer/models/product-transfer.models';
import { FolioQuery } from './folio.query';
import { FolioStore } from './folio.store';
import { Router } from '@angular/router';
import { HapticService } from 'src/app/services/helpers/haptic.service';
import { GitReasonsComponent } from '../components/git-reasons/git-reasons.component';
import { paymentTypes } from 'src/app/services/dto/orwi-store';
import { SessionQuery } from '../../session/state/session.query';
import { SSOSessionService } from '../../session/state/sso-session.service';
import { ParametersQuery } from '../../parameters/state/parameters.query';
import { BrandsStore } from '../../brands/state/brands.store';

@Injectable({ providedIn: 'root' })
export abstract class FolioService {
  constructor(
    private modifierService: ModifiersService,
    private orwiService: OrwiService,
    private menuQuery: MenuQuery,
    private idGen: IdGeneratorService,
    private folioQuery: FolioQuery,
    private folioStore: FolioStore,
    private op: OrwiPromptService,
    private orwiStore: OrwiStoreStore,
    private numpadService: NumpadService,
    private appService: AppService,
    private modalService: ModalService,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private router: Router,
    private hs: HapticService,
    private sessionQuery: SessionQuery,
    private ssoService: SSOSessionService,
    private paramQuery: ParametersQuery,
    private brandStore: BrandsStore
  ) {}

  productSub: Subscription;

  async loadOpenFolios() {
    let openFolios = await this._posGetOpenFolios(this.orwiStore.getValue().id);
    this.folioStore.update({
      openFolios: openFolios.filter((x) => x.deliveryStatus != 'cancelled'),
      activeFolio: new Folio(),
      // openFolios.filter((x) => x.deliveryStatus != 'cancelled')?.length > 0
      //   ? openFolios.filter((x) => x.deliveryStatus != 'cancelled')[0]
      //   : new Folio(),
    });
  }

  async initialize() {
    this.loadOpenFolios();

    if (this.productSub) {
      return;
    }

    this.productSub = this.menuQuery.selectedProduct$.subscribe((o) => {
      if (o) {
        this.insertFolioRow(o.product);
        this.modifierService.modifiers(true);
      }
    });

    // this.folioQuery.openFolios$.subscribe((o) => {
    //   localforage.setItem('OpenFolios', o);
    // });

    // this.folioQuery.closedFolio$.subscribe((o) => {
    //   localforage.setItem('OpenFolios', o);
    // });

    this.appService.connection$.subscribe((o) => {
      if (o !== 'offline') {
        this._posImportFolios();
      }
    });

    this.appService.stateChanged$.subscribe(async (o) => {
      if (o) {
        if (this.router.url == '/phone-folio') return;

        // this.glb.showLoading();

        // let id = undefined;
        // if (this.folioStore.getValue().activeFolio)
        //   id = this.folioStore.getValue().activeFolio.id;

        // let _openFolios = await this._posGetOpenFolios(
        //   this.orwiStore.getValue().id
        // );

        // let _activeFolio: Folio;

        // if (id) _activeFolio = _openFolios.find((o) => o.id == id);

        // this.folioStore.update({
        //   openFolios: _openFolios,
        //   activeFolio: _activeFolio,
        // });

        // this.glb.closeLoading();
      }
    });
  }

  async moveProduct(row: FolioRowListItem[], oldFoli: Folio, e: Folio) {
    let oldRows: FolioRow[] = [];
    let targetRows: FolioRow[] = [];

    row.forEach((el) => {
      const qty = +el.folioRow.qty - +el.qty;
      const modifers = this.folioStore
        .getValue()
        .activeFolio.rows.filter((ele) => ele.parentID === el.folioRow.id);

      if (el.slected) {
        if (qty > 0) {
          oldRows.push({
            ...el.folioRow,
            qty: qty,
            price: qty * el.folioRow.unitPrice,
          } as FolioRow);
          modifers.forEach((m) =>
            oldRows.push({
              ...m,
              qty: qty,
              price: qty * m.unitPrice,
            } as any)
          );
        }

        if (qty === 0) {
          oldRows.push({ ...el.folioRow, recordStatus: 'deleted' } as FolioRow);
        }

        el.folioRow.recordStatus = 'deleted';
        el.folioRow.deleters.push(this.createDeleterLog(el.qty));
        oldRows.push({
          ...el.folioRow,
          id: this.idGen.generate(),
          qty: el.qty,
          price: el.qty * el.folioRow.unitPrice,
        } as FolioRow);
        modifers.forEach((x) => {
          x.deleters.push(this.createDeleterLog(el.qty));
          x.recordStatus = 'deleted';
          oldRows.push({
            ...x,
            qty: el.qty,
            price: el.qty * x.unitPrice,
          } as FolioRow);
        });
      } else {
        oldRows.push({ ...el.folioRow } as FolioRow);
        modifers.forEach((m) => oldRows.push(m));
      }

      if (el.slected && el.valid) {
        const id = this.idGen.generate();
        el.folioRow.recordStatus = 'old';
        el.folioRow.deleters = [];
        targetRows.push({
          ...el.folioRow,
          id: id,
          qty: el.qty,
          price: el.qty * el.folioRow.unitPrice,
        } as FolioRow);

        modifers.forEach((m) => {
          targetRows.push({
            ...m,
            parentID: id,
            id: this.idGen.generate(),
            recordStatus: 'old',
            deleters: [],
            qty: el.qty,
            price: el.qty * m.unitPrice,
          } as FolioRow);
        });
      }
    });

    oldFoli.rows = oldRows;
    console.log('OLD ROWS', oldFoli.rows, 'TARGET ROW', targetRows);
    await this._posSaveFolio(oldFoli);
    e.rows = [...e.rows, ...targetRows];

    const logObject = this.createLogObject(e, oldFoli);
    await this.saveFolioLog(logObject);
    await this._posSaveFolio(e);

    this.glb.toast(
      'Product Migration Process Completed.',
      '',
      'bottom',
      'success'
    );
  }

  private createDeleterLog(qty: number) {
    return {
      qty: qty,
      reason: 'transfer',
      reasonID: 'transfer',
      time: new Date(),
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
    };
  }

  private createLogObject(e: Folio, oldFoli: Folio) {
    return {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: e.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'change-table',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: '',
      infoType: 'information',
      description: `${this.transloco.translate('Change Table')} ${
        oldFoli.sequenceNo
      } > ${e.sequenceNo}`,
    };
  }

  async moveFolio(table: { id: string; name: string }, folio: Folio) {
    if (!this.ssoService.checkPermission('folio-change-table')) {
      this.glb.permissionToast();
      return;
    }
    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
      descripton: 'closed-move',
    });

    folio.table.id = table.id;
    folio.table.name = table.name;

    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
      descripton: 'moved',
    });
    await this.updateActiveFolio(folio);
  }

  async combineFolio(
    table: { id: string; name: string },
    combinedfolio: Folio,
    folio: Folio
  ) {
    if (!this.ssoService.checkPermission('folio-combine')) {
      this.glb.permissionToast();
      return;
    } else {
      this.glb.showLoading();
      combinedfolio.updaters.push({
        userID: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        time: new Date(),
        descripton: 'closed-combine',
      });

      let stringRows = JSON.stringify(
        combinedfolio.rows.filter((x) => x.recordStatus !== 'deleted')
      );
      let targetRow: FolioRow[] = JSON.parse(stringRows);

      folio.rows = [...folio.rows.filter((x) => x.recordStatus !== 'deleted')];
      targetRow.map((el) => {
        const pId = this.idGen.generate();
        targetRow
          .filter((x) => x.parentID === el.id)
          .map((subRow) => {
            subRow.parentID = pId;
          });
        el.id = pId;
        folio.rows.push(el);
      });
      folio.table.id = table.id;
      folio.table.name = table.name;

      folio.updaters.push({
        userID: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        time: new Date(),
        descripton: 'combine',
      });
      await this._posSaveFolio(folio);
      // await this.updateActiveFolio(folio);
      // console.log('combine', combinedfolio.rows);

      combinedfolio.status = 'cancelled'; //!KONTROL EDILECEK
      combinedfolio.deliveryStatus = 'cancelled';
      combinedfolio.note = 'closed-combine';
      combinedfolio.rows.map((row) => {
        row.deleters.push({
          qty: row.qty,
          reason: 'deleted-combine',
          reasonID: 'deleted-combine',
          time: new Date(),
          userID: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
        });
        row.recordStatus = 'deleted';
        // row.id = this.idGen.generate()
      });
      // combinedfolio.rows.map(x=> x.folioId = combinedfolio.id)
      this.folioStore.update(({ openFolios }) => ({
        openFolios: arrayUpsert(openFolios, folio.id, folio),
      }));
      let logObject = {
        id: this.idGen.generate(),
        storeId: this.sessionQuery.activeLicense.orwiStore.id,
        folioId: combinedfolio.id,
        createDate: new Date(),
        logLevel: 'debug',
        actionType: 'combine-table',
        userId: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        data: '',
        infoType: 'information',
        description:
          this.transloco.translate('Combine Table') +
          `${combinedfolio.sequenceNo} > ${folio.sequenceNo}`,
      };
      await this.saveFolioLog(logObject);
      logObject.folioId = folio.id;
      await this.saveFolioLog(logObject);
      await this._posSaveFolio(combinedfolio);
      // await this._posCloseFolio(combinedfolio);
      await this.loadOpenFolios();
      this.glb.closeAllLoading();
    }
  }

  updateActiveFolio(folio: Folio, importance: 'low' | 'med' | 'high' = 'high') {
    // folio.lock.status = 'unlocked';
    // folio.lock.time = moment().toDate();
    // folio.lock.userID = this.sessionQuery.user.id;
    // folio.lock.userName = this.sessionQuery.user.name;
    this.folioStore.update({ activeFolio: folio, activeFolioRows: folio.rows });

    return this._posSaveFolio(
      this.folioStore.getValue().activeFolio,
      importance
    );
  }

  lockUnlockFolio(folioId: string, status: 'locked' | 'unlocked') {
    this.orwiService
      .serviceRequestPromise(
        '/api/pos/folio/setFolioLock',
        {
          id: folioId,
          storeId: this.sessionQuery.activeLicense.orwiStore.id,
          lockStatus: status,
        },
        this.sessionQuery.token
      )
      .then();
  }

  async insertFolioRow(o: Product) {
    let productPrice = o.price;
    if (
      this.folioStore.getValue().activeFolio.type == 'delivery' ||
      this.folioStore.getValue().activeFolio.type == 'take-away'
    ) {
      productPrice = o.dlPrice;
    }

    if (o) {
      const activeFolio = this.folioStore.getValue().activeFolio;
      const existingRow = activeFolio.rows.find((row) => row.itemID === o.id && row.recordStatus==='new');

      if (this.paramQuery.getValue().posParameters.saleMergeSameProducts && (existingRow && !activeFolio.rows.some(r=>r.parentID === existingRow.id))) {
        existingRow.qty += this.numpadService.getValue('qty');
        existingRow.price = existingRow.qty * existingRow.unitPrice
        existingRow.updaters.push({
          userID: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
          time: moment().toDate(),
        })
      } else {
      let row = this.createFolioRow(
        o.id,
        o.name,
        this.numpadService.getValue('qty'),
        '',
        productPrice,
        o.printer,
        o.ecrdepartment,
        o.vat
      );

      this.folioStore.update((p) => {
        p.activeFolio.rows.push(row);
      });

      this.folioStore.update({ changedRows: [row] });
      this.folioStore.update({ activeFolioRow: row });
      this.folioStore.update({ newRow: row });
      }

      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');
      await this.hs.hapticsImpactLight(100);
      await this.hs.hapticsImpactMedium(100);
    }
  }

  popProduct(productId: string) {
    const activeFolio: Folio = this.folioStore.getValue().activeFolio;

    if (!activeFolio || !activeFolio.rows || !activeFolio.rows.length) {
      return false;
    }

    for (let i = activeFolio.rows.length - 1; i > 0; i--) {
      const activeRow = activeFolio.rows[i];
      if (
        activeRow.rowType === 'modifier' ||
        activeRow.rowType === 'custom-modifier'
      ) {
        activeFolio.rows.pop();
      } else {
        break;
      }
    }

    if (productId !== activeFolio.rows[activeFolio.rows.length - 1].itemID) {
      return false;
    }

    activeFolio.rows.pop();

    this.folioStore.update({
      activeFolio: {
        ...activeFolio,
        rows: activeFolio.rows,
      },
    });
    return true;
  }

  createFolioRow(
    product_id,
    product_name,
    qty,
    parent_id,
    price,
    printer: string = '',
    ecrDepartment?: string,
    tax?: number,
    image?,
    isGift?: boolean
  ): FolioRow {
    isGift = isGift == undefined ? false : isGift == false ? false : true;
    let row: FolioRow = new FolioRow();
    row.creation = moment().toDate();
    row.id = this.idGen.generate();
    row.name = product_name;
    row.qty = qty;
    row.unitPrice = price;
    row.price = price * qty;
    row.printer = printer;
    row.itemID = product_id;
    (row.creator.userID = this.sessionQuery.user.id),
      (row.creator.userName = this.sessionQuery.user.name),
      (row.ecrDepartmentId = ecrDepartment),
      (row.tax = tax);

    row.itemImage = image;
    row.parentID = parent_id;
    if (product_id === 'note') {
      row.rowType = 'note';
      row.isModifier = true;
    }
    row.recordStatus = 'new';
    row.isGift = isGift;
    row.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });

    return row;
  }

  async createFolio(
    tableId: string,
    tableName: string,
    serviceType: ServiceType = 'table'
  ) {
    let folio = new Folio();
    folio.id = this.idGen.generateMaxi();
    folio.type = serviceType;

    folio.creation = new Date();
    folio.lastChange = new Date();
    folio.table.id = tableId;
    folio.pax = 0;
    folio.uuid = this.idGen.generateUuid();

    folio.table.name = tableName;
    if (serviceType === 'take-away') {
      folio.table.name = 'take-away-service';
    }

    folio.storeId = this.orwiStore.getValue().id;
    folio.total = 0;
    folio.userId = this.sessionQuery.user.id;
    folio.paxChild = 0;
    folio.paxFemale = 0;
    folio.paxMale = 0;
    folio.lock.status = 'unlocked';
    if (this.brandStore.getValue().brands.length > 0) {
      folio.brand = this.brandStore
        .getValue()
        .brands.find((x) => x.isDefault)?.id;
    }
    folio.lock.time = moment().toDate();
    folio.lock.userID = this.sessionQuery.user.id;
    folio.lock.userName = this.sessionQuery.user.name;
    folio.creator.userID = this.sessionQuery.user.id;
    folio.creator.userName = this.sessionQuery.user.name;
    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });

    this.folioStore.update({ activeFolio: folio });

    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayAdd(openFolios, folio),
    }));

    await this.hs.hapticsImpactLight(100);
    await this.hs.hapticsImpactLight(300);
    await this.hs.hapticsImpactMedium(100);

    return this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');
  }

  async createSelfFolio() {
    return new Promise(async (resolve) => {
      const folio = await this.createFolio('self', 'self', 'self');
      resolve(folio);
    });
  }

  async askDeleteSelectedRows() {
    const deleteModal = await this.modalService.openModal({
      component: DeleteProductFolioComponent,
      backdropDismiss: false,
      canDismiss: true,
    });

    deleteModal.onDidDismiss().then(({ data }) => {
      if (data?.reason) {
        this.deleteSelectedRows(data.reason);
      }
    });
  }

  getOldFolios(
    type: 'delivery' | 'all' | 'in-store',
    limit = 100
  ): Promise<deliveryFolio[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/folio/getUserFolios',
          { filter: type, limit, skip: 0 },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            this.glb.consolelog(o);
            if (o.response) {
              o.response.map((o) => {
                o['showDetail'] = false;
              });
              resolve(o.response);
            }
          },
          (e) => {
            e;
            //TODO error bass
            reject(false);
          }
        );
    });
  }

  async askGiftSelectedRows() {
    const activeFolioRows: FolioRow[] = this.folioStore.getValue().activeFolio
      .rows;
    const selectedRow: FolioRow = this.folioStore.getValue().selectedRow;
    if (selectedRow.isGift) {
      let ids = [];
      selectedRow.isGift = false;
      this.folioStore.getValue().activeFolio.rows.map((el) => {
        if (el.parentID === selectedRow.id) {
          el.isGift = false;
        }
      });
      for (const iterator of activeFolioRows) {
        ids.push(iterator.id);
      }
      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

      this.folioStore.update({ changedRows: activeFolioRows });
      this.folioStore.update(({ activeFolioRows }) => ({
        activeFolioRows: arrayUpdate(
          activeFolioRows,
          ids,
          activeFolioRows,
          'id'
        ),
      }));
      return;
    }
    const deleteModal = await this.modalService.openModal({
      component: GitReasonsComponent,
      backdropDismiss: false,
      canDismiss: true,
    });

    deleteModal.onDidDismiss().then(({ data }) => {
      if (data?.reason) {
        // this.deleteSelectedRows(data.reason);
        this.setGift(data.reason);
      }
    });
  }

  async setGift(reason: { reasonId: string; reasonName: string }) {
    let ids = [];
    let rows = this.folioStore
      .getValue()
      .activeFolioRows.filter((o) => o.selected);
    rows.push(this.folioStore.getValue().selectedRow);
    let subRows = this.folioStore
      .getValue()
      .activeFolio.rows.filter(
        (el) =>
          el.parentID == this.folioStore.getValue().selectedRow.id &&
          el.rowType === 'modifier'
      );
    rows.push(...subRows);

    rows.map((o) => {
      o.isGift = !o.isGift;
    });

    let otherId = this.idGen.generate();
    if (reason.reasonId == 'other') {
      reason.reasonId = otherId;
    }
    this.folioStore.getValue().selectedRow.deleters.push({
      reasonID: reason.reasonId,
      reason: reason.reasonName,
      userID: this.sessionQuery.user.id,
    } as Deleters);
    for (const iterator of rows) {
      ids.push(iterator.id);
    }

    this.folioStore.update({ selectedRow: null, activeFolioRow: null });

    await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

    this.folioStore.update({ changedRows: rows });
    this.folioStore.update(({ activeFolioRows }) => ({
      activeFolioRows: arrayUpdate(activeFolioRows, ids, rows, 'id'),
    }));
  }

  async deleteSelectedRows(reason: { reasonId: string; reasonName: string }) {
    let ids: string[] = [];
    const activeFolioRows: FolioRow[] = this.folioStore.getValue().activeFolio
      .rows;
    const selectedRow: FolioRow = this.folioStore.getValue().selectedRow;
    const rows = activeFolioRows.filter((o) => {
      const isDeleted: boolean = o.parentID == selectedRow.id;
      return o.selected || isDeleted;
    });

    rows.push(this.folioStore.getValue().selectedRow);
    let otherId = this.idGen.generate();
    if (reason.reasonId == 'other') {
      reason.reasonId = otherId;
    }
    this.folioStore.getValue().selectedRow.deleters.push({
      reasonID: reason.reasonId,
      reason: reason.reasonName,
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
    } as Deleters);

    selectedRow.printed = false;
    rows.map(
      (o) => (
        (o.recordStatus = 'deleted'),
        o.folioId != '' ? (o.printed = false) : (o.printed = true),
        (o.new = true)
      )
    );
    //  this.folioStore.getValue().selectedRow.printer = this.menuQuery.getValue().products.find(x => x.id == this.folioStore.getValue().selectedRow.itemID).printer || ""
    for (const iterator of rows) {
      ids.push(iterator.id);
    }

    this.folioStore.update({ selectedRow: null, activeFolioRow: null });
    console.log('active folio:', this.folioStore.getValue().activeFolio);
    let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.folioStore.getValue().activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'delete-product',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: '',
      infoType: 'information',
      description: this.transloco.translate('Product Delete'),
    };
    await this.saveFolioLog(logObject);
    await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

    this.folioStore.update({ changedRows: rows });

    this.folioStore.update(({ activeFolioRows }) => ({
      activeFolioRows: arrayUpdate(activeFolioRows, ids, rows, 'id'),
    }));
  }

  async deleteRowsQty(
    reason: { reasonId: string; reasonName: string },
    quantity: number
  ) {
    let ids: string[] = [];
    const activeFolioRows: FolioRow[] = this.folioStore.getValue().activeFolio
      .rows;
    const selectedRow: FolioRow = this.folioStore.getValue().selectedRow;
    const rows = activeFolioRows.filter((o) => {
      const isDeleted: boolean = o.parentID == selectedRow.id;
      return o.selected || isDeleted;
    });

    const newRow: FolioRow = {
      ...selectedRow,
      id: this.idGen.generate(),
      qty: selectedRow.qty - quantity,
      recordStatus: 'new',
      price: (selectedRow.qty - quantity) * selectedRow.unitPrice,
      selected: false,
      new: true,
      deleters: [],
      getRowTotal: function (): number {
        return (selectedRow.qty - quantity) * selectedRow.unitPrice;
      },
    };

    let modifiers = this.getModifiers(this.folioStore.getValue().activeFolio.rows, selectedRow.id);
    let folioRow = this.createFolioRow(
      selectedRow.id,
      selectedRow.name,
      quantity,
      '',
      selectedRow.unitPrice,
      selectedRow.printer,
      selectedRow.ecrDepartmentId,
      selectedRow.tax
    );
    this.getActiveFolio().rows.forEach((row) => {
      row.selected = false;
    });
    folioRow.selected = true;
    folioRow.folioId = selectedRow.folioId;
    let newModifiers = [];

    modifiers.forEach((modifier) => {
      console.log(modifier);
      let newModifier = this.createFolioRow(
        modifier.id,
        modifier.name,
        modifier.qty - quantity,
        newRow.id,
        modifier.unitPrice,
        modifier.printer,
        modifier.ecrDepartmentId
      );
      newModifier.rowType = modifier.rowType;
      newModifier.isModifier = modifier.isModifier;
      newModifier.isMustModifier = modifier.isMustModifier;
      newModifier.folioId = modifier.folioId;
      // newModifier.recordStatus = 'deleted'

      // if (localStorage.getItem('activeStore') == '050d-57752172364-f961') {
      newModifier.itemID = newModifier.id;
      modifier.qty = quantity;
      newModifiers.push(newModifier);
    });
    console.log('newModifiers', newModifiers)
    // newModifiers
    this.folioStore
    .getValue()
    .activeFolio?.rows.push(...newModifiers);

    selectedRow.qty = quantity;
    selectedRow.price = quantity * selectedRow.unitPrice;

    rows.push(selectedRow);

    let otherId = this.idGen.generate();
    if (reason.reasonId == 'other') {
      reason.reasonId = otherId;
    }

    selectedRow.deleters.push({
      reasonID: reason.reasonId,
      reason: reason.reasonName,
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
    } as Deleters);

    selectedRow.printed = false;

    rows.map((o) => {
      o.recordStatus = 'deleted';
      o.printed = o.folioId !== '' ? false : true;
      o.new = true;
    });
    rows.push(newRow);

    for (const iterator of rows) {
      ids.push(iterator.id);
    }
    console.log('ROWS', rows);

    let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.folioStore.getValue().activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'delete-product',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: JSON.stringify(selectedRow),
      infoType: 'information',
      description: this.transloco.translate('Product Delete'),
    };
    let activeFolio = this.folioStore.getValue().activeFolio
    // activeFolio.rows = rows
    activeFolio.rows.push(newRow)
    this.folioStore.update({activeFolio: activeFolio})
    this.folioStore.update({ selectedRow: null, activeFolioRow: null });
    this.folioStore.update({ changedRows: rows });
    this.folioStore.update({
      activeFolioRows: activeFolio.rows,
    });
    await this.saveFolioLog(logObject);
    await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');
    console.log('active folio:', this.folioStore.getValue().activeFolio);
  }

  getModifiers(row: FolioRow[], id: string): FolioRow[] {
    return row.filter((el) => el.parentID == id);
  }

  getActiveFolio() {
    return this.folioStore
      .getValue()
      .activeFolio;
  }

  async setPax() {
    const isPaxRequired = this.paramQuery.getValue().posParameters
      .globalParameters.paxSelectAutoShow;

    await this.glb.openModal({
      component: PaxComponent,
      cssClass: 'reason-modal',
      backdropDismiss: !isPaxRequired,
      componentProps: {
        isPaxRequired: isPaxRequired,
      },
    });
  }

  addNote() {
    let note: string = this.folioStore.getValue().activeFolio.note;
    let title = this.transloco.translate('Folio Note');
    let desc = this.transloco.translate('Please enter the folio note');
    if (this.folioStore.getValue().selectedRow) {
      title = this.transloco.translate('Product Note');
      desc = this.transloco.translate('Please enter the product note');
      note = this.folioStore.getValue().selectedRow.note;
      if (!this.ssoService.checkPermission('folio-add-note-product')) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('folio-add-note-old-product') &&
        this.folioStore.getValue().selectedRow.recordStatus === 'old'
      ) {
        this.glb.permissionToast();
        return;
      }
    } else {
      if (!this.ssoService.checkPermission('folio-add-folio-note')) {
        this.glb.permissionToast();
        return;
      }
    }

    let _op = this.op.showComponent({
      title: title,
      message: desc,
      inputs: [
        {
          placeholder: 'Note',
          type: 'textarea',
          id: 'note',
          value: note,
          required: false,
        },
      ],
    });

    _op.click.subscribe(async (o) => {
      // // console.log('note:',o);
      if (o.inputs === null || o.inputs === undefined) {
        _op.closeClick.emit();
        return;
      }
      let os = o.inputs.find((x) => x.id == 'note');

      // if (!os.value || os.value.toString().trim() === '') {
      //   this.glb.toast('Not', 'Lütfen not giriniz.', 'bottom', 'warning');
      //   return;
      // }
      if (this.folioStore.getValue().selectedRow) {
        if (!os.value || os.value.toString().trim() === '') {
          this.glb.toast(
            '',
            this.transloco.translate('Note area cannot be empty!'),
            'bottom',
            'warning'
          );
          return;
        }
        // if have a selected row we will add note to their rows

        let row = this.createFolioRow(
          'note',
          os.value,
          this.folioStore.getValue().selectedRow.qty,
          this.folioStore.getValue().selectedRow.id,
          0
        );

        this.folioStore.update(({ activeFolio }) => ({
          activeFolio: {
            ...activeFolio,
            rows: [...activeFolio.rows, row],
          },
        }));
      } else {
        // if dont select any folio this mean we must add note to folio note
        this.folioStore.update(({ activeFolio }) => ({
          activeFolio: {
            ...activeFolio,
            note: os.value ?? '',
          },
        }));
      }
      _op.closeClick.emit();
      let logObject = {
        id: this.idGen.generate(),
        storeId: this.sessionQuery.activeLicense.orwiStore.id,
        folioId: this.folioStore.getValue().activeFolio.id,
        createDate: new Date(),
        logLevel: 'debug',
        actionType: 'add-note',
        userId: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        data: '',
        infoType: 'information',
        description: os.value,
      };
      await this.saveFolioLog(logObject);
      // await this.saveActiveFolio();

      // // console.log('folioStore: ', this.folioStore.getValue() );
    });
  }
  async saveActiveFolio(): Promise<boolean> {
    try {
      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'high');
      return true;
    } catch (error) {
      return false;
    }
  }

  async checkFolioEftPos(folio?: Folio): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let paymenTotal: number =
          folio.rows
            .filter((fr) => fr.rowType === 'payment')
            .reduce((sum, item) => sum + item.unitPrice * item.qty, 0) || 0;
        paymenTotal = paymenTotal * -1;
        console.log('checkFolioEftPos', folio, paymenTotal);
        if (folio.rows.filter((x) => x.rowType === 'payment').length > 0) {
          if (paymenTotal === folio.grandTotal) {
            resolve('payment-done');
          }
        } else if (folio.eftPos) {
          resolve('sended-tsm');
        } else {
          resolve('');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteFolioLog(folioLog: FolioLog) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/deleteFolioLog',
          {
            id: folioLog.id,
            folioId: folioLog.folioId,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  saveFolioLog(folioLog) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/saveFolioLog',
          folioLog,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  getFolioLog(folioId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getFolioLogs',
          {
            id: folioId,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  __getFoliosByDate(date: Date = new Date()) {
    let start = moment(date).startOf('day');
    let end = moment(date).add(1, 'day').endOf('day');

    console.log(start, end);
    let param = {
      startDate: start,
      endDate: end,
      skip: 0,
      limit: 10000,
      id: this.orwiStore.getValue().id,
    };

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getAllFoliosByDate',
          param,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }
  __getFoliosByStartAndEndDate(
    { start, end }: any,
    storeId = this.orwiStore.getValue().id
  ) {
    start = moment(
      moment(start).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.openTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // start = await this.paramQuery.startDate(start);
    //  let eDate = moment(this.dateFilterForm.value.endDate).endOf("day").utc(true).toISOString();
    end = moment(
      moment(end).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.closeTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // end = await this.paramQuery.endDate(end);

    if (
      moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')
    ) {
      end = moment(end).add(1, 'd').toISOString();
    }

    let param = {
      startDate: start,
      endDate: end,
      skip: 0,
      limit: 10000,
      id: storeId,
    };

    console.log('__getFoliosByStartAndEndDate', param);

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getAllFoliosByDate',
          param,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  getAllFolios({
    startDate,
    endDate,
    storeIds,
    ...restRequest
  }: AllFoliosRequest) {
    startDate = moment(
      moment(startDate).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.openTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // start = await this.paramQuery.startDate(start);
    //  let eDate = moment(this.dateFilterForm.value.endDate).endOf("day").utc(true).toISOString();
    endDate = moment(
      moment(endDate).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.closeTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // end = await this.paramQuery.endDate(end);

    if (
      moment(startDate).format('YYYY-MM-DD') ===
      moment(endDate).format('YYYY-MM-DD')
    ) {
      endDate = moment(endDate).add(1, 'd').toISOString();
    }

    let param: AllFoliosRequest = {
      startDate: startDate,
      endDate: endDate,
      skip: restRequest.skip ?? 0,
      limit: restRequest.limit ?? 10000,
      storeIds,
      withDetails: restRequest.withDetails ?? false,
    };

    console.log('__getFoliosByStartAndEndDate', param);

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getAllFolios',
          param,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  _posSaveFolio(
    folio?: Folio | null,
    importance: 'low' | 'med' | 'high' = 'high'
  ): Promise<Folio> {
    return new Promise(async (resolve, reject) => {
      try {
        let result: any;
        let resultFolio: Folio;
        folio = folio ? folio : this.folioStore.getValue().activeFolio;
        folio.lastChange = new Date();
        folio.rows.map((fl) => {
          if (
            fl.rowType === 'product' ||
            fl.rowType === 'modifier' ||
            fl.rowType === 'custom-modifier'
          ) {
            fl.modifiers = [];
            fl.mustModifiers = [];
          }
        });
        if (this.appService.connection !== 'offline') {
          try {
            if (importance == 'high') {
              result = await this.orwiService.serviceRequestPromise(
                '/api/pos/folio/saveFolio',
                folio,
                this.sessionQuery.token
              );

              if (result.error) {
                throw new Error(result.error);
              } else {
                resultFolio = result.response;
              }
            } else {
              resultFolio = folio;
            }
          } catch (error) {
            resultFolio = folio;
          }
        } else {
          resultFolio = folio;
        }

        this.folioStore.update(({ openFolios }) => ({
          openFolios: arrayUpdate(
            openFolios,
            resultFolio.id,
            resultFolio,
            'id'
          ),
        }));
        resolve(resultFolio);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  async _posDoneFolio(folio?: Folio) {
    console.log('newRow', this.folioStore.getValue().newRow);
    if (!folio) {
      folio = this.folioStore.getValue().activeFolio;
    }
    console.log('activeFolio', this.folioStore.getValue().activeFolio);
    folio.lock.status = 'unlocked';
    folio.lock.time = moment().toDate();
    folio.lock.userID = this.sessionQuery.user.id;
    folio.lock.userName = this.sessionQuery.user.name;
    folio.lastChange = new Date();
    folio.rows.map((fl) => {
      if (
        fl.rowType === 'product' ||
        fl.rowType === 'modifier' ||
        fl.rowType === 'custom-modifier'
      ) {
        fl.modifiers = [];
        fl.mustModifiers = [];
      }
    });
    if (this.folioStore.getValue().activeFolio.sequenceNo === 0) {
      let logObject = {
        id: this.idGen.generate(),
        storeId: this.sessionQuery.activeLicense.orwiStore.id,
        folioId: this.folioStore.getValue().activeFolio.id,
        createDate: new Date(),
        logLevel: 'debug',
        actionType: 'folio-open',
        userId: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        data: '',
        infoType: 'information',
        description: this.transloco.translate('Folio Open'),
      };
      await this.saveFolioLog(logObject);
    }
    if (this.folioStore.getValue().newRow) {
      for (
        let index = 0;
        index <
        this.folioStore
          .getValue()
          .activeFolio.rows.filter((x) => x.recordStatus === 'new').length;
        index++
      ) {
        const element = this.folioStore
          .getValue()
          .activeFolio.rows.filter((x) => x.recordStatus === 'new')[index];
        let logObject = {
          id: this.idGen.generate(),
          storeId: this.sessionQuery.activeLicense.orwiStore.id,
          folioId: this.folioStore.getValue().activeFolio.id,
          createDate: new Date(),
          logLevel: 'debug',
          actionType: 'add-product',
          userId: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
          data: JSON.stringify(element),
          infoType: 'information',
          description: this.transloco.translate('Add Product'),
        };
        await this.saveFolioLog(logObject);
      }
    }
    return new Promise<Folio>(async (resolve, reject) => {
      try {
        let result: any;
        let resultFolio: Folio;

        if (this.appService.connection !== 'offline') {
          try {
            result = await this.orwiService.serviceRequestPromise(
              '/api/pos/folio/doneFolio',
              folio,
              this.sessionQuery.token
            );

            if (result.error) {
              throw new Error(result.error);
            } else {
              resultFolio = result.response;
              // console.log("doneFolio",resultFolio)
            }
          } catch (error) {
            resultFolio = folio;
            resultFolio.rows.map((o) => (o.recordStatus = 'old'));
          }
        } else {
          resultFolio = folio;
          resultFolio.rows.map((o) => (o.recordStatus = 'old'));
        }

        this.folioStore.update({ changedRows: [] });
        this.folioStore.update({ activeFolioRow: undefined });
        this.folioStore.update({ newRow: undefined });

        // this.folioStore.update(({ openFolios }) => ({
        //   openFolios: arrayUpdate(openFolios, resultFolio.id, resultFolio, 'id'),
        //   activeFolio: resultFolio
        // }));

        this.folioStore.update(({ openFolios }) => ({
          activeFolio: new Folio(),
          openFolios: arrayUpdate(
            openFolios,
            resultFolio.id,
            resultFolio,
            'id'
          ),
        }));

        await this.hs.hapticsImpactLight(100);
        await this.hs.hapticsImpactMedium(200);
        await this.hs.hapticsImpactLight(100);
        return resolve(resultFolio);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  _posCloseFolio(folio?: Folio): Promise<Folio> {
    if (!this.ssoService.checkPermission('folio-close')) {
      this.glb.permissionToast();
      return Promise.reject(new Error('Permission denied')); // Reject the promise with an error
    } else {
      if (!folio) {
        folio = this.folioStore.getValue().activeFolio;
      }
      folio.lastChange = new Date();
      folio.closedDate = new Date();
      return new Promise(async (resolve, reject) => {
        try {
          let result: any;
          let resultFolio: Folio;

          // let roleLevel = this.sessionQuery.getHigerRole().level;
          // if (roleLevel < 29) {
          //   this.glb.toast(
          //     this.transloco.translate('Folio Close'),
          //     this.transloco.translate('No Folio Closing Permission'),
          //     'bottom',
          //     'warning'
          //   );
          //   resolve(folio);
          //   return;
          // }

          if (this.appService.connection !== 'offline') {
            try {
              result = await this.orwiService.serviceRequestPromise(
                '/api/pos/folio/closeFolio',
                folio,
                this.sessionQuery.token
              );
              if (result.error) {
                throw new Error(result.error);
              } else {
                resultFolio = result.response;
                // this.folioLogService.insertFolioLog({
                //   folioId: folio.id,
                //   logType: 'folio-close',
                //   data: {
                //     userId: this.sessionQuery.user.id,
                //     userName: this.sessionQuery.user.name,
                //   },
                // });
              }
            } catch (error) {
              folio.status = 'closed';
              resultFolio = folio;
              resultFolio.rows.map((o) => (o.recordStatus = 'old'));
            }
          } else {
            folio.status = 'closed';
            resultFolio = folio;
            resultFolio.rows.map((o) => (o.recordStatus = 'old'));
          }

          this.folioStore.update(({ openFolios }) => ({
            openFolios: arrayRemove(openFolios, resultFolio.id),
          }));
          this.folioStore.update({ closedFolios: [resultFolio] });

          this.folioStore.update(({ openFolios }) => ({
            openFolios: arrayUpdate(
              openFolios,
              resultFolio.id,
              resultFolio,
              'id'
            ),
          }));

          resolve(resultFolio);
        } catch (err: any) {
          reject(err);
          return err; // Return error in case of rejection
        }
      });
    }
  }

  _posGetFolioById(folioId: string): Promise<Folio> {
    return new Promise(async (resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getFolioById',
          {
            id: folioId,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              resolve(o.response);
            } else {
              reject(o?.error);
            }
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  _posGetOpenFolios(id): Promise<Folio[]> {
    return new Promise(async (resolve, reject) => {
      if (this.appService.connection == 'offline') {
        let openfolios: Folio[] = await localforage.getItem('OpenFolios');
        resolve(openfolios);
        return;
      }
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getOpenFolios',
          { id: id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            //// console.log('_getOpenFolios', o);
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  clearStates() {
    this.folioStore.update({
      activeFolio: null,
      activeSegment: 'all',
      activeFolioRow: null,
      newRow: null,
      selectedRow: null,

      changedRows: [],
      closedFolios: [],

      prepareDelete: null,

      activeModifiersGroup: [],
    });
  }

  _posImportFolios() {
    let data = {
      id: this.orwiStore.getValue().id,
      openFolios: this.folioQuery.getValue().openFolios,
      closedFolios: this.folioQuery.getValue().closedFolios,
    };
    if (data.openFolios.length == 0 && data.closedFolios.length == 0) return;
    this.orwiService
      .serviceRequestPromise(
        '/api/pos/folio/importFolios',
        data,
        this.sessionQuery.token
      )
      .then((o: any) => {
        if (o.response) {
          this.folioStore.update({ openFolios: o.response, closedFolios: [] });
          for (const iterator of o.response) {
            this.folioStore.update(({ openFolios }) => ({
              openFolios: arrayUpsert(openFolios, iterator.id, iterator, 'id'),
            }));
          }
        }
      });
  }

  closeAllFolios() {
    this.folioStore
      .getValue()
      .openFolios.forEach(async (el) => await this._posCloseFolio(el));
  }

  createPaymentrow(
    payment_id,
    payment_name,
    total,
    rowType: rowType = 'payment',
    paymentType: paymentTypes,
    payRowID?: any,
    currencyCode?: any,
    currencyRate?: any,
    currencyTotal?: any
  ): FolioRow {
    let row: FolioRow = new FolioRow();
    row.id = this.idGen.generate();
    row.name = payment_name;
    row.qty = 1;
    row.unitPrice = total - total * 2;
    row.price = total - total * 2;
    row.itemID = payment_id;
    row.itemImage = '';
    row.parentID = '';
    row.recordStatus = 'new';
    row.rowType = rowType;
    row.paymentType = paymentType;
    row.payRowID = payRowID;
    row.isPayment = row.rowType !== 'discount' ? true : false;
    row.isDiscount = row.rowType === 'discount' ? true : false;
    row.currencyCode = currencyCode;
    row.currencyRate = currencyRate;
    row.currencyTotal = currencyTotal;
    row.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });
    return row;
  }
}

interface AllFoliosRequest {
  storeIds: string[];
  startDate: string;
  endDate: string;
  skip?: number;
  limit?: number;
  withDetails?: boolean;
}
