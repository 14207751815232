import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
  providers: [DecimalPipe],
})
export class ProgressModalComponent implements OnInit, OnDestroy {
  progressSubscription: Subscription;
  progressPercentage: number = 0;
  matchedName: string = '';
  progressText: string = '';
  private destroy$ = new Subject<void>();

  constructor(
    private integrationService: FoodAppsIntegrationService,
    private decimalPipe: DecimalPipe,
    private transloco: TranslocoService,
    private modalService: ModalService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.checkProgress();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  checkProgress() {
    this.progressSubscription = this.integrationService.progress$.pipe(takeUntil(this.destroy$)).subscribe(
      ({ matchedName, progress }) => {
        this.matchedName = matchedName;
        this.progressPercentage = parseFloat(
          this.decimalPipe.transform(progress, '1.0-0')
        );
        this.progressText = this.transloco.translate(
          `Matching '{{ matchedName }}'`,
          {
            matchedName: this.matchedName,
          }
        );
        // console.log(this.matchedName, this.progressPercentage)
        if (this.progressPercentage === 100) {
          this.globalService.toast(
            'Success',
            'Process completed',
            'bottom',
            'success'
          );
          setTimeout(() => {
            this.modalService.dismiss();
          }, 1000);
        }
      }
    );
  }

  ionViewWillLeave() {
    if (this.progressSubscription) {
      console.log('destroyed')
      this.progressSubscription.unsubscribe();
    }
  }
}
