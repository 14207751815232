import { Component, OnInit } from '@angular/core';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { FoodAppStore } from '../../state/dto';
import { FoodAppsIntegrationQuery } from '../../state/food-apps-integration.query';
import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
// import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-store-list',
  templateUrl: './food-apps-store-list.component.html',
  styleUrls: ['./food-apps-store-list.component.scss'],
})
export class FoodAppsStoreListComponent implements OnInit {
  filteredStores : FoodAppStore[] = []
  constructor(
    private integrationService: FoodAppsIntegrationService,
    private storeQuery: OrwiStoreQuery,
    public integrationQuery: FoodAppsIntegrationQuery,
    // private printerCoverService: PrintCoverService,
    private transloco: TranslocoService
  ) {}

  ngOnInit() {
    this.getStores();
    this.filteredStores = this.integrationQuery.foodAppStores
  }

  getStores() {
    this.integrationService
      .getStores(this.storeQuery._orwiStore.id)
      .then((o) => {
        console.log(o);
      });
  }

  searchValue(value : string)
  {
    if (value != '') {
      // let filterData = this.userService.user.store.filter(x=>x.id == e.value)

      this.filteredStores = this.integrationQuery.foodAppStores.filter((o) =>
        Object.keys(o).some(() =>
          o.name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
      if (this.filteredStores.length == 0) {
        // this.loadingText = this.transcolo.translate('Store Not Found');
      }
    } else {
      this.filteredStores = this.integrationQuery.foodAppStores;
    }
  }

  statusChange(e, item: FoodAppStore) {
    console.log(e.detail.value, item.status);

    this.integrationService.changeStatus(item, e.detail.value).then((o) => {
      o
      // console.log(o);
      // this.printerCoverService.printFoodAppStatus(item, e.detail.value);
    });
  }

  customActionSheetOptions = {
    header: this.transloco.translate('Status'),
  };

  getIcon(appName) {
    return  this.integrationQuery.foodAppAplications.find(
      (o) => o.id == appName
    ).icon || ''
  }
}
