import { Pipe, PipeTransform } from "@angular/core";
import { FoodAppsIntegrationQuery } from "src/app/modules/food-apps-integration/state/food-apps-integration.query";

@Pipe({
  name: 'applicationImage',
  standalone:true
})
export class ApplicationImagePipe implements PipeTransform {
  constructor(private integrationQuery: FoodAppsIntegrationQuery) {} // Replace 'any' with the correct type if possible.

  transform(appName: string): string {
    return (
      this.integrationQuery.foodAppAplications.find(
        (o: { id: string; icon: string }) => o.id === appName
      )?.icon || ''
    );
  }
}