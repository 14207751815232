import { Component, OnInit } from '@angular/core';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { PaymentType } from 'src/app/services/dto/orwi-store';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { FoodAppsIntegrationStore } from '../../../state/food-apps-integration.store';
import { arrayUpsert } from '@datorama/akita';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'payment-select',
  templateUrl: './payment-select.component.html',
  styleUrls: ['./payment-select.component.scss'],
})
export class PaymentSelectComponent implements OnInit {
  query = '';
  page = 0;
  pageLimit = 20;
  pagedData = [];
  isLoaded = false;
  paymentTypes: PaymentType[];
  async: any;
  constructor(
    public storeQuery: OrwiStoreQuery,
    private integrationQuery: FoodAppsIntegrationQuery,
    private integrationStore: FoodAppsIntegrationStore,
    private glb : GlobalService
  ) {}

  ngOnInit() {
    this.storeQuery.payTypes$.subscribe((x) => {
      this.paymentTypes = x;
    });
  }

  handleChange(event) {
    this.query = event.target.value.toLowerCase();
  }

  select(selectedPayment) {
    this.integrationQuery.activePaymentType.internalId = selectedPayment.id;
    this.integrationQuery.activePaymentType.isChanged = true;
    this.integrationStore.update(({ foodAppPaymentType }) => ({
      foodAppPaymentType: arrayUpsert(
        foodAppPaymentType,
        this.integrationQuery.activePaymentType.id,
        this.integrationQuery.activePaymentType,
        'id'
      ),
    }));
    this.glb.closeModal();
  }
}
