
class QRCode {
    private seller: Uint8Array;
    private vatNo: Uint8Array;
    private dateTime: Uint8Array;
    private total: Uint8Array;
    private tax: Uint8Array;

    constructor(
        seller: string,
        taxNo: string,
        dateTime: Date,
        total: number,
        tax: number
    ) {
        this.seller = new TextEncoder().encode(seller);
        this.vatNo = new TextEncoder().encode(taxNo);
        this.dateTime = new TextEncoder().encode(dateTime.toISOString());
        this.total = new TextEncoder().encode(total.toString());
        this.tax = new TextEncoder().encode(tax.toString());
    }

    private getAsText(tag: number, value: Uint8Array): string {
        const tagHex = tag.toString(16).padStart(2, '0');
        const lengthHex = value.length.toString(16).padStart(2, '0');
        const valueHex = Array.from(value)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return `${tagHex}${lengthHex}${valueHex}`;
    }

    private getBytes(tag: number, value: Uint8Array): Uint8Array {
        const result = new Uint8Array(2 + value.length);
        result[0] = tag;
        result[1] = value.length;
        result.set(value, 2);
        return result;
    }

    private getString(): string {
        let tlvText = '';
        tlvText += this.getAsText(1, this.seller);
        tlvText += this.getAsText(2, this.vatNo);
        tlvText += this.getAsText(3, this.dateTime);
        tlvText += this.getAsText(4, this.total);
        tlvText += this.getAsText(5, this.tax);
        return tlvText;
    }

    public toString(): string {
        return this.getString();
    }

    public toBase64(): string {
      const tlvBytes: Uint8Array[] = [];
      tlvBytes.push(this.getBytes(1, this.seller));
      tlvBytes.push(this.getBytes(2, this.vatNo));
      tlvBytes.push(this.getBytes(3, this.dateTime));
      tlvBytes.push(this.getBytes(4, this.total));
      tlvBytes.push(this.getBytes(5, this.tax));

      const totalLength = tlvBytes.reduce((sum, arr) => sum + arr.length, 0);
      const flatBytes = new Uint8Array(totalLength);
      let offset = 0;

      tlvBytes.forEach(arr => {
          flatBytes.set(arr, offset);
          offset += arr.length;
      });

      // Convert Uint8Array to a binary string
      const binaryString = Array.from(flatBytes)
          .map(byte => String.fromCharCode(byte))
          .join('');

      // Encode the binary string to Base64
      return btoa(binaryString);
  }

    public async toQrCode(): Promise<string> {
        const base64Data = this.toBase64();
        return base64Data
    }
}

export default QRCode;
