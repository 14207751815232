import { Component, inject, OnInit } from '@angular/core';
import { CouponService } from 'src/app/services/store/coupon.service';
import { FolioQuery } from '../../state/folio.query';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss'],
})
export class LoyaltyComponent  implements OnInit {
  couponService = inject(CouponService);
  folioQuery = inject(FolioQuery);
  modalService = inject(ModalService);
  coupons: any = [];
  totalPoints: number = 0;
  usedPoints: number = 0;
  remainingPoints: number = 0;
  constructor() { }

  ngOnInit() {}

  async handleClose() {
    return true;
  }


  async getUserCoupons(qrCode: any) {
    const coupons = await this.couponService.getBenefitsForPOS({
      folioNo: this.folioQuery.getValue().activeFolio.id,
      kerzzId: localStorage.getItem("activeStore"),
      orwiFolioId: this.folioQuery.getValue().activeFolio.id,
      userQrCode: qrCode
    })
    console.log("coupons", coupons);
  }

}
