import { Injectable } from '@angular/core';
import { PosParameters } from 'kerzz-base';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { SessionQuery } from '../../session/state/session.query';
import { ParametersStore } from './parameters.store';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({ providedIn: 'root' })
export class ParametersService {
  posParameters: PosParameters = new PosParameters()

  constructor(
    private orwiService: OrwiService,
    private sessionQuery: SessionQuery,
    private parametersStore: ParametersStore,
    private globalService: GlobalService,
  ) {}
  getParameters() {
    this.orwiService.serviceRequestPromise('/api/pos/pos-parameter/getPosParameters', { id: this.sessionQuery.activeLicense.orwiStore.id, uuid: this.globalService.DeviceUUID }, this.sessionQuery.token).then((x: any) => {
      console.log('getParameters', x.response)
      this.posParameters = x.response
      this.parametersStore.update({ posParameters: this.posParameters })
    })
  }

  setParameters(parameters: PosParameters) {
    this.globalService.showLoading()

    const params = {
      ...parameters,
      globalParameters: {
        ...parameters.globalParameters,
        openTime: new Date(parameters.globalParameters.openTime).toISOString(),
        closeTime: new Date(parameters.globalParameters.closeTime).toISOString()
      }
    };
    this.orwiService.serviceRequestPromise('/api/pos/pos-parameter/savePosParameters', params, this.sessionQuery.token).then((x: any) => {
      this.posParameters = x.response
      this.parametersStore.update({ posParameters: this.posParameters })
    })
    this.globalService.closeLoading()
  }
}
