<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      [ngStyle]="{ display: progress === true ? '' : 'none' }"
    ></ion-progress-bar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button style="width: 300px;" (click)="synsPaymentType()">
        <ion-icon slot="icon-only" name="download-outline"></ion-icon>
        {{ "Sync Payments" | transloco }}
      </ion-button>

      <ion-button style="width: 200px;" (click)="save()">
        <ion-icon slot="icon-only" name="save-outline"></ion-icon>
        {{ "Save" | transloco }}
      </ion-button>
      <!-- <ion-button (click)="exportexcel()">
        <ion-icon slot="icon-only" name="download-outline"></ion-icon>
        {{ "Export" | transloco }}
      </ion-button>
      <ion-button (click)="syncBestMatch()">
        <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
        {{ "Sync" | transloco }}
      </ion-button> -->
    </ion-buttons>

    <ion-title>
      {{ this.integrationQuery.activeAccount.description }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <cdk-virtual-scroll-viewport
    itemSize="50"
    minBufferPx="900"
    maxBufferPx="1350"
    class="ion-content-scroll-host"
  >
    <ion-list>
      <ion-item
        *ngIf="(integrationQuery.foodAppPaymentTypes$ | async).length === 0"
      >
        {{ "Payment list is empty" | transloco }}
      </ion-item>
      <ion-item-sliding
        #slidingCase
        *ngFor="let pitem of integrationQuery.foodAppPaymentTypes$ | async"
        id="excel-table"
      >
        <ion-item (click)="syncSelect(pitem)">
          <div style="width: 100%; font-size: 14px">
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                color: #171e4e;
              "
            >
              <div>{{ pitem.name }}</div>
              <!-- <div>{{ pitem.price | currency: "₺" }}</div> -->
            </div>
            <div *ngIf="getName(pitem.internalId) !== ''">
              <div
                style="padding-left: 20px; margin-top: 10px; margin-bottom: 5px"
              >
                <ion-icon
                  color="secondary"
                  *ngIf="pitem.isChanged === true"
                  name="ellipse"
                ></ion-icon>
                {{ getName(pitem.internalId) }}
              </div>
            </div>
          </div>
        </ion-item>


        <ion-item-options>
          <ion-item-option
            (click)="deleteMatch(slidingCase, pitem)"
            color="primary"
            >{{ "Delete" | transloco }}</ion-item-option
          >
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </cdk-virtual-scroll-viewport>
</ion-content>
