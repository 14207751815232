<div style="width: 100%">
  <div
  id="table-cover"
  class="table-cover table-cover-initial"
  [ngClass]="selectedPosMenuId !== 'open-folios' ? 'hidden' : 'visible'">

  @for (item of openFolios; track trackByFolioId) { @defer (on
    viewport) {
    <orwi-open-folio-button
    id="{{ item.id }}"
    class="c-table-button"
    OrwiDraggableFolio
    [folio]="item">
  </orwi-open-folio-button>
    } @placeholder {
    <div style="padding: 5px">
      <ion-skeleton-text style="height: 80px; width: 95px"></ion-skeleton-text>
    </div>
    } }
  <!-- <ng-container *ngFor="let item of openFolios; trackBy: trackByFolioId">
    <orwi-open-folio-button
      id="{{ item.id }}"
      class="c-table-button"
      OrwiDraggableFolio
      [folio]="item">
    </orwi-open-folio-button>
  </ng-container> -->
  <div class="sorter" (click)="onSort()">
    <ion-icon src="/assets/icons/sort.svg" [ngClass]="sortDir"></ion-icon>
    <div>{{ 'Change Order' | transloco }}</div>
  </div>
</div>

  <div
    class="table-cover"
    [ngClass]="(mq.posMenuSelected$ | async).id !== 'self-service' ? 'hidden' : 'visible'"
    style="position: initial !important">
    <div class="table-btn open" (click)="newTransaction()">
      {{ "New Transaction" | transloco }}
    </div>
    @for (item of tq.openSelfFolio$ | async; track item.id) { @defer (on
    viewport) {
    <orwi-open-folio-button
      [folio]="item"
      (onClickInfo)="onClickInfo($event, item.table.name, item)">
    </orwi-open-folio-button>
    } @placeholder {
    <div style="padding: 5px">
      <ion-skeleton-text style="height: 80px; width: 95px"></ion-skeleton-text>
    </div>
    } }
  </div>

  <div
    class="table-cover"
    [ngClass]="(mq.posMenuSelected$ | async).id !== 'take-away' ? 'hidden' : 'visible'"
    style="position: initial !important">
    <div (click)="newTransaction()" class="table-btn open">
      {{ "New Transaction" | transloco }}
    </div>

    @for (item of tq.openTakeawayFolio$ | async; track item.id) { @defer (on
    viewport) {
    <orwi-open-folio-button [folio]="item"> </orwi-open-folio-button>
    } @placeholder {
    <div style="padding: 5px">
      <ion-skeleton-text style="height: 80px; width: 95px"></ion-skeleton-text>
    </div>
    } }
  </div>
</div>
