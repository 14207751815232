import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonPopover } from '@ionic/angular';
import {
  DeliveryStatus,
  DeliveryStatuses,
  Folio,
} from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { TableService } from 'src/app/modules/tables/state/table.service';
import { FolioService } from '../../state/folio.service';
import { FolioStore } from '../../state/folio.store';
import { arrayUpsert } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { EcrCoverService } from 'src/app/services/ecr/ecr-cover.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
// import { FoodAppsIntegrationStore } from 'src/app/modules/food-apps-integration/state/food-apps-integration.store';

@Component({
  selector: 'orwi-open-folio-button',
  templateUrl: './open-folio-button.component.html',
  styleUrls: ['./open-folio-button.component.scss'],
})
export class OpenFolioButtonComponent implements OnInit {
  @Input() folio: Folio;
  @Output() onClickInfo: EventEmitter<Folio> = new EventEmitter<Folio>();
  @ViewChild('contextPopOver', { static: false }) contextPopOver: IonPopover;
  constructor(
    private ts: TableService,
    private fs: FolioStore,
    private ecrCoverService: EcrCoverService,
    private folioService: FolioService,
    private glb: GlobalService,
    private foodAppService: FoodAppsIntegrationService,
    private transloco: TranslocoService,
    private sessionQuery: SessionQuery,
    private ssoService: SSOSessionService,
    // private integrationStore: FoodAppsIntegrationStore,
  ) {}

  ngOnInit() {}

  deliveryStatuses = DeliveryStatuses;

  info(e, folio) {
    e.preventDefault();
    e.stopPropagation();
    this.onClickInfo.emit(folio);
  }

  async select() {
    this.glb.showLoading()
    this.sessionQuery
  //   if (
  //     (this.folio.creator.userName !== this.sessionQuery.getValue().userInfo.name && !this.ssoService.checkPermission('folio-show-only-own-folios'))
  // ) {
  //     this.glb.permissionToast();
  //     return;
  // }
    if(!this.ssoService.checkPermission('folio-show-all-folios')){
      this.glb.permissionToast();
      this.glb.closeAllLoading()
      return;
    }
    this.glb.closeAllLoading()
    if (this.folio.isOrwiPay) {
      await this.glb.openAlert({
        header: this.transloco.translate('ORWI'),
        message:
          'Bu çek ORWI APP den Online olarak kapatılmıştır.Adisyon Kapatılacaktır.',
        buttons: [
          this.transloco.translate('No'),
          {
            text: this.transloco.translate('Yes'),
            handler: async () => {
              await this.ecrCoverService.sendToEcr(this.folio);
            },
          },
        ],
      });

      return;
    }



    const folioResult  = await  this.folioService.checkFolioEftPos(this.folio)
    this.glb.closeAllLoading()
    if (folioResult === 'payment-done')
    {
      await this.glb.openAlert({
        header: this.transloco.translate('Orwi Pos'),
        message:
          'Bu çek ' +
          this.folio.rows.find((x) => x.rowType == 'payment').name +
          ' olarak kapatılmıştır.Adisyon Kapatılacaktır.',
        buttons: [
          this.transloco.translate('No'),
          {
            text: this.transloco.translate('Yes'),
            handler: () => {
              // popover.dismiss(value);

              this.folioService._posCloseFolio(this.folio);
            },
          },
        ],
      });

      return
    }

    if (folioResult == 'sended-tsm') {
      this.glb.toast(
        '',
        'You cannot make transactions on the ticket sent via EFT Pos.',
        'bottom',
        'danger'
      );
      return;
    }



    this.folioService._posGetFolioById(this.folio.id).then(
      (o) => {
        this.glb.closeAllLoading()
        this.folio = o;
        if (this.folio.type == 'table') {
          this.ts.selectTable(this.folio.table.id, this.folio.id);
        } else if (this.folio.type == 'self') {
          this.fs.update({ activeFolio: this.folio });
          this.glb.navigateFolioForm();
        } else if (this.folio.type == 'take-away') {
          this.fs.update({ activeFolio: this.folio });
          this.glb.navigateFolioForm();
        } else if (this.folio.type == 'delivery') {
          this.fs.update({ activeFolio: this.folio });
          this.glb.navigateFolioForm();
        }
      },
      (e) => {
        this.glb.toast('error', e, 'bottom', 'danger');
      }
    );
  }

  paymentDone() {
    if (this.folio.eftPos) {
      if (
        this.folio.rows
          .filter((fr) => fr.rowType == 'payment')
          .reduce((sum, item) => sum + item.unitPrice * item.qty, 0) ||
        0 == this.folio.grandTotal
      ) {
        return true;
      }
    }
    return false;
  }

  openContextMenu(card: HTMLDivElement) {
    card; //reserved
    if (this.folio.appName == 'orwi') return;
    this.foodAppService.openSheetForMobile(this.folio);
    return;
  }

  // YANINDA

  closeContextMenu(card: HTMLDivElement) {
    card.querySelectorAll('.fab-item').forEach((el) => {
      el.classList.remove('open');
      el.animate(
        [
          // {
          //   ...(el.classList.contains(this.folio.deliveryStatus)
          //     ? {
          //         transform: 'scale(1.5)',
          //       }
          //     : {
          //         transform: 'scale(1)',
          //       }),
          // },
          {
            transform: 'scale(0)',
            // ...(el.classList.contains(this.folio.deliveryStatus)
            //   ? {
            //       top: ' -40px',
            //       left: '50px',
            //     }
            //   : {}),
          },
        ],
        {
          duration: 500,
        }
      ).finished.then(() => {
        // if (el.classList.contains(this.folio.deliveryStatus))
        el['style'].transform = 'scale(0)';
      });
      el.classList.add('close');
    });

    const circle = card.querySelector('.circle-one');

    circle
      .animate(
        [
          // {
          //   transform: `rotate(0deg)`,
          // },
          {
            transform: `rotate(45deg)`,
          },
        ],
        {
          easing: 'ease-in',
          duration: 500,
        }
      )
      .finished.then(() => {
        (circle as any).style.transform = `rotate(45deg)`;
      });
    setTimeout(() => {
      card.classList.remove('active');
    }, 600);
  }

  async onClickDeliveryIcon(deliveryStatus: DeliveryStatus) {
    if (this.folio.deliveryStatus == deliveryStatus) return;

    await this.glb.openAlert({
      header: this.transloco.translate('Delivery Status'),
      message: `Siparişin durumunu <b>${this.transloco.translate(
        this.folio.deliveryStatus
      )}</b>'dan <b>${this.transloco.translate(
        deliveryStatus
      )}</b>'a güncellemek ister misiniz?`,
      buttons: [
        this.transloco.translate('No'),
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            // popover.dismiss(value);
            this.changeDeliveryStatus(deliveryStatus);
          },
        },
      ],
    });
  }

  onClickPopoverItem(deliveryStatus: DeliveryStatus, popover?: IonPopover) {
    popover.dismiss();
    this.changeDeliveryStatus(deliveryStatus);
  }

  async changeDeliveryStatus(status: DeliveryStatus) {
    await this.glb.openAlert({
      header: this.transloco.translate('Delivery Status'),
      message: `Siparişin durumunu <b>${this.transloco.translate(
        this.folio.deliveryStatus
      )}</b>'dan <b>${this.transloco.translate(
        status
      )}</b>'a güncellemek ister misiniz?`,
      buttons: [
        this.transloco.translate('No'),
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            const value = status; //deliveryStatus.target['value'];
            // popover.dismiss(value);
            this.foodAppService.updateOrderStatus(this.folio.id, value).then(
              (res) => {
                console.log('Response from FoodApp', res);
                this.folio.deliveryStatus = value;

                if (this.folio.deliveryStatus == 'cancelled') {
                  this.folioService._posCloseFolio(this.folio);
                } else {
                  this.fs.update(({ openFolios }) => ({
                    openFolios: arrayUpsert(
                      openFolios,
                      this.folio.id,
                      this.folio
                    ),
                  }));
                }
                this.glb.toast('', 'Action Successful', 'middle', 'success');
              },
              () => {
                this.glb.toast('', 'An error occured', 'middle', 'danger');
              }
            );
          },
        },
      ],
    });
  }

  statusTypes: any[] = [
    'cancelled',
    'accepted',
    'preparing',
    'routing',
    'completed',
  ];
}
